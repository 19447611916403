import React from 'react';
import { TargetingProps, TargetProps } from './interface';
import styled from 'styled-components';
import Typography from '../../bit/components/typography';
import Icon from '../../bit/components/icon';
import wiFiIcon from '../../images/wiFi.svg';
import globeIcon from '../../images/globe.svg';
import { color } from '../../bit/components/utils/utils';

const StyledTargeting = styled.div`
  display: flex;
  flex-direction: column;

  .target {
  }
`;

const StyledTarget = styled.div`
  display: inline-flex;
  align-items: center;

  & + & {
    margin-top: 8px;
  }

  span {
    line-height: normal;
  }
`;

const TargetIcon = styled(Icon)`
  margin-right: 8px;
  color: ${color('text.first')};
`;

const Target = (props: TargetProps) => {
  const { icon, label } = props;

  return (
    <StyledTarget>
      <TargetIcon src={icon} size={16} color='inherit' />
      <Typography variant='subtitle2' size={14} color='main'>
        {label}
      </Typography>
    </StyledTarget>
  );
};

const Targeting = (props: TargetingProps) => {
  const { targeting } = props;

  return (
    <StyledTargeting>
      {targeting.geo.allowed && (
        <Target icon={globeIcon} label={targeting.geo.allowed} />
      )}
      {targeting.connectionType.allowed && (
        <Target icon={wiFiIcon} label={targeting.connectionType.allowed} />
      )}
    </StyledTargeting>
  );
};

export default Targeting;
