import { useMemo } from 'react';
import { DropdownOption } from './interface';

const useDropdownOptions = <T>(
  items: T[],
  name: keyof T | ((item: T) => string),
  value: keyof T
): DropdownOption[] => {
  return useMemo(
    () =>
      items.map((item) => ({
        name: typeof name === 'function' ? name(item) : String(item[name]),
        value: String(item[value]),
        item
      })),
    [items, name, value]
  );
};

export default useDropdownOptions;
