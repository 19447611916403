import saveAs from 'file-saver';
import { AxiosError } from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../store';
import { mapAppLandingsOffers } from './../../maps/appMap';

import Api from '../../api';
import AppsApi from '../../api/apps';
import {
  ApiAppCabinetsGetParams,
  ApiAppsGetQuery,
  ApiAppsPut,
  AppRequest
} from '../../types/api/apps';
import { App, AppCabinets, AppLandingsOffer, AppType } from '../../types/apps';

interface AppsState {
  apps: App[];
  appsCabinets: AppCabinets;
  requests: AppRequest[];
  landings: AppLandingsOffer[];
}

const initialState: AppsState = {
  apps: [],
  appsCabinets: {},
  requests: [],
  landings: []
};

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setApps: (state, action) => {
      const data: App[] = action.payload.data || [];
      state.apps = data.map((a) => ({
        ...a,
        name: a.isBanned ? `${a.name} (BANNED)` : a.name
      }));
    },
    setAppsCabinets: (state, action) => {
      const data = action.payload || {};
      state.appsCabinets = data;
    },
    setLandings: (state, action) => {
      state.landings = mapAppLandingsOffers(action.payload.data);
    },
    setRequests: (state, action) => {
      state.requests = action.payload;
    }
  }
});

const {
  setApps,
  setLandings,
  setRequests,
  setAppsCabinets
} = appsSlice.actions;

export const fetchAppsAsync = (params?: ApiAppsGetQuery): AppThunk => async (
  dispatch
) => {
  try {
    const response = await AppsApi.getApps(params);
    dispatch(setApps(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchAppsCabinetsAsync = (
  params: ApiAppCabinetsGetParams
): AppThunk => async (dispatch) => {
  try {
    const response = await AppsApi.getAppsCabinets(params);
    dispatch(setAppsCabinets(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const editAppAsync = (
  body: ApiAppsPut,
  type: AppType,
  success: () => void,
  error?: (status: number, axiosError: AxiosError) => void
): AppThunk => async () => {
  try {
    await AppsApi.editApp(body, { type });
    success();
  } catch (e) {
    if (error) {
      error(e.response?.status, e);
    } else {
      Api.handleDefaultError(e);
    }
  }
};

export const fetchLandingsOffer = (): AppThunk => async (dispatch) => {
  try {
    const response = await AppsApi.getLandingsOffer();
    dispatch(setLandings(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchAppRequests = (
  appId: string,
  type: AppType,
  affiliate?: string
): AppThunk => async (dispatch) => {
  try {
    const response = await AppsApi.getAppRequests(appId, { type, affiliate });
    dispatch(setRequests(response.data.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const downloadAppRequests = (
  appId: string,
  type: AppType,
  affiliate: string | undefined,
  appName: string
): AppThunk => async () => {
  try {
    const response = await AppsApi.downloadAppRequests(appId, {
      type,
      affiliate
    });

    const name = [
      'cabinets',
      affiliate && `-${affiliate}`,
      `-${appName}`,
      '.xlsx'
    ]
      .filter(Boolean)
      .join('');

    saveAs(response.data, name);
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const selectApps = (state: RootState) => state.apps.apps;

export const selectAppsCabinets = (state: RootState) => state.apps.appsCabinets;

export const selectAppsLandings = (state: RootState) => state.apps.landings;

export const selectAppRequests = (state: RootState) => state.apps.requests;

export default appsSlice.reducer;
