import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import Header from '../../components/Header';
import {
  fetchOffers,
  fetchOffersCountries,
  fetchOffersGoalTypes,
  fetchOffersTags,
  selectOffers
} from '../../redux/slices/offersSlice';
import Grid from '../../components/Grid';
import OffersTable from './OffersTable';
import {
  StringParam,
  useQueryParams,
  withDefault,
  ArrayParam,
  NumberParam
} from 'use-query-params';
import { OffersTabs } from './interface';
import { TabsOnChange } from '../../bit/components/tabs/interface';
import Tabs, { Tab } from '../../components/Tabs';
import styled from 'styled-components';
import { ApiOffersGet } from '../../types/api/offers';
import Input from '../../components/Input';
import { limitPageOptions } from '../../resources/constants';
import Pagination from '../../components/Pagination';
import Dropdown, {
  DropdownOption,
  DropdownOnChange
} from '../../components/Dropdown';

enum PaymentFormats {
  CPA = 'CPA',
  CPL = 'CPL',
  RS = 'RS'
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
`;

const StyledTabs = styled(Tabs)`
  align-self: flex-start;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -8px -8px;

  > * {
    width: 252px;
    margin: 8px;
  }
`;

const OffersPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { meta: offersMeta, countries: countriesList } = useSelector(
    selectOffers
  );

  const [filters, setFilters] = useQueryParams({
    tab: withDefault(StringParam, OffersTabs.all),
    tags: ArrayParam,
    categories: StringParam,
    countries: ArrayParam,
    availability: StringParam,
    search: StringParam,
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, Number(limitPageOptions[0].value))
  });

  const paymentFormatsOptions: DropdownOption[] = Object.keys(
    PaymentFormats
  ).map((paymentFormatKey) => ({
    name: paymentFormatKey,
    value: paymentFormatKey.toLowerCase()
  }));

  const {
    tab,
    categories,
    tags,
    availability,
    countries,
    search,
    page,
    limit
  } = filters;

  const handleChangeTab = useCallback<TabsOnChange>(
    (nextTab) => {
      setFilters({ tab: nextTab, page: 1 });
    },
    [setFilters]
  );

  const countriesOptions = useMemo<DropdownOption[]>(
    () =>
      countriesList.map((country) => ({
        name: country.title,
        value: String(country.id)
      })),
    [countriesList]
  );

  const availabilityOptions: DropdownOption[] = [
    { name: t('offers.availableShort') as string, value: 'available' },
    {
      name: t('offers.notAvailableShort') as string,
      value: 'require_approval'
    }
  ];

  const getOffers = useCallback(async () => {
    const params: ApiOffersGet = {
      page,
      limit,
      search: search as string,
      tags: tags?.join(','),
      countries: countries?.join(','),
      categories: categories ?? undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      availability: availability ?? undefined
    };

    if (tab === OffersTabs.top) {
      params.onlyFeatured = 'yes';
    } else if (tab === OffersTabs.new) {
      params.onlyNew = 'yes';
    }

    setIsLoading(true);
    await dispatch(fetchOffers(params));
    setIsLoading(false);
  }, [
    availability,
    categories,
    countries,
    dispatch,
    limit,
    page,
    search,
    tab,
    tags
  ]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => {
    dispatch(fetchOffersTags());
    dispatch(fetchOffersGoalTypes());

    // TODO: некорректно, доработать!
    // Проблема в том, что Dropdown не показывает выбранные опции,
    // когда переданы параметры multiple и isSearchableForApi
    dispatch(fetchOffersCountries({ limit: 230 }));
  }, [dispatch]);

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setFilters({ search: e.target.value || undefined }),
    [setFilters]
  );

  const handleFilterChange: (
    filterKey: keyof typeof filters
  ) => DropdownOnChange = (filterKey) => (value) =>
    setFilters({ [filterKey]: value, page: 1 });

  const handleClearMultipleDropdownValue = (
    filterKey: 'countries' | 'categories'
  ) => () => setFilters({ [filterKey]: [], page: 1 });

  return (
    <Page>
      <Header title={t('sidebar.offers')}>
        <FiltersContainer>
          <Input
            value={search as string}
            onChange={handleInput}
            placeholder={t('common.search')}
          />

          <Dropdown
            multiple
            clearable
            searchable
            label='GEO'
            value={countries}
            options={countriesOptions}
            onChange={handleFilterChange('countries')}
            onClear={handleClearMultipleDropdownValue('countries')}
          />

          <Dropdown
            clearable
            searchable
            label={t('offers.availability')}
            value={availability}
            options={availabilityOptions}
            onChange={handleFilterChange('availability')}
          />

          <Dropdown
            clearable
            value={categories}
            options={paymentFormatsOptions}
            label={t('offers.paymentFormat')}
            onChange={handleFilterChange('categories')}
            onClear={handleClearMultipleDropdownValue('categories')}
          />
        </FiltersContainer>
      </Header>
      <Grid>
        <TableContainer>
          <StyledTabs variant='open' activeId={tab} onChange={handleChangeTab}>
            <Tab id={OffersTabs.all}>{t('offers.allOffers')}</Tab>
            <Tab id={OffersTabs.top}>{t('offers.topOffers')}</Tab>
            <Tab id={OffersTabs.new}>{t('offers.newOffers')}</Tab>
          </StyledTabs>

          <OffersTable isLoading={isLoading} />

          <Pagination
            page={page}
            pages={offersMeta.pages}
            onChange={(nextPage) => setFilters({ page: nextPage })}
            limitProps={{
              value: limit,
              onChange: (newLimit) =>
                setFilters({ limit: Number(newLimit), page: 1 })
            }}
          />
        </TableContainer>
      </Grid>
    </Page>
  );
};

export default OffersPage;
