import theme from '../theme/theme';
import React from 'react';
import { ModalCloseProps } from './interface';
import styled from 'styled-components';
import Icon from '../icon';
import closeIcon from './close.svg';
import { color } from '../utils/utils';

export const ModalCloseIconContainer = styled.div`
  flex: none;
  display: inline-flex;
  padding: 10px;
  border: 1px solid ${color('border.main')};
  border-radius: 8px;
  color: ${color('common.black')};
  cursor: pointer;
`;

ModalCloseIconContainer.defaultProps = {
  theme
};

const ModalCloseIcon = (props: ModalCloseProps) => {
  const { onClose, ...otherProps } = props;

  return (
    <ModalCloseIconContainer {...otherProps} onClick={onClose}>
      <Icon src={closeIcon} size={12} color='inherit' />
    </ModalCloseIconContainer>
  );
};

export default ModalCloseIcon;
