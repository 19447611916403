import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiStatisticsGet,
  ApiStatisticsClicksGet,
  ApiStatisticsConversionsGet,
  ApiStatisticsGetResponse,
  ApiStatisticsClicksGetResponse,
  ApiStatisticsConversionsGetResponse
} from '../types/api/statistics';

class StatisticsApi {
  static getStatictics(
    params: ApiStatisticsGet
  ): AxiosPromise<ApiStatisticsGetResponse> {
    return Api.call('/statistics', { params });
  }

  static getStaticticsClicks(
    params: ApiStatisticsClicksGet
  ): AxiosPromise<ApiStatisticsClicksGetResponse> {
    return Api.call('/statistics/clicks', { params });
  }

  static getStaticticsConversions(
    params: ApiStatisticsConversionsGet
  ): AxiosPromise<ApiStatisticsConversionsGetResponse> {
    return Api.call('/statistics/conversions', { params });
  }
}

export default StatisticsApi;
