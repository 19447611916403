import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Employee } from '../../types/employees';
import { TableProps } from '../../bit/components/table';

import { useSelector } from 'react-redux';
import { selectEmployess } from '../../redux/slices/employeesSlice';

import Table from '../Table';
import Icon from '../../bit/components/icon';
import Typography from '../../bit/components/typography';

import EditIcon from '../../images/edit.svg';
import DeleteIcon from '../../images/delete.svg';
import useWithTelegram from '../../hooks/useWithTelegram';
import { selectUser } from '../../redux/slices/userSlice';

interface TableEmployeesProps {
  onEditEmployee: (item: Employee) => () => void;
  onDeleteEmployee: (item: Employee) => () => void;
}

const TableEmployees = (props: TableEmployeesProps) => {
  const { t } = useTranslation();
  const { withTelegramByUserLang } = useWithTelegram();

  const user = useSelector(selectUser);
  const isCreator = user.info?.creator;

  const employees = useSelector(selectEmployess);

  const messengerOptions = useMemo(() => {
    return withTelegramByUserLang
      ? [{ dataKey: 'telegram', title: t('employees.telegram') }]
      : [
          { dataKey: 'whatsapp', title: t('common.whatsapp') },
          { dataKey: 'skype', title: t('common.skype') },
          { dataKey: 'wechat', title: t('common.wechat') }
        ];
  }, [t, withTelegramByUserLang]);

  const tableOptions = useMemo<TableProps['options']>(
    () => [
      { dataKey: 'id', title: t('employees.id') },
      { dataKey: 'email', title: t('employees.email') },
      ...messengerOptions,
      { dataKey: 'action', title: t('employees.action') }
    ],
    [t, messengerOptions]
  );

  const tableRows = useMemo<TableProps['rows']>(() => {
    return (employees || []).map((item, i) => ({
      id: (
        <Typography variant='subtitle2' size={14}>
          {item.id}
        </Typography>
      ),
      email: (
        <Typography variant='subtitle2' size={14}>
          {item.email}
        </Typography>
      ),
      telegram: (
        <Typography variant='subtitle2' size={14}>
          {item.messengers.telegram}
        </Typography>
      ),
      whatsapp: (
        <Typography variant='subtitle2' size={14}>
          {item.messengers.whatsapp}
        </Typography>
      ),
      skype: (
        <Typography variant='subtitle2' size={14}>
          {item.messengers.skype}
        </Typography>
      ),
      wechat: (
        <Typography variant='subtitle2' size={14}>
          {item.messengers.wechat}
        </Typography>
      ),
      action: (
        <>
          <Icon src={EditIcon} onClick={props.onEditEmployee(item)} />

          {isCreator && user?.info?.id !== item.id && !item.creator && (
            <Icon
              src={DeleteIcon}
              onClick={props.onDeleteEmployee(item)}
              style={{ marginLeft: 12, cursor: 'pointer' }}
            />
          )}
        </>
      ),
      key: `employees-${item.id}-${item.name}-${item.role}-${item.email}-${item.phone}`
    }));
  }, [employees, props, isCreator, user?.info?.id]);

  return (
    <Table
      fullWidth
      rows={tableRows}
      options={tableOptions}
      isEmpty={!employees.length}
    />
  );
};

export default TableEmployees;
