import React from 'react';
import { Props } from './interface';

import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { color, isFullWidth } from '../../bit/components/utils/utils';
import Typography from '../../bit/components/typography';
import VVTable, { TableProps } from '../../bit/components/table';
import Loader from '../Loader';

const TableContainer = styled.div<Omit<Props, keyof TableProps>>`
  ${isFullWidth};

  > div {
    overflow-x: auto;
  }

  table {
    td,
    th {
      background-color: ${color('background.main')};
      color: ${color('text.themeMain')};

      span {
        color: inherit;
      }
    }

    td {
      vertical-align: middle;
      padding: 10px 16px;
    }

    th {
      text-align: left;
      border-top: ${(props) =>
        props.addBorderTop && `1px solid ${props.theme.palette.border.main}`};
    }
  }
`;

const StyledTable = styled(VVTable)<{ $hideHead?: boolean }>`
  border-radius: 0;
  border: none;

  ${({ $hideHead }) =>
    $hideHead &&
    css`
      & table thead {
        display: none;
      }
      & table tbody tr:first-child td {
        border-top: none;
      }
    `}
`;

const EmptyRow = styled.div`
  background-color: ${color('background.main')};
  border-top: 1px solid ${color('border.main')};
  color: ${color('text.themeMain')};

  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = ({
  rows,
  isEmpty,
  options,
  fullWidth,
  isLoading,
  addBorderTop,
  hideHead = false,
  ...otherProps
}: Props) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      {...otherProps}
      fullWidth={fullWidth}
      addBorderTop={addBorderTop}
    >
      <StyledTable
        options={options}
        $hideHead={hideHead}
        rows={isLoading ? [] : rows}
      />

      {isLoading ? (
        <EmptyRow>
          <Loader isStatic />
        </EmptyRow>
      ) : isEmpty ? (
        <EmptyRow>
          <Typography size={14} variant='subtitle2'>
            {t(`table.empty`)}
          </Typography>
        </EmptyRow>
      ) : null}
    </TableContainer>
  );
};

export default Table;
