import Api from '../../api';
import ManagerialApi from '../../api/managerial';

import saveAs from 'file-saver';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../store';
import formatDate from '../../utils/formatDate';
import Notification from '../../components/Notification';

import {
  ApiAlert,
  ApiManager,
  ApiAffiliate,
  ApiAlertsGetParams,
  ApiAlertsGetResponse,
  ApiRemindersGetParams,
  ApiAffiliatesGetParams,
  ApiAffiliatesGetResponse,
  ApiUpdateAffiliateCommentPostBody,
  ApiRemindersGetResponse,
  ApiReminder,
  ApiFreeAffiliatesGetParams,
  ApiFreeAffiliatesGetResponse,
  ApiCaps,
  ApiCapsGetParams,
  ApiCapsGetResponse,
  ApiOffersByAffiliateIdGetParams,
  ApiOffersByAffiliateIdGetResponse,
  ApiAffiliateCaps,
  ApiOffersByAffiliateIdPutBody,
  ApiOffersByAffiliateIdPutParams,
  ApiAlertPromo,
  ApiAlertsPromoGetParams,
  ApiAlertsPromoGetResponse,
  ApiApproveAffiliatePromoOfferPutBody
} from '../../types/api/managerial';
import { ApiPaginationMeta } from '../../types/api';
import { DEFAULT_PAGINATION } from '../../resources/constants';

interface ManagerialState {
  managers: ApiManager[];
  alerts: {
    list: ApiAlert[];
    meta: ApiPaginationMeta;
  };
  alertsPromo: {
    list: ApiAlertPromo[];
    meta: ApiPaginationMeta;
  };
  caps: {
    list: ApiCaps[];
    meta: ApiPaginationMeta;
  };
  affiliateCaps: {
    list: ApiAffiliateCaps[];
  };
  affiliates: {
    list: ApiAffiliate[];
    meta: ApiPaginationMeta;
  };
  reminders: {
    list: ApiReminder[];
    meta: ApiPaginationMeta;
  };
  freeAffiliates: {
    list: Omit<ApiAffiliate, 'statistic'>[];
    meta: ApiPaginationMeta;
  };
}

const initialState: ManagerialState = {
  managers: [],
  alerts: {
    list: [],
    meta: DEFAULT_PAGINATION
  },
  alertsPromo: {
    list: [],
    meta: DEFAULT_PAGINATION
  },
  caps: {
    list: [],
    meta: DEFAULT_PAGINATION
  },
  affiliateCaps: {
    list: []
  },
  affiliates: {
    list: [],
    meta: DEFAULT_PAGINATION
  },
  reminders: {
    list: [],
    meta: DEFAULT_PAGINATION
  },
  freeAffiliates: {
    list: [],
    meta: DEFAULT_PAGINATION
  }
};

const managerialSlice = createSlice({
  name: 'managerial',
  initialState,
  reducers: {
    setManagerialManagers: (state, action: PayloadAction<ApiManager[]>) => {
      state.managers = action.payload;
    },
    setManagerialAlerts: (
      state,
      action: PayloadAction<ApiAlertsGetResponse>
    ) => {
      state.alerts.list = action.payload.data;
      state.alerts.meta = action.payload.meta;
    },
    setManagerialAlertsPromo: (
      state,
      action: PayloadAction<ApiAlertsPromoGetResponse>
    ) => {
      state.alertsPromo.list = action.payload.data;
      state.alertsPromo.meta = action.payload.meta;
    },
    setManagerialCaps: (state, action: PayloadAction<ApiCapsGetResponse>) => {
      state.caps.list = action.payload.data;
      state.caps.meta = action.payload.meta;
    },
    setManagerialAffiliateCaps: (
      state,
      action: PayloadAction<ApiOffersByAffiliateIdGetResponse>
    ) => {
      state.affiliateCaps.list = action.payload.data;
    },
    setManagerialAffiliates: (
      state,
      action: PayloadAction<ApiAffiliatesGetResponse>
    ) => {
      state.affiliates.list = action.payload.data;
      state.affiliates.meta = action.payload.meta;
    },
    setManagerialReminders: (
      state,
      action: PayloadAction<ApiRemindersGetResponse>
    ) => {
      state.reminders.list = action.payload.data;
      state.reminders.meta = action.payload.meta;
    },
    setManagerialFreeAffiliates: (
      state,
      action: PayloadAction<ApiFreeAffiliatesGetResponse>
    ) => {
      state.freeAffiliates.list = action.payload.data;
      state.freeAffiliates.meta = action.payload.meta;
    }
  }
});

const {
  setManagerialAffiliateCaps,
  setManagerialReminders,
  setManagerialManagers,
  setManagerialAlerts,
  setManagerialAlertsPromo,
  setManagerialFreeAffiliates,
  setManagerialAffiliates,
  setManagerialCaps
} = managerialSlice.actions;

export const updateAffiliatesComments = (
  data: ApiUpdateAffiliateCommentPostBody
): AppThunk => async () => {
  try {
    await ManagerialApi.updateAffiliatesComments(data);

    Notification.show({
      delay: 1200,
      message: 'Данные сохранены'
    });
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const approveAffiliatePromoOffer = (
  data: ApiApproveAffiliatePromoOfferPutBody
): AppThunk => async () => {
  try {
    await ManagerialApi.approveAffiliatePromoOffer(data);

    Notification.show({
      delay: 1200,
      message: 'Данные сохранены'
    });
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const getAffiliatesStatistic = (): AppThunk => async () => {
  try {
    const response = await ManagerialApi.getAffiliatesStatistic();

    if (response.data.message) {
      let message = response.data.message;

      if (
        response.data.info?.lastUpdateTime &&
        response.data.info?.nextPossibleUpdateTime
      ) {
        const lastUpdateTime = formatDate(response.data.info.lastUpdateTime);
        const nextPossibleUpdateTime = formatDate(
          response.data.info.nextPossibleUpdateTime
        );

        message += `\n\nПоследнее обновление: ${lastUpdateTime}. Следующее возможное: ${nextPossibleUpdateTime}`;
      }

      Notification.show({ message, type: 'warning' });
    }

    return response.data;
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialManagersAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await ManagerialApi.getManagers();
    dispatch(setManagerialManagers(response.data.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialAlertsAction = (
  params: ApiAlertsGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getAlerts(params);
    dispatch(setManagerialAlerts(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialAlertsPromoAction = (
  params: ApiAlertsPromoGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getAlertsPromo(params);
    dispatch(setManagerialAlertsPromo(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialCapsAction = (
  params: ApiCapsGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getCaps(params);
    dispatch(setManagerialCaps(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialAffiliatesAction = (
  params: ApiAffiliatesGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getAffiliates(params);

    dispatch(setManagerialAffiliates(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialFreeAffiliatesAction = (
  params: ApiFreeAffiliatesGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getFreeAffiliates(params);

    dispatch(setManagerialFreeAffiliates(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialRemindersAction = (
  params: ApiRemindersGetParams
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await ManagerialApi.getReminders(params);
    dispatch(setManagerialReminders(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const managerialDownloadStatistic = (
  params: Pick<
    ApiAffiliatesGetParams,
    'to' | 'from' | 'managerId' | 'withManagerId'
  >
): AppThunk => async () => {
  try {
    const response = await ManagerialApi.downloadStatistic(params);
    saveAs(response.data, 'partners.xlsx');
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const setManagerialAffiliateCapsAction = (
  params: ApiOffersByAffiliateIdGetParams
): AppThunk => async (dispatch) => {
  try {
    const response = await ManagerialApi.getOffersByAffiliateId(params);
    dispatch(setManagerialAffiliateCaps(response.data));
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const updateManagerialAffiliateCapsAction = (
  id: string | number,
  body: ApiOffersByAffiliateIdPutBody,
  params: ApiOffersByAffiliateIdPutParams
): AppThunk => async () => {
  try {
    await ManagerialApi.updateOfferByIdAndByAffiliateId(id, body, params);

    Notification.show({
      delay: 1200,
      message: 'Данные сохранены'
    });
  } catch (error) {
    Api.handleDefaultError(error as AxiosError);
  }
};

export const selectManagerialManagers = (state: RootState) =>
  state.managerial.managers;

export const selectManagerialReminders = (state: RootState) =>
  state.managerial.reminders;

export const selectManagerialAlerts = (state: RootState) =>
  state.managerial.alerts;

export const selectManagerialAlertsPromo = (state: RootState) =>
  state.managerial.alertsPromo;

export const selectManagerialCaps = (state: RootState) => state.managerial.caps;

export const selectManagerialAffiliateCaps = (state: RootState) =>
  state.managerial.affiliateCaps;

export const selectManagerialAffiliates = (state: RootState) =>
  state.managerial.affiliates;

export const selectManagerialFreeAffiliates = (state: RootState) =>
  state.managerial.freeAffiliates;

export default managerialSlice.reducer;
