import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import { Props } from './interface';

const StyledStatInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 18px 16px;
  min-width: 270px;
`;

const StatInfoTitle = styled(Typography)`
  margin-bottom: 10px;
`;

const StatInfo = (props: Props) => {
  const { value, title, titleColor = 'main', className } = props;

  return (
    <StyledStatInfo className={className}>
      <StatInfoTitle variant='subtitle2' size={14} color={titleColor}>
        {title}
      </StatInfoTitle>
      <Typography variant='subtitle1' size={18} color='main'>
        {value}
      </Typography>
    </StyledStatInfo>
  );
};

export default StatInfo;
