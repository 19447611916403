import { AxiosPromise } from 'axios';
import { ApiReferralsGetResponse } from '../types/api/referrals';
import Api from './index';

class ReferralsApi {
  static getReferrals(): AxiosPromise<ApiReferralsGetResponse> {
    return Api.call('/referrals');
  }
}

export default ReferralsApi;
