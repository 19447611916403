import { createLogger } from 'redux-logger';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import appsReducer from './slices/appsSlice';
import userReducer from './slices/userSlice';
import offersReducer from './slices/offersSlice';
import settingsReducer from './slices/settingsSlice';
import billingsReducer from './slices/billingsSlice';
import employeesReducer from './slices/employeesSlice';
import referralsReducer from './slices/referralsSilica';
import managerialReducer from './slices/managerialSlice';
import statisticsReducer from './slices/statisticsSlice';

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const logger = createLogger({
  collapsed: true
});

export const store = configureStore({
  reducer: {
    apps: appsReducer,
    user: userReducer,
    offers: offersReducer,
    billings: billingsReducer,
    settings: settingsReducer,
    employees: employeesReducer,
    referrals: referralsReducer,
    statistics: statisticsReducer,
    managerial: managerialReducer
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware()
});
