import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import merge from 'deepmerge';
import defaultTheme from '../../bit/components/theme/theme';
import { Props } from './interface';
import { selectTheme, ThemeEnum } from '../../redux/slices/settingsSlice';
import { useSelector } from 'react-redux';

const commonTheme = merge(defaultTheme, {
  borderRadius: '4px',
  font: {
    family: 'Montserrat, sans-serif'
  },
  palette: {
    primary: {
      main: '#D73737',
      dark: '#B13837',
      light: '#F6D8D2'
    },
    text: {
      main: '#212126',
      second: '#989FA6',
      first: '#CCCDD3',
      third: '#6D6D78',
      white: '#FFFFFF',
      black: '#000000',
      accent: '#C74240',
      darkWhite: '#EFEFEF',
      themeMain: '#212126'
    },
    background: {
      main: '#FFFFFF',
      dark: '#343434',
      light: '#F6F4F4',
      dark2: '#3D3D3D'
    }
  },
  components: {
    modal: {
      background: '#ffffff'
    },
    sidebar: {
      width: 300,
      color: '#989FA6',
      background: '#FAFAFA',
      shadow: '2px 0 34px rgba(0, 0, 0, 0.06)'
    },
    page: {
      background: '#F6F4F4'
    },
    sidebarButton: {
      color: '#343434'
    },
    outlinedButton: {
      color: '#D73737',
      hover: '#B72828'
    }
  }
});

const themes = {
  [ThemeEnum.light]: {},
  [ThemeEnum.dark]: {
    palette: {
      background: {
        main: '#3D3D3D'
      },
      text: {
        main: '#EFEFEF',
        themeMain: '#EFEFEF'
      },
      border: {
        main: '#6D6D78'
      }
    },
    components: {
      sidebar: {
        color: '#CCCDD3',
        background: '#3D3D3D',
        shadow: '2px 0 14px rgba(0, 0, 0, 0.15)'
      },
      page: {
        background: '#343434'
      },
      modal: {
        background: '#3D3D3D',
        closeIconColor: '#ffffff'
      },
      dropdownList: {
        hoverBackground: '#484848'
      },
      formElementLabel: {
        color: '#CCCDD3'
      },
      sidebarButton: {
        color: '#EFEFEF'
      },
      outlinedButton: {
        color: '#DF5151',
        hover: '#B72828'
      }
    }
  }
};

const ThemeProvider = (props: Props) => {
  const currentTheme = useSelector(selectTheme);
  const theme = merge(commonTheme, themes[currentTheme]);

  return (
    <StyledThemeProvider theme={theme}>{props.children}</StyledThemeProvider>
  );
};

export default ThemeProvider;
