import React from 'react';
import styled from 'styled-components';
import Input from '../input';
import { ErrorsState, LogInProps } from './interface';
import Button from '../button';
import { color } from '../utils/utils';
import Typography from '../typography';
import { InputValidateState } from '../input-base';
import { ButtonProps } from '../button/interface';

const StyledInput = styled(Input)`
  & + & {
    margin-top: 12px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 400px;
  background: ${color('common.white')};
  border-radius: 8px;
  padding: 48px 32px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

const FormContainer = styled.div`
  display: relative;
  z-index: 5;

  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const BackgroundContainer = styled.div`
  display: absolute;
  left: 0;
  top: 0;
  z-index: 0;
`;

const AdditionalTextContainer = styled.div`
  margin: 16px 0 8px;
`;

const Title: typeof Typography = styled(Typography)`
  margin-bottom: 16px;
`;

const SignIn = (props: LogInProps) => {
  const { config, onSubmit, className, forceErrorsState } = props;

  const [errorsState, setErrorsState] = React.useState<ErrorsState>({});

  React.useEffect(() => {
    if (forceErrorsState) {
      setErrorsState(forceErrorsState);
    }
  }, [forceErrorsState]);

  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (!onSubmit || !formRef.current) return;

      setErrorsState({});

      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = Object.fromEntries(new FormData(formRef.current).entries());

      // check required inputs
      const nextErrors: ErrorsState = {};

      config.inputs.forEach((input) => {
        if (input.name && input.required && !data[input.name]) {
          console.log(input);
          nextErrors[input.name] = InputValidateState.warning;
        }
      });

      if (Object.keys(nextErrors).length) {
        setErrorsState(nextErrors);
      } else {
        onSubmit(data);
      }
    },
    [config.inputs, onSubmit]
  );

  const title = React.useMemo(() => {
    if (config.renderTitle && typeof config.renderTitle === 'function') {
      return config.renderTitle();
    }

    return config.title || '';
  }, [config]);

  const LoginFooter = React.useMemo(() => {
    if (config.renderFooter && typeof config.renderFooter === 'function') {
      return config.renderFooter();
    }

    return null;
  }, [config]);

  const LoginBackground = React.useMemo(() => {
    if (
      config.renderBackground &&
      typeof config.renderBackground === 'function'
    ) {
      return config.renderBackground();
    }

    return null;
  }, [config]);

  return (
    <StyledForm onSubmit={handleSubmit} className={className} ref={formRef}>
      <FormContainer>
        {title && <Title variant='h1'>{title}</Title>}
        {config.inputs.map((input) => {
          if (input.additionalText) {
            return (
              <AdditionalTextContainer key={input._key}>
                <Typography variant='subtitle3' color='second'>
                  {input.additionalText}
                </Typography>
              </AdditionalTextContainer>
            );
          }

          return (
            <StyledInput
              key={input._key}
              validate={errorsState[input.name]}
              {...input}
            />
          );
        })}
        {Boolean(config.buttons.length) && (
          <ButtonsContainer>
            {config.buttons.map((button, index) => {
              return React.isValidElement(button) ? (
                button
              ) : (
                <Button {...button} key={index}>
                  {(button as ButtonProps).children}
                </Button>
              );
            })}
          </ButtonsContainer>
        )}
        {LoginFooter}
      </FormContainer>
      <BackgroundContainer>{LoginBackground}</BackgroundContainer>
    </StyledForm>
  );
};

export default SignIn;
