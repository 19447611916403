import React from 'react';
import { Switch, Route } from 'react-router-dom';
import useRoutes from '../../hooks/useRoutes';

const Router = () => {
  const { routes } = useRoutes();

  return (
    <Switch>
      {Object.values(routes).map((route) => {
        const key = Array.isArray(route.path) ? route.path[0] : route.path;

        return (
          <Route
            key={key}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
};

export default Router;
