import axios, { AxiosError } from 'axios';
import i18n from 'i18next';
import QueryString from 'query-string';
import { getLocalStorage } from '../resources/functions';
import { STORE_USER_TOKEN } from '../resources/constants';
import { logOutAsync } from '../redux/slices/userSlice';
import { store } from '../redux/store';
import Notification from '../components/Notification';

const baseURL = (process.env.BASE_URL || '') + '/api';

export const paramsSerializer = (params: Record<string, any>) => {
  return QueryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true
  });
};

class Api {
  static setToken(token: string) {
    Api.call.defaults.headers['x-access-token'] = token;
  }

  static call = axios.create({
    baseURL,
    paramsSerializer,
    responseType: 'json'
  });

  static handleDefaultError = (
    error: AxiosError & { isCancel?: boolean },
    handleCustomErrors?: (error: AxiosError) => void
  ) => {
    const { response } = error;

    if (response) {
      const { status } = response;
      const message = response.data?.message || response.data?.error;

      switch (status) {
        case 401:
          if (process.env.NODE_ENV === 'development') {
            Notification.show({ message: 'dev logout', type: 'warning' });
            return;
          }

          store.dispatch(logOutAsync({ isError: true }));
          return;

        case 403:
          Notification.show({
            type: 'error',
            message: i18n.t('common.accessDenied')
          });
          return;

        case 500:
          Notification.show({
            type: 'error',
            message: message || 'Server Error'
          });
          return;

        default:
          if (handleCustomErrors) {
            handleCustomErrors(error);
            return;
          }

          Notification.show({
            type: 'error',
            message: message || 'Something went wrong'
          });
          return;
      }
    }

    // TODO
    // NOTE
    // При релоаде c сбросе текущих запросов в Safari вылетает ошибка 'Network error'
    // Решения проблемы пока не знаю, но есть несколько issue
    // https://github.com/axios/axios/issues/3278#issuecomment-1324919092
    // Кажется проблема именно в обработке ошибок Axios (под капотом xhr)

    if (error.message !== 'Network Error') {
      Notification.show({
        type: 'error',
        message: error.message
      });
    }

    // eslint-disable-next-line no-console
    console.error(error);
  };
}

Api.setToken(getLocalStorage(STORE_USER_TOKEN) || '');

export default Api;
