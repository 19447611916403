import { FC } from 'react';
import { createPortal } from 'react-dom';
import { MANAGERIAL_PAGE_HEAD } from '../../../resources/constants';

const SupportManagerialPageHead: FC = ({ children }) => {
  const selector = document.getElementById(MANAGERIAL_PAGE_HEAD);

  if (!selector) return null;

  return createPortal(children, selector);
};

export default SupportManagerialPageHead;
