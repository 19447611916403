import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState, useCallback, useEffect } from 'react';

import { getDate } from '../resources/functions';
import { useQueryParams, withDefault, StringParam } from 'use-query-params';

import Button from '../bit/components/button';
import DatePicker from '../components/DatePicker';

import moment, { Moment } from 'moment';
import Typography from '../components/Typography';
import Notification from '../components/Notification';

const StyledDatePickerContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-end;

  margin-top: -12px;
  margin-left: -12px;

  & > * {
    margin-top: 12px;
    margin-left: 12px;
  }
`;

const isEqualDate = (date1: Date | string, date2: Date | string) => {
  return (
    moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')
  );
};

const useFromToDatePicker = (initialFrom?: Moment, withText?: boolean) => {
  const { t } = useTranslation();

  const [{ to, from }, setQuery] = useQueryParams({
    to: withDefault(StringParam, moment().format('YYYY-MM-DD')),
    from: withDefault(StringParam, moment(initialFrom).format('YYYY-MM-DD'))
  });

  const [clientDates, setClientDates] = useState<{
    endDate: Date;
    startDate: Date;
  }>({
    endDate: moment(to).toDate(),
    startDate: moment(from).toDate()
  });

  const onChangeDatePicker = useCallback(([startDate, endDate]) => {
    setClientDates({ startDate, endDate });
  }, []);

  const onSubmit = useCallback(() => {
    const { startDate, endDate } = clientDates;

    if (!endDate || !startDate) {
      Notification.show({ message: 'Выберите диапазон дат', type: 'warning' });
      return;
    }

    setQuery({
      to: getDate(endDate),
      from: getDate(startDate)
    });
  }, [clientDates, setQuery]);

  useEffect(() => {
    if (
      isEqualDate(to, clientDates.endDate) &&
      isEqualDate(from, clientDates.startDate)
    ) {
      return;
    }

    setClientDates({
      endDate: moment(to).toDate(),
      startDate: moment(from).toDate()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, from]);

  return {
    from,
    to,
    DatePicker: (props: any) => (
      <StyledDatePickerContainer {...props}>
        {withText && (
          <Typography color='second' size={14} variant='subtitle3'>
            {t('statistics.timeZone')}
          </Typography>
        )}

        <DatePicker {...clientDates} onChange={onChangeDatePicker} />

        <Button size='middle' onClick={onSubmit}>
          {t('common.apply')}
        </Button>
      </StyledDatePickerContainer>
    )
  };
};

export default useFromToDatePicker;
