import React from 'react';
import styled from 'styled-components';
import { FormElementLabelProps } from './interface';
import Typography from '../typography';
import { InputValidateState } from '../input-base';
import theme, { Theme } from '../theme/theme';

const StyledFormElementLabel: any = styled(Typography)`
  display: inline-flex;
  margin-bottom: 8px;
  color: ${(props: { validate: InputValidateState; theme: Theme }) => {
    const { validate } = props;

    if (validate === InputValidateState.warning) {
      return props.theme.palette.warning.main;
    } else if (validate === InputValidateState.error) {
      return props.theme.palette.error.main;
    } else {
      return props.theme.components.formElementLabel.color;
    }
  }} !important;
`;

StyledFormElementLabel.defaultProps = {
  theme
};

const FormElementLabel = (props: FormElementLabelProps) => {
  const { children, validate, id, ...otherProps } = props;

  return (
    <StyledFormElementLabel
      {...otherProps}
      validate={validate}
      variant='subtitle3'
      component='label'
      htmlFor={id}
    >
      {children}
    </StyledFormElementLabel>
  );
};

export default FormElementLabel;
