import { InputValidateState } from '../../bit/components/input-base';
import LogInForm, { LogInConfig } from '../../bit/components/log-in';
import { LogInSubmit } from '../../bit/components/log-in/interface';
import { color } from '../../bit/components/utils/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserApi from '../../api/user';
import Modal from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import { Props } from './interface';
import { setTokenAsync } from '../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import Notification from '../../components/Notification';

const Form = styled(LogInForm)`
  background-color: ${color('background.main')};
  max-width: none;
  width: 100%;
  padding: 0;
`;

const PasswordRecoveryModal = ({ onClose, open }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorsState, setErrorsState] = useState<
    Record<string, InputValidateState>
  >({});

  const formConfig = useMemo<LogInConfig>(
    () => ({
      inputs: [
        {
          _key: 'oldPassword',
          name: 'oldPassword',
          type: 'password',
          label: t('fields.oldPassword.label'),
          placeholder: t('fields.oldPassword.placeholder')
        },
        {
          _key: 'password',
          name: 'password',
          type: 'password',
          autoComplete: 'new-password',
          label: t('fields.password.label'),
          placeholder: t('fields.password.placeholder')
        },
        {
          _key: 'repeatPassword',
          name: 'repeatPassword',
          type: 'password',
          autoComplete: 'new-password',
          label: t('fields.repeatPassword.label'),
          placeholder: t('fields.repeatPassword.placeholder')
        }
      ],
      buttons: [
        {
          children: t('code.button'),
          size: 'medium'
        }
      ]
    }),
    [t]
  );

  const handleSubmit = useCallback<LogInSubmit>(
    async (data) => {
      const { oldPassword, password, repeatPassword } = data as Record<
        string,
        string
      >;

      if (!oldPassword) {
        setErrorsState({ oldPassword: InputValidateState.error });
        return;
      } else if (!password || password !== repeatPassword) {
        setErrorsState({
          password: InputValidateState.error,
          repeatPassword: InputValidateState.error
        });
        return;
      }

      try {
        const response = await UserApi.updatePassword({
          oldPassword,
          newPassword: password
        });

        dispatch(setTokenAsync(response.data.token));
        Notification.show({
          type: 'success',
          message: t('updatePassword.success')
        });

        onClose();
      } catch (error) {
        const status = error.response?.status || 500;

        if (status === 409) {
          Notification.show({
            type: 'error',
            message: t('updatePassword.oldPasswordError')
          });
          setErrorsState({ oldPassword: InputValidateState.error });
        }
      }
    },
    [dispatch, onClose, t]
  );

  return (
    <Modal open={open} onClose={onClose} maxWidth={440}>
      <ModalTitle>{t('updatePassword.title')}</ModalTitle>
      <Form
        config={formConfig}
        onSubmit={handleSubmit}
        forceErrorsState={errorsState}
      />
    </Modal>
  );
};

export default PasswordRecoveryModal;
