import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { selectTheme, ThemeEnum } from '../../redux/slices/settingsSlice';

import { PepperWithFlameProps } from './PepperWithFlameIconTypes';
import pepperWithFlame from '../../images/pepperWithFlame.svg';

const StyledIcon = styled(ReactSVG)`
  width: 120px;
  height: 162px;

  * {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    ${(props) =>
      props.isDark &&
      css`
        path {
          fill: black;

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            fill: transparent;
          }
        }
      `};
  }
`;

const PepperWithFlameIcon = (props: PepperWithFlameProps) => {
  const theme = useSelector(selectTheme);

  return (
    <StyledIcon
      src={pepperWithFlame}
      className={props.className}
      isDark={props.isDark ?? theme === ThemeEnum.dark}
    />
  );
};

export default PepperWithFlameIcon;
