import Icon from '../../bit/components/icon';
import React, { useCallback, useEffect, useState } from 'react';

import infoIcon from '../../images/info.svg';
import Popper from '../Popper';
import { Props } from './interface';

const PopperIcon = (props: Props) => {
  const { forceShowPopper, children, onClick } = props;
  const [
    referenceElement,
    setReferenceElement
  ] = useState<HTMLDivElement | null>(null);

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(Boolean(forceShowPopper));
  }, [forceShowPopper]);

  const handleMouseEnter = useCallback(() => {
    setIsShow(forceShowPopper || true);
  }, [forceShowPopper]);

  const handleMouseLeave = useCallback(() => {
    setIsShow(forceShowPopper || false);
  }, [forceShowPopper]);

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon src={infoIcon} ref={setReferenceElement} />
      </div>
      <Popper
        referenceElement={referenceElement}
        isShow={isShow}
        maxWidth={430}
      >
        {children}
      </Popper>
    </>
  );
};

export default PopperIcon;
