import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectSettings, ThemeEnum } from '../redux/slices/settingsSlice';

const useIconErrorColor = () => {
  const theme = useTheme();
  const { theme: themeMode } = useSelector(selectSettings);
  const isDarkTheme = themeMode === ThemeEnum.dark;

  // @ts-ignore
  const primaryLightColor = theme.palette.primary.light;
  // @ts-ignore
  const textMainColor = theme.palette.text.main;

  return isDarkTheme ? textMainColor : primaryLightColor;
};

export default useIconErrorColor;
