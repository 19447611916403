import deepEqual from 'deep-equal';
import React, { useState, useCallback, useEffect } from 'react';

import Input from './Input';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slices/userSlice';
import { useTranslation } from 'react-i18next';
import {
  AffiliateParam,
  ChangeAffiliateParamHandler
} from '../hooks/useStatisticPageSubIds';

interface Props {
  subId1: string | undefined;
  subId2: string | undefined;
  subId3: string | undefined;
  subId4: string | undefined;
  subId5: string | undefined;
  affParam2: string | undefined;
  affParam5: string | undefined;
  onChangeAffiliateParam: ChangeAffiliateParamHandler;
  onChange: (index: number, value: string | undefined) => void;
}

const StatisticPageSubIds = ({
  onChange: onChangeSubId,
  onChangeAffiliateParam,
  ...query
}: Props) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const [affParam2, setAffParam2] = useState(query.affParam5);
  const [affParam5, setAffParam5] = useState(query.affParam5);
  const [clientSubIds, setClientSubIds] = useState([
    query.subId1,
    query.subId2,
    query.subId3,
    query.subId4,
    query.subId5
  ]);

  const createSubIdHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSubId(index, event.target.value || undefined);
      setClientSubIds((prevState) => {
        const nextState = prevState.slice();
        nextState[index] = event.target.value;
        return nextState;
      });
    },
    [onChangeSubId]
  );

  const createChangeAffiliateParamHandler = useCallback(
    (param: AffiliateParam) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (param === 'affParam2') {
        setAffParam2(event.target.value);
      }

      if (param === 'affParam5') {
        setAffParam5(event.target.value);
      }

      onChangeAffiliateParam(param, event.target.value || undefined);
    },
    [onChangeAffiliateParam]
  );

  useEffect(() => {
    if (query.affParam5 !== affParam5) {
      setAffParam5(query.affParam5);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.affParam5]);

  useEffect(() => {
    const queryValues = [
      query.subId1,
      query.subId2,
      query.subId3,
      query.subId4,
      query.subId5
    ];

    if (!deepEqual(queryValues, clientSubIds)) {
      setClientSubIds(queryValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.subId1, query.subId2, query.subId3, query.subId4, query.subId5]);

  return (
    <>
      {clientSubIds.map((value, index) => (
        <Input
          key={index}
          value={value}
          placeholder={`Sub ID ${index + 1}`}
          onChange={createSubIdHandler(index)}
        />
      ))}

      {user.info?.creator && (
        <Input
          value={affParam5}
          placeholder={t('reportTypes.employeeId')}
          onChange={createChangeAffiliateParamHandler('affParam5')}
        />
      )}

      <Input
        value={affParam2}
        placeholder={t('reportTypes.domain')}
        onChange={createChangeAffiliateParamHandler('affParam2')}
      />
    </>
  );
};

export default StatisticPageSubIds;
