import React from 'react';
import styled from 'styled-components';
import { SidebarProps } from './interface';
import theme from '../theme/theme';
import { transition } from '../utils/utils';

const StyledSidebar = styled.div<SidebarProps>`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.components.sidebar.background};
  width: ${(props) => {
    const { collapsed, theme } = props;
    const { collapsedWidth, width } = theme.components.sidebar;
    return collapsed ? collapsedWidth : width;
  }}px;
  box-shadow: 2px 0px 34px rgba(0, 0, 0, 0.04);
  padding: 32px 24px;
  ${transition({ name: 'width' })};
`;

StyledSidebar.defaultProps = {
  theme
};

const Sidebar = (props: SidebarProps) => {
  const { children, ...otherProps } = props;

  return <StyledSidebar {...otherProps}>{children}</StyledSidebar>;
};

export default Sidebar;
