import Api from './index';
import {
  ApiBillingsBalanceResponse,
  ApiBillingsDetailsResponse,
  ApiGetBillings
} from '../types/api/billings';
import { AxiosPromise } from 'axios';

class BillingApi extends Api {
  static getBillingsBalance(): AxiosPromise<ApiBillingsBalanceResponse> {
    return Api.call('/billings');
  }

  static getBillings(params?: ApiGetBillings) {
    return Api.call('/billings/payments', { params });
  }

  static getBillingsDetails(): AxiosPromise<ApiBillingsDetailsResponse> {
    return Api.call('/billings/details');
  }
}

export default BillingApi;
