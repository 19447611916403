import React from 'react';
import { Props } from './interface';
import Typography from '../Typography';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';

const StyledModalTitle: typeof Typography = styled(Typography)`
  margin-bottom: 28px;
  margin-right: 46px;
  text-transform: uppercase;
  color: ${color('text.themeMain')};
`;

const ModalTitle = (props: Props) => {
  const { children } = props;

  return <StyledModalTitle variant='h1'>{children}</StyledModalTitle>;
};

export default ModalTitle;
