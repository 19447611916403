import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-spinner-material';
import { Props } from './interface';

import Modal from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import LogIn, { ErrorsState, LogInConfig } from '../../bit/components/log-in';
import { useDispatch } from 'react-redux';
import { editAppAsync } from '../../redux/slices/appsSlice';
import Button from '../../bit/components/button';
import { AxiosError } from 'axios';
import Notification from '../../components/Notification';
import Switcher from '../../components/Switcher';
import useBoolState from '../../hooks/useBoolState';

// import { InputValidateState } from '../../../bit/components/input-base';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`;

const StyledForm = styled(LogIn)`
  padding: 0;
  background: none;

  min-width: auto;
`;

const StyledButton: typeof Button = styled(Button)`
  position: relative;
  margin-top: 18px;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -9px;
  margin-top: -9px;
  color: #ffffff;
`;

const AppModal = ({ open, onClose, app, onUpdate }: Props) => {
  const { state: hasAllowMassApps, toggle: changeAllowMassApps } = useBoolState(
    false
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorsState, setErrorsState] = useState<ErrorsState>({});

  const [isFetching, setIsFetching] = useState(false);

  const [ids, setIds] = useState<string[]>(['', '']);

  const onSubmitForm = useCallback(
    async (data) => {
      if (app) {
        let acts: string[];

        if (hasAllowMassApps) {
          acts = data.acts.replace(/\D+/gm, ',').split(',').filter(Boolean);
        } else {
          acts = (Object.values(data) as string[])
            .map((id) => id.replace(/\D+/gm, ''))
            .filter(Boolean);
        }

        setIsFetching(true);

        const handleError = (status: number, error: AxiosError) => {
          if (error?.response?.data?.error) {
            Notification.show({
              type: 'error',
              message: error?.response?.data?.error
            });
          } else if (status === 400) {
            Notification.show({
              type: 'error',
              message: t('apps.errorUpdate')
            });
          }
        };

        const handleSuccess = () => {
          onClose();

          if (onUpdate) {
            onUpdate();
          }

          Notification.show({
            type: 'success',
            message: t('apps.successUpdate')
          });
        };

        await dispatch(
          editAppAsync(
            { appId: app?.id, acts, appName: app?.name },
            app.type,
            handleSuccess,
            handleError
          )
        );

        setIsFetching(false);
      }
    },
    [hasAllowMassApps, app, dispatch, onClose, onUpdate, t]
  );

  const title = useMemo(() => {
    return t('apps.newCabinet');
  }, [t]);

  const createInputHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setIds((prevState) => {
        const nextState = prevState.slice();
        if (nextState[index + 1] === undefined) {
          nextState.push('');
        }

        nextState[index] = event.target.value;

        if (!nextState[index] && nextState.length > 1) {
          nextState.splice(index, 1);
        }
        return nextState;
      });
    },
    []
  );

  const inputs = useMemo(() => {
    if (hasAllowMassApps) {
      return [
        {
          _key: `acts`,
          multiline: true,
          rows: 10,
          initialValue: '',
          autoComplete: 'new-password',
          name: `acts`,
          label: t('fields.accountId.label'),
          placeholder: t('fields.accountId.placeholder')
        }
      ];
    }

    return ids.map((id, index) => ({
      _key: `empoyee-account-id-${index}`,
      initialValue: '',
      autoComplete: 'new-password',
      name: `account-id-${index}`,
      label: `${t('fields.accountId.label')} ${index + 1}`,
      placeholder: `${t('fields.accountId.placeholder')} ${index + 1}`,
      onChange: createInputHandler(index)
    }));
  }, [hasAllowMassApps, createInputHandler, ids, t]);

  const config = useMemo<LogInConfig>(() => {
    return {
      title: '',
      inputs,
      buttons: [],
      renderFooter: () => (
        <StyledButton size='middle' disabled={isFetching}>
          {isFetching ? (
            <StyledSpinner visible stroke={2} color='inherit' radius={18} />
          ) : (
            t('common.save')
          )}
        </StyledButton>
      )
    };
  }, [inputs, isFetching, t]);

  return (
    <Modal open={open} onClose={onClose} maxWidth={400}>
      <ModalTitle>{title}</ModalTitle>
      <Container>
        <Switcher
          isRow
          active={!hasAllowMassApps}
          style={{ marginBottom: 20 }}
          onChange={changeAllowMassApps}
          label={t('apps.addSeparately')}
        />

        <StyledForm
          config={config}
          onSubmit={onSubmitForm}
          forceErrorsState={errorsState}
        />
      </Container>
    </Modal>
  );
};

export default AppModal;
