import LangSelect, {
  icons,
  LanguageSelectProps
} from '../../bit/components/language-select';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Languages } from '../../types';
import { Props } from './interface';

import ua from './ua.svg';

const LanguageSelect = ({ isShort, className }: Props) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const options: LanguageSelectProps<Languages>['options'] = useMemo(() => {
    return [
      {
        value: Languages.en,
        name: isShort ? 'En' : 'English',
        icon: icons.en
      },
      {
        value: Languages.ua,
        name: isShort ? 'Ua' : 'Українська',
        icon: ua
      },
      {
        value: Languages.ru,
        name: isShort ? 'Ру' : 'Русский',
        icon: icons.ru
      }
    ];
  }, [isShort]);

  const handleChange = useCallback(
    (nextLanguage: Languages) => {
      let href = window.location.origin;

      if (nextLanguage !== Languages.en) {
        href += `/${nextLanguage}`;
      }

      href += location.pathname;

      window.location.href = href;
    },
    [location]
  );

  return (
    <LangSelect
      className={className}
      isShort={isShort}
      value={i18n.language as Languages}
      onChange={handleChange}
      options={options}
    />
  );
};

export default LanguageSelect;
