import { AppLandingsOffer } from '../types/apps';
import { ApiAppsLandingsOffer } from '../types/api/apps';

export const mapAppLandingsOffer = (
  item: ApiAppsLandingsOffer
): AppLandingsOffer => {
  try {
    return {
      ...item,
      nameCompany: JSON.parse(item.nameCompany)
    };
  } catch (_) {
    return {
      ...item,
      nameCompany: {
        name: '',
        stream_id: ''
      }
    };
  }
};

export const mapAppLandingsOffers = (
  a: ApiAppsLandingsOffer[]
): AppLandingsOffer[] => a.map(mapAppLandingsOffer);
