import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchReferralsAsync,
  selectReferrals
} from '../../redux/slices/referralsSilica';
import Table from '../Table';
import { useTranslation } from 'react-i18next';

const ReferralBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReferralsAsync());
  }, [dispatch]);

  const referrals = useSelector(selectReferrals);

  const rows = useMemo(
    () =>
      referrals.items.map((referral, index) => ({
        key: index,
        ...referral
      })),
    [referrals.items]
  );

  return (
    <Table
      options={[
        { title: t('common.title'), dataKey: 'referral' },
        { title: t('common.sum'), dataKey: 'referralCommission' }
      ]}
      rows={rows}
      isEmpty={!rows.length}
    />
  );
};

export default ReferralBlock;
