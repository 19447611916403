import { StyledConfig } from 'styled-components';

const transientProps: StyledConfig = {
  shouldForwardProp: (propName: string) => {
    if (propName === 'as') return false;
    if (propName.startsWith('$')) return false;

    return true;
  }
};

export default transientProps;
