import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectAppsCabinets } from '../../redux/slices/appsSlice';
import Typography from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { TableOption, TableRow } from '../../bit/components/table/interface';
import Table from '../../components/Table';
import Loader from '../../components/Loader';

const StyledLoaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CabinetName = styled('div')`
  min-width: 140px;
  height: 39px !important;
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 12px;
  display: block;
  color: #d73737;
`;

interface AppsCabinetsTableProps {
  isLoading: boolean;
}

const AppsCabinetsTable = ({ isLoading }: AppsCabinetsTableProps) => {
  const { t } = useTranslation();

  const appsCabinets = useSelector(selectAppsCabinets);

  const config = useMemo(() => {
    const result = Object.keys(appsCabinets);

    if (!result.length) {
      return (
        <Typography
          color='main'
          variant='subtitle2'
          style={{ textAlign: 'center' }}
        >
          {t('table.empty')}
        </Typography>
      );
    }

    return result.map((appName, i) => {
      const cabinets = appsCabinets[appName];
      const isLastTable = result.length - i === 1;

      const tableOptions: TableOption[] = [
        {
          dataKey: 'cabinets',
          title: t('support.cabinets')
        },
        {
          dataKey: 'status',
          title: t('support.status'),
          width: '160px'
        },
        {
          dataKey: 'requestTime',
          title: t('apps.requestTime'),
          width: '190px'
        },
        {
          dataKey: 'sharedTime',
          title: t('apps.sharedTime'),
          width: '190px'
        }
      ];

      const tableRows: TableRow[] = cabinets.map((a) => {
        const status = t(
          !a.isPartnerResponse
            ? 'support.statusBanned'
            : a.isShared
            ? 'support.statusDone'
            : 'support.statusProcess'
        );
        return {
          key: a.cabinetId,
          status,
          sharedTime: a.sharedTime || '-',
          requestTime: a.requestTime || '-',
          cabinets: <CabinetName>{a.cabinetId}</CabinetName>
        };
      });

      return (
        <div
          key={appName}
          style={!isLastTable ? { marginBottom: 24 } : undefined}
        >
          <StyledTypography variant='subtitle1'>{appName}</StyledTypography>

          <Table
            fullWidth
            options={tableOptions}
            isEmpty={!tableRows.length}
            rows={tableRows}
          />
        </div>
      );
    });
  }, [appsCabinets, t]);

  if (isLoading) {
    return (
      <StyledLoaderWrapper>
        <Loader isStatic />
      </StyledLoaderWrapper>
    );
  }

  return <>{config}</>;
};

export default AppsCabinetsTable;
