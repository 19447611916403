import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiUsersLoginPost,
  ApiUsersConfirmPost,
  ApiUsersRegisterPost,
  ApiUsersRegisterPostResponse,
  ApiUsersResetPassword,
  ApiUsersConfirmReset,
  ApiUsersResetPasswordResponse,
  ApiUsersResponse,
  ApiUsersGet,
  ApiUsersUpdatePasswordParams,
  ApiUsersUpdatePasswordResponse
} from '../types/api/users';

class UserApi {
  static login(data: ApiUsersLoginPost) {
    return Api.call('/users/login', {
      data,
      method: 'POST'
    });
  }

  static confirm(data: ApiUsersConfirmPost) {
    return Api.call('/users/confirm', {
      data,
      method: 'POST'
    });
  }

  static register(
    data: ApiUsersRegisterPost
  ): AxiosPromise<ApiUsersRegisterPostResponse> {
    return Api.call('/users/register', {
      data,
      method: 'POST'
    });
  }

  static resetPassword(
    data: ApiUsersResetPassword
  ): AxiosPromise<ApiUsersResetPasswordResponse> {
    return Api.call('/users/reset_password', { data, method: 'POST' });
  }

  static confirmReset(data: ApiUsersConfirmReset): AxiosPromise<any> {
    return Api.call('/users/confirm_reset', { data, method: 'PUT' });
  }

  static updatePassword(
    data: ApiUsersUpdatePasswordParams
  ): AxiosPromise<ApiUsersUpdatePasswordResponse> {
    return Api.call('/users/update_password', { data, method: 'PUT' });
  }

  static getInfo() {
    return Api.call('/users/info');
  }

  static getCompanyInfo() {
    return Api.call('/companies/info');
  }

  static getUsers(params?: ApiUsersGet): AxiosPromise<ApiUsersResponse> {
    return Api.call('/users/main', { params });
  }
}

export default UserApi;
