import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import Api from '../api';
import { AxiosError } from 'axios';
import { ApiManager } from '../types/api/managerial';

import Modal from '../bit/components/modal';
import withStaticModal, {
  StaticModalWrappedComponent
} from './withStaticModal';

import Input from '../components/Input';
import Button from '../components/Button';
import ModalTitle from '../components/ModalTitle';
import SectionsSettings from '../components/SectionsSettings';

import useBoolState from '../hooks/useBoolState';
import { SectionsCredentials } from '../types/employees';
import { patchUserCredentials } from '../utils/setCredentials';
import { setManagerialManagersAction } from '../redux/slices/managerialSlice';
import Notification from '../components/Notification';

const ModalAdministrationCredentials: StaticModalWrappedComponent<ApiManager> = ({
  isOpen,
  onClose,
  data
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state: isLoading, setTrue, setFalse } = useBoolState();

  const [
    sectionsCredentials,
    setSectionsCredentials
  ] = useState<SectionsCredentials>();

  const onSubmit = async () => {
    if (!sectionsCredentials || !data?.userCredentials || !data?.userId) return;

    try {
      setTrue();

      await patchUserCredentials(
        { id: data.userId, credentials: data.userCredentials },
        sectionsCredentials,
        true
      );
      await dispatch(setManagerialManagersAction());

      Notification.show({
        delay: 1200,
        message: t('administration.modalCredentials.credentialsSaveSuccess')
      });

      onClose();
    } catch (error) {
      Api.handleDefaultError(error as AxiosError);
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setSectionsCredentials(data?.userCredentials || undefined);
  }, [data?.userCredentials, isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={700}>
      <ModalTitle>
        {t('administration.modalCredentials.credentialsSettings')}
      </ModalTitle>

      <Input disabled value={data?.userEmail || ''} />

      <SectionsSettings
        isAdministration
        withTitle={false}
        style={{ marginTop: 24 }}
        sections={['support', 'managerial']}
        sectionsCredentials={sectionsCredentials}
        setSectionsCredentials={setSectionsCredentials}
      />

      <Button
        size='big'
        onClick={onSubmit}
        isLoading={isLoading}
        style={{ marginTop: 20 }}
      >
        {t('administration.modalCredentials.credentialsSave')}
      </Button>
    </Modal>
  );
};

export default withStaticModal<ApiManager>(ModalAdministrationCredentials);
