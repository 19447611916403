import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBillings,
  selectBillingPayments
} from '../../redux/slices/billingsSlice';
import { getDayEnd, getDayStart } from '../../resources/functions';
import { PaymentStatus } from '../../types';

// temporary hidden
// import Input from '../Input';
// import Icon from '../../../bit/components/icon';
// import searchIcon from '../../images/search.svg';

import { useTranslation } from 'react-i18next';
import DatePicker from '../DatePicker';
import Button from '../../bit/components/button';
import Table from '../Table';

import * as S from './styled';

const PaymentsBlock = () => {
  const { t } = useTranslation();
  const payments = useSelector(selectBillingPayments);
  const dispatch = useDispatch();

  const [localDates, setLocalDates] = useState([getDayStart(), getDayEnd()]);

  const [status, setStatus] = useState('status');
  const [dates, setDates] = useState([localDates[0], localDates[1]]);
  const [from, to] = dates;

  useEffect(() => {
    if (from && to) {
      dispatch(
        fetchBillings({
          status,
          from: getDayStart(from).toISOString(),
          to: getDayEnd(to).toISOString()
        })
      );
    }
  }, [dispatch, from, to, status]);

  const handleChangeDates = useCallback((nextDates: [Date, Date]) => {
    setLocalDates(nextDates);
  }, []);

  const applyFilters = useCallback(() => {
    setDates(localDates);
  }, [localDates]);

  const statusOptions = useMemo(
    () => [
      {
        value: 'status',
        name: t('billing.all')
      },
      {
        value: 'paid',
        name: t('billing.paid')
      },
      {
        value: 'not_paid',
        name: t('billing.notPaid')
      }
    ],
    [t]
  );

  const handleChangeStatus = useCallback((value) => {
    setStatus(value);
  }, []);

  return (
    <S.StyledContainer>
      <S.StyledHeader>
        {/* temporary hidden */}
        {/* <Input
          placeholder={t('common.search')}
          endIcon={<Icon src={searchIcon} />}
        /> */}
        <S.StyledDropdown
          value={status}
          onChange={handleChangeStatus}
          options={statusOptions}
        />
        <DatePicker
          showMonthDropdown
          showYearDropdown
          startDate={localDates[0]}
          endDate={localDates[1]}
          onChange={handleChangeDates}
        />
        <Button onClick={applyFilters} size='middle'>
          {t('common.apply')}
        </Button>
      </S.StyledHeader>
      <Table
        addBorderTop
        options={[
          { dataKey: 'date', title: t('common.date') },
          { dataKey: 'paymentId', title: t('billing.paymentId') },
          { dataKey: 'invoice', title: t('billing.invoice') },
          { dataKey: 'status', title: t('common.status') },
          { dataKey: 'sum', title: t('common.sum') },
          { dataKey: 'notes', title: t('common.notes') }
        ]}
        isEmpty={!payments.length}
        rows={payments.map((payment) => ({
          ...payment,
          invoice: payment.invoice,
          paymentId: payment.id,
          key: payment.id,
          status: t(`statuses.${PaymentStatus[payment.status]}`)
        }))}
      />
    </S.StyledContainer>
  );
};

export default PaymentsBlock;
