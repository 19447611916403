import { createSlice } from '@reduxjs/toolkit';
import { Referral } from '../../types/referrals';
import { AppThunk, RootState } from '../store';
import ReferralsApi from '../../api/referrals';
import Api from '../../api';
import { mapReferrals } from '../../maps/referralsMaps';

interface ReferralsState {
  items: Referral[];
}

const initialState: ReferralsState = {
  items: []
};

const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    setReferrals: (state, action) => {
      state.items = mapReferrals(action.payload.data);
    }
  }
});

const { setReferrals } = referralsSlice.actions;

export const fetchReferralsAsync = (): AppThunk => async (dispatch) => {
  try {
    const response = await ReferralsApi.getReferrals();
    dispatch(setReferrals(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const selectReferrals = (state: RootState) => state.referrals;

export default referralsSlice.reducer;
