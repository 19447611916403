import Button from '../../bit/components/button';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';

const OutlinedButton: typeof Button = styled(Button)`
  white-space: nowrap;
  background-color: transparent;
  color: ${color('text.second')};
  border: 1px solid ${color('text.first')};

  &:hover,
  &:active {
    opacity: 1;
    background-color: transparent;
    border-color: ${color('text.main')};
    color: ${color('text.main')};
  }
`;

export default OutlinedButton;
