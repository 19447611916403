export enum ReadOnlyInfoItemType {
  html = 'html'
}

export interface ReadOnlyInfoItem {
  label: string;
  value: string;
  id: string;
  type?: ReadOnlyInfoItemType;
}

export interface Props {
  info: ReadOnlyInfoItem[];
}

export interface ReadOnlyInfoValueProps {
  value: string;
  type?: ReadOnlyInfoItemType;
}
