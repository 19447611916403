/* eslint-disable max-len */

import React from 'react';
import Icon from './Icon/Icon';
import { IconProps } from './Icon/IconTypes';
import useIconErrorColor from '../hooks/useIconErrorColor';

const Icon404 = ({
  customWidth = 325,
  customHeight = 109,
  ...restProps
}: IconProps) => {
  const iconColor = useIconErrorColor();

  return (
    <Icon
      {...restProps}
      viewBox='0 0 325 109'
      customWidth={customWidth}
      customHeight={customHeight}
    >
      <path
        d='M5.396 94.48V78.928L34.868 36.88H55.508V78.16H62.9V94.48H55.508V106H36.5V94.48H5.396ZM37.94 58.096L24.692 78.16H37.94V58.096ZM255.521 94.48V78.928L284.993 36.88H305.633V78.16H313.025V94.48H305.633V106H286.625V94.48H255.521ZM288.065 58.096L274.817 78.16H288.065V58.096Z'
        fill={iconColor}
      />
      <path
        d='M213.929 85.3333H111.071V44H90.5V106H234.5V44H213.929V85.3333Z'
        fill={iconColor}
      />
    </Icon>
  );
};

export default Icon404;
