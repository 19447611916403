import React, { useState, useEffect, useCallback, useRef } from 'react';

import { NotificationItemProps } from './NotificationItemTypes';
import { StyledClose, StyledNotification } from './NotificationItemStyled';
import Typography from '../../bit/components/typography';
import closeWhiteIcon from '../../images/closeWhite.svg';

const animationDelay = 500;
const delay = 5000;

const NotificationItem = ({ data, hide, id }: NotificationItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isRender, setRenderState] = useState(false);
  const [isVisible, setVisibleState] = useState(false);
  const [isReady, setReadyState] = useState(false);

  const remove = useCallback(() => {
    setVisibleState(false);

    setTimeout(() => {
      setRenderState(false);
      hide(id);
    }, animationDelay);
  }, [hide, id]);

  useEffect(() => {
    const timeoutDelay = data.delay || delay;

    setRenderState(true);

    setTimeout(() => {
      setVisibleState(true);

      setTimeout(() => {
        setReadyState(true);
      }, animationDelay);
    });

    setTimeout(remove, timeoutDelay);
  }, [data.delay, remove]);

  if (!isRender) return null;

  const height = ref.current?.clientHeight;
  const style = height && isReady ? { height } : undefined;

  return (
    <StyledNotification
      ref={ref}
      style={style}
      $type={data.type}
      $isVisible={isVisible}
    >
      <Typography variant='subtitle3' style={{ whiteSpace: 'pre-line' }}>
        {data.message}
      </Typography>
      <StyledClose src={closeWhiteIcon} onClick={remove} />
    </StyledNotification>
  );
};

export default NotificationItem;
