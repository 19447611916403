import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';

import Dropdown from '../Dropdown';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${color('background.main')};
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 24px;
  margin: -8px;

  > * {
    margin: 8px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 160px;
`;
