/* eslint-disable max-len */

import React from 'react';
import Icon from './Icon/Icon';
import { IconProps } from './Icon/IconTypes';
import useIconErrorColor from '../hooks/useIconErrorColor';

const Icon500 = ({
  customWidth = 259,
  customHeight = 109,
  ...restProps
}: IconProps) => {
  const iconColor = useIconErrorColor();

  return (
    <Icon
      {...restProps}
      customWidth={customWidth}
      customHeight={customHeight}
      viewBox='0 0 259 109'
    >
      <path
        d='M54.816 52.432H26.016V63.088C27.232 61.808 28.896 60.784 31.008 60.016C33.12 59.248 35.456 58.864 38.016 58.864C42.624 58.864 46.464 59.92 49.536 62.032C52.672 64.144 54.976 66.928 56.448 70.384C57.92 73.84 58.656 77.616 58.656 81.712C58.656 89.264 56.512 95.216 52.224 99.568C47.936 103.856 41.984 106 34.368 106C29.056 106 24.448 105.104 20.544 103.312C16.704 101.456 13.76 98.896 11.712 95.632C9.664 92.368 8.608 88.624 8.544 84.4H26.88C27.072 85.872 27.712 87.152 28.8 88.24C29.888 89.328 31.488 89.872 33.6 89.872C35.84 89.872 37.536 89.104 38.688 87.568C39.84 86.032 40.416 83.984 40.416 81.424C40.416 78.928 39.808 77.04 38.592 75.76C37.376 74.416 35.616 73.744 33.312 73.744C31.584 73.744 30.176 74.192 29.088 75.088C28.064 75.92 27.456 76.976 27.264 78.256H8.928V35.824H54.816V52.432Z'
        fill={iconColor}
      />
      <path
        d='M142.929 85.3333H100.071V44H79.5V106H163.5V44H142.929V85.3333Z'
        fill={iconColor}
      />
      <path
        d='M238.429 85.3333H195.571V44H175V106H259V44H238.429V85.3333Z'
        fill={iconColor}
      />
    </Icon>
  );
};

export default Icon500;
