import { color, transition } from '../utils/utils';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarItemBaseProps } from './interface';
import theme from '../theme/theme';

const SidebarIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${color('border.main')};
  margin-right: 16px;
  ${transition({ name: 'background-color' })};
`;

SidebarIconContainer.defaultProps = {
  theme
};

const StyledSidebarItem = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &.active {
    ${SidebarIconContainer} {
      background-color: ${color('primary.light')};
    }
  }
`;

StyledSidebarItem.defaultProps = {
  theme
};

const SidebarItem = (props: SidebarItemBaseProps) => {
  const { path, exact, children, ...otherProps } = props;

  return (
    <StyledSidebarItem
      {...otherProps}
      exact={exact}
      to={path}
      activeClassName='active'
    >
      {children}
    </StyledSidebarItem>
  );
};

export default SidebarItem;
