import styled, { css } from 'styled-components';
import { NotificationItemStyledProps } from './NotificationItemTypes';

export const StyledNotification = styled('div')<NotificationItemStyledProps>`
  overflow: hidden;
  transition: all 500ms;

  width: 100%;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;

  /* TODO: error state */

  padding: 16px;
  border-radius: 8px;

  ${({ $type }) => {
    if ($type === 'warning') {
      return css`
        background: #ac970d;
      `;
    }

    if ($type === 'error') {
      return css`
        background: #ac0d0d;
      `;
    }

    return css`
      background: #0dac54;
    `;
  }};

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      margin: 0;
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
      height: 0 !important;
    `};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledClose = styled.img`
  opacity: 0.5;
  margin-top: 2px;
  margin-left: 12px;

  width: 16px;
  font-size: 16px;

  cursor: pointer;
  transition: all 250ms;

  path {
    fill: red;
  }

  &:hover {
    opacity: 1;
  }
`;
