import React, { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQueryParam,
  useQueryParams,
  withDefault,
  StringParam
} from 'use-query-params';
import { TableProps } from '../../bit/components/table';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectStatisticsClicks,
  fetchStatisticsClicks
} from '../../redux/slices/statisticsSlice';
import {
  ApiStatisticsClicksGet,
  ApiStatisticFilters
} from '../../types/api/statistics';

import useAwaitCallback from '../../hooks/useAwaitCallback';

import Table from '../Table';
import Offer from '../Offer';
import Typography from '../Typography';
import OffersDropdown from '../OffersDropdown';
import SupportManagerialPageHead from '../../pages/SupportManagerialPage/components/SupportManagerialPageHead';
import {
  HeaderFiltration,
  HeaderFiltrationRow
} from '../../pages/StatisticsPage/styled';
import useStatisticPageSubIds from '../../hooks/useStatisticPageSubIds';
import StatisticPageCountry from '../StatisticPageCountry';
import StatisticPageSubIds from '../StatisticPageSubIds';
import { DropdownOnChange } from '../Dropdown';

const TableClicks = () => {
  const { t } = useTranslation();
  const clicks = useSelector(selectStatisticsClicks);

  const dispatch = useDispatch();

  const [to] = useQueryParam<string | undefined>('to');
  const [from] = useQueryParam<string | undefined>('from');
  const [query, setQuery] = useQueryParams({
    offers: withDefault(StringParam, undefined),
    landing: withDefault(StringParam, undefined),
    countries: withDefault(StringParam, undefined)
  });

  const { offers, landing, countries } = query;

  const {
    onChangeAffiliateParam,
    onChangeSubId,
    affParam2,
    affParam5,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5
  } = useStatisticPageSubIds();

  const params = useMemo(() => {
    const result: ApiStatisticFilters = {
      offers,
      countries,
      offersLinks: landing,
      affiliatesSubids1: subId1,
      affiliatesSubids2: subId2,
      affiliatesSubids3: subId3,
      affiliatesSubids4: subId4,
      affiliatesSubids5: subId5,
      affiliatesParams5: affParam5
    };
    return result;
  }, [
    countries,
    landing,
    offers,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5,
    affParam5
  ]);

  const [fetchClicks, isLoading] = useAwaitCallback(
    async (params: ApiStatisticsClicksGet) => {
      await dispatch(fetchStatisticsClicks(params));
    },
    [dispatch]
  );

  useEffect(() => {
    const data: ApiStatisticsClicksGet = {
      to,
      from,
      ...params
    };

    fetchClicks(data);
  }, [fetchClicks, from, offers, to, params]);

  const tableOptions = React.useMemo<TableProps['options']>(
    () => [
      { dataKey: 'date', title: t('clicks.date') },
      { dataKey: 'offer', title: t('clicks.offer') },
      { dataKey: 'geo', title: t('clicks.geo') }
    ],
    [t]
  );

  const tableRows = React.useMemo<TableProps['rows']>(() => {
    return (clicks || []).map((item, i) => {
      const offerId = parseInt(item.offer);
      const offerTitle = item.offer.replace(String(offerId), '').trim();

      return {
        date: (
          <Typography variant='subtitle2' size={14}>
            {item.date}
          </Typography>
        ),
        geo: (
          <Typography variant='subtitle2' size={14}>
            {item.geo.country}
          </Typography>
        ),
        offer: <Offer withoutStatus title={offerTitle} offerId={offerId} />,
        key: `conversions-${i}`
      };
    });
  }, [clicks]);

  const createDropdownHandler = useCallback(
    (property: keyof typeof query): DropdownOnChange => (value) => {
      setQuery({ [property]: value || undefined });
    },
    [setQuery]
  );

  return (
    <>
      <SupportManagerialPageHead>
        <HeaderFiltration>
          <HeaderFiltrationRow>
            <OffersDropdown
              withSavingData
              value={offers}
              landing={landing}
              onChange={createDropdownHandler('offers')}
              onChangeLanding={createDropdownHandler('landing')}
            />
            <StatisticPageCountry
              withSavingData
              value={countries}
              onChange={createDropdownHandler('countries')}
            />
          </HeaderFiltrationRow>

          <HeaderFiltrationRow>
            <StatisticPageSubIds
              subId1={subId1}
              subId2={subId2}
              subId3={subId3}
              subId4={subId4}
              subId5={subId5}
              affParam2={affParam2}
              affParam5={affParam5}
              onChange={onChangeSubId}
              onChangeAffiliateParam={onChangeAffiliateParam}
            />
          </HeaderFiltrationRow>
        </HeaderFiltration>
      </SupportManagerialPageHead>

      <Table
        fullWidth
        rows={tableRows}
        isLoading={isLoading}
        options={tableOptions}
        isEmpty={!clicks.length}
      />
    </>
  );
};

export default TableClicks;
