import React from 'react';
import styled from 'styled-components';
import { ReadOnlyInfoItemType, ReadOnlyInfoValueProps } from './interface';
import { truncate } from '../../resources/functions';
import { TypographyProps } from '../../bit/components/typography/interface';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Expand = styled(Typography)`
  margin-top: 6px;
  cursor: pointer;
  user-select: none;
`;

const typographyProps: TypographyProps = {
  color: 'third',
  size: 16,
  variant: 'subtitle2'
};

const ReadOnlyInfoValue = (props: ReadOnlyInfoValueProps) => {
  const { type, value } = props;

  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const toggleExpand = React.useCallback(
    () => setExpanded((prevState) => !prevState),
    []
  );

  const content = React.useMemo(
    () => (expanded ? value : truncate(value, 200)),
    [expanded, value]
  );

  const renderExpand = React.useCallback(
    () =>
      value.length > 200 && (
        <Expand
          onClick={toggleExpand}
          color='themeMain'
          variant='subtitle2'
          size={16}
        >
          {t(expanded ? 'common.rollUp' : 'common.expand')}
        </Expand>
      ),
    [expanded, t, toggleExpand, value.length]
  );

  if (type === ReadOnlyInfoItemType.html) {
    return (
      <Container>
        <Typography
          {...typographyProps}
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
        {renderExpand()}
      </Container>
    );
  }

  return (
    <Container>
      <Typography {...typographyProps}>{content}</Typography>
      {renderExpand()}
    </Container>
  );
};

export default ReadOnlyInfoValue;
