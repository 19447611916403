import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Block from '../../components/Block';

import Page from '../../components/Page';
import Typography from '../../bit/components/typography';
import PepperWithFlameIcon from '../../components/PepperWithFlameIcon';

const Frame = styled(Block)`
  width: 80%;
  max-width: 732px;
  padding: 64px 32px;

  line-height: 30px;
  box-shadow: 0px 6px 80px rgba(0, 0, 0, 0.07);
  color: ${(p) => p.theme.palette.text.main};

  position: relative;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
`;

const StyledPepperIcon = styled(PepperWithFlameIcon)`
  top: 0;
  left: 50%;
  width: 120px;
  height: 160px;
  margin-left: -60px;
  position: absolute;

  transform: translateY(calc(-50% - 24px));
`;

const TypographyWrapper = styled.div`
  max-width: 604px;
`;

const UnverifiedUserPage = () => {
  const { t } = useTranslation();

  return (
    <Page isVerticalCenter isHorizontalCenter>
      <Frame>
        <StyledPepperIcon />
        <TypographyWrapper>
          <Typography variant='h3' color='inherit'>
            {t('unverifiedUserPage.message')}
          </Typography>
        </TypographyWrapper>
      </Frame>
    </Page>
  );
};

export default UnverifiedUserPage;
