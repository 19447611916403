import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { logOutAsync } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import useRoutes, { RoutesPaths } from '../../hooks/useRoutes';

import Icon from '../../bit/components/icon';
import Sidebar from '../../bit/components/sidebar';
import SidebarItem from '../../bit/components/sidebar-item';
import { color, transition } from '../../bit/components/utils/utils';
import LanguageSelect from '../LanguageSelect';

import Logo from '../Logo';
import UserInfo from '../UserInfo';
import Switcher from '../Switcher';
import SidebarTitle from '../SidebarTitle';
import {
  ThemeEnum,
  setThemeAsync,
  selectSettings,
  setSidebarState
} from '../../redux/slices/settingsSlice';
import Typography from '../Typography';

import webIcon from '../../images/globe-white.svg';
import telegramIcon from '../../images/telegram.svg';
import instagramIcon from '../../images/instagram.svg';
import media from '../../resources/media';
import SidebarButton from '../SidebarButton';

const StyledSidebar = styled(Sidebar)<{ isOpenOnMobile?: boolean }>`
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.components.sidebar.shadow};

  @media ${media.tablet} {
    display: ${(props) => (props.isOpenOnMobile ? 'flex' : 'none')};
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledLogo = styled(Logo)`
  width: 250px;
`;

const StyledSidebarButton = styled(SidebarButton)`
  display: none;

  @media ${media.tablet} {
    display: inline-flex;
  }
`;

const StyledLanguageSelect = styled(LanguageSelect)`
  margin-bottom: 20px;
`;

const StyledUserInfo = styled(UserInfo)`
  margin-bottom: 20px;
`;

const StyledIconWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;
`;

const ThemeSwitcher = styled(Switcher)`
  margin-bottom: 36px;
`;

const StyledFooter = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  color: ${(props) => props.theme.components.sidebar.color};
`;

const Support = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 36px;
  color: ${(props) => props.theme.components.sidebar.color};
`;

const SupportLink = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  user-select: none;
  color: inherit;
`;

const SupportIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSidebarTitle = styled(SidebarTitle)`
  text-transform: uppercase;
  line-height: 22px;
`;

const StyledSidebarItem = styled(SidebarItem)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.components.sidebar.color};
  ${transition({ name: 'background-color' })};

  svg {
    ${transition({ name: 'all' })};
  }

  height: 52px;
  border-radius: 0;

  & + & {
    margin-top: 16px;
  }

  &.active {
    color: ${color('primary.main')};
  }
`;

const AppSidebar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { routes } = useRoutes();

  const { isOpenOnMobile, theme } = useSelector(selectSettings);

  useEffect(() => {
    if (isOpenOnMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpenOnMobile]);

  const handleLogOut = React.useCallback(() => dispatch(logOutAsync()), [
    dispatch
  ]);

  const onChangeTheme = React.useCallback(() => {
    const value = theme === ThemeEnum.dark ? ThemeEnum.light : ThemeEnum.dark;
    dispatch(setThemeAsync(value));
  }, [theme, dispatch]);

  const handleClick = React.useCallback(() => {
    dispatch(setSidebarState(false));
  }, [dispatch]);

  return (
    <StyledSidebar isOpenOnMobile={isOpenOnMobile}>
      <div>
        <SidebarHeader>
          <StyledLogo />
          <StyledSidebarButton isCloseButton />
        </SidebarHeader>
        <StyledLanguageSelect />
        <StyledUserInfo onLogOut={handleLogOut} />
        <SidebarList>
          {Object.keys(routes).map((routeKey) => {
            const route = routes[routeKey as RoutesPaths];
            const path = Array.isArray(route.path) ? route.path[0] : route.path;

            if (!route.title || !route.icon) {
              return null;
            }

            return (
              <StyledSidebarItem
                onClick={handleClick}
                key={routeKey}
                path={path}
                exact
              >
                <StyledIconWrapper>
                  <Icon
                    type='stroke'
                    size={18}
                    src={route.icon}
                    color='inherit'
                  />
                </StyledIconWrapper>
                <StyledSidebarTitle
                  size={16}
                  variant='subtitle2'
                  color='inherit'
                >
                  {route.title}
                </StyledSidebarTitle>
              </StyledSidebarItem>
            );
          })}
        </SidebarList>
      </div>

      <StyledFooter>
        <ThemeSwitcher
          isRow
          active={theme === ThemeEnum.dark}
          onChange={onChangeTheme}
          label={t('sidebar.darkTheme')}
        />
        <Support>
          <Typography variant='subtitle2' color='inherit'>
            {t('common.support')}
          </Typography>

          <SupportLink href='https://telegram.me/peppersup' target='_blank'>
            <SupportIcon src={telegramIcon} />
            <Typography variant='subtitle2' color='inherit'>
              @peppersup
            </Typography>
          </SupportLink>
        </Support>
      </StyledFooter>

      <StyledFooter>
        <Support>
          <Typography variant='subtitle2' color='inherit'>
            {t('common.solutions')}
          </Typography>

          <SupportLink href='https://crmpp.pepper.partners' target='_blank'>
            <SupportIcon src={webIcon} />
            <Typography variant='subtitle2' color='inherit'>
              Pepper CRM
            </Typography>
          </SupportLink>

          <SupportLink href='https://t.me/+XL-z1i80R4g0NDUy' target='_blank'>
            <SupportIcon src={telegramIcon} />
            <Typography variant='subtitle2' color='inherit'>
              Pepper Shop
            </Typography>
          </SupportLink>
        </Support>
      </StyledFooter>

      <Socials>
        <Typography variant='subtitle2' color='inherit'>
          {t('common.society')}
        </Typography>

        <SupportLink
          href='https://t.me/joinchat/UF486GaNNHmEwMbB'
          target='_blank'
        >
          <SupportIcon src={telegramIcon} />
          <Typography variant='subtitle2' color='inherit'>
            {t('sidebar.blog')}
          </Typography>
        </SupportLink>

        <SupportLink
          href='https://www.instagram.com/pepper.prtnrs/'
          target='_blank'
        >
          <SupportIcon src={instagramIcon} />
          <Typography variant='subtitle2' color='inherit'>
            instpepper
          </Typography>
        </SupportLink>
      </Socials>
    </StyledSidebar>
  );
};

export default AppSidebar;
