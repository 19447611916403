import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BooleanParam, useQueryParams, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Page from '../../components/Page';
import Header from '../../components/Header';
import AppModal from '../../modals/AppModal';
import Typography from '../../components/Typography';
import NamingGeneratorModal from '../../modals/NamingGeneratorModal';

import useAwaitCallback from '../../hooks/useAwaitCallback';
import {
  fetchAppsAsync,
  fetchAppsCabinetsAsync
} from '../../redux/slices/appsSlice';

import { App } from '../../types/apps';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import AppsCabinetsTable from './AppsCabinetsTable';
import AppsTable from './AppsTable';
import Switcher from '../../components/Switcher';

const AdditionalTitle = styled(Typography).attrs({ color: 'main' })`
  max-width: 400px;
  margin-left: auto;
`;

const StyledSwitcher = styled(Switcher)`
  margin-bottom: 24px;
`;

const AppsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [app, setApp] = useState<App | undefined>();
  const [isOpenAppModal, setOpenAppModalState] = useState(false);
  const [isOpenGeneratorModal, setOpenGeneratorModalState] = useState(false);

  const [{ withBannedCabinets }, setQuery] = useQueryParams({
    withBannedCabinets: withDefault(BooleanParam, false)
  });

  const [fetchData, isLoading] = useAwaitCallback(async () => {
    await dispatch(fetchAppsAsync());
  }, [dispatch]);

  const [
    fetchAppsCabinets,
    isGetAppsCabinetsLoading
  ] = useAwaitCallback(async () => {
    await dispatch(
      fetchAppsCabinetsAsync({
        withBanned: withBannedCabinets
      })
    );
  }, [dispatch, withBannedCabinets]);

  useEffect(() => {
    fetchAppsCabinets();
  }, [fetchAppsCabinets]);

  useEffect(() => {
    fetchData();
  }, [dispatch, fetchData]);

  const closeAppModal = useCallback(() => {
    setOpenAppModalState(false);
    setApp(undefined);
  }, []);

  const closeNamingGeneratorModal = useCallback(() => {
    setOpenGeneratorModalState(false);
    setApp(undefined);
  }, []);

  const additionalTitle = useMemo(
    () => <AdditionalTitle>{t('apps.popper')}</AdditionalTitle>,
    [t]
  );

  return (
    <Page>
      <Header title={t('sidebar.apps')} additionalTitle={additionalTitle} />

      <Block>
        <BlockHeader title={t('apps.availableApplications')} />
        <AppsTable
          setApp={setApp}
          isLoading={isLoading}
          setOpenAppModalState={setOpenAppModalState}
          setIsNamingGeneratorModalOpen={setOpenGeneratorModalState}
        />
      </Block>

      <Block>
        <BlockHeader title={t('apps.myCabinets')} />

        <StyledSwitcher
          isRow
          label={t('apps.showBannedCabinets')}
          active={withBannedCabinets}
          isReadonly={isGetAppsCabinetsLoading}
          onChange={() => {
            setQuery({ withBannedCabinets: !withBannedCabinets });
          }}
        />

        <AppsCabinetsTable isLoading={isGetAppsCabinetsLoading} />
      </Block>

      <AppModal
        app={app}
        open={isOpenAppModal}
        onClose={closeAppModal}
        onUpdate={fetchAppsCabinets}
      />
      <NamingGeneratorModal
        app={app}
        open={isOpenGeneratorModal}
        onClose={closeNamingGeneratorModal}
      />
    </Page>
  );
};

export default AppsPage;
