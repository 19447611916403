import React from 'react';
import Typography from '../../bit/components/typography';
import { Props } from './interface';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';

const Title: typeof Typography = styled(Typography)`
  text-transform: uppercase;
  margin-right: 32px;
  color: ${color('text.main')};
`;

const HeaderTitle = (props: Props) => {
  const { children, ...otherProps } = props;

  return (
    <Title {...otherProps} variant='h1'>
      {children}
    </Title>
  );
};

export default HeaderTitle;
