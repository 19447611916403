import React from 'react';
import { GridProps } from './interface';
import styled from 'styled-components';

const StyledGrid = styled.div<GridProps>`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  flex-direction: ${(props) => props.direction};
`;

const Grid = (props: GridProps) => {
  const { children, ...otherProps } = props;

  return <StyledGrid {...otherProps}>{children}</StyledGrid>;
};

export default Grid;
