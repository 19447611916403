import React from 'react';
import { Props } from './interface';
import styled from 'styled-components';
import ReadOnlyInfoValue from './ReadOnlyInfoValue';
import Typography from '../Typography';

const StyledReadOnlyInfo = styled.div`
  display: inline-flex;
  align-items: center;
  margin: -6px -12px;

  table {
    border-collapse: collapse;

    td {
      padding: 6px 12px;
    }
  }
`;

const ReadOnlyInfoLabel = styled(Typography)`
  white-space: nowrap;
`;

const ReadOnlyInfo = (props: Props) => {
  const { info } = props;

  return (
    <StyledReadOnlyInfo>
      <table>
        <tbody>
          {info.map((item) => (
            <tr key={item.id}>
              <td>
                <ReadOnlyInfoLabel color='second' size={16} variant='subtitle2'>
                  {item.label}:
                </ReadOnlyInfoLabel>
              </td>
              <td>
                <ReadOnlyInfoValue value={item.value} type={item.type} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledReadOnlyInfo>
  );
};

export default ReadOnlyInfo;
