import React, { useCallback } from 'react';
import { Props } from './interface';
import styled from 'styled-components';
import Icon from '../../bit/components/icon';

import burgerIcon from '../../images/burger.svg';
import closeIcon from '../../images/close.svg';
import { useDispatch } from 'react-redux';
import { setSidebarState } from '../../redux/slices/settingsSlice';
import media from '../../resources/media';

const StyledIcon = styled(Icon)`
  display: none;
  color: ${(props) => props.theme.components.sidebarButton.color};
  cursor: pointer;

  @media ${media.tablet} {
    display: inline-flex;
  }
`;

const SidebarButton = (props: Props) => {
  const { isCloseButton, ...otherProps } = props;

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setSidebarState(!isCloseButton));
  }, [dispatch, isCloseButton]);

  return (
    <StyledIcon
      {...otherProps}
      src={isCloseButton ? closeIcon : burgerIcon}
      onClick={handleClick}
      color='inherit'
    />
  );
};

export default SidebarButton;
