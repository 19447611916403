import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Props } from './interface';
import useRoutes from '../../hooks/useRoutes';

import { ReactSVG } from 'react-svg';

import logo from '../../images/logo.svg';

const StyledLogo = styled(Link)<{ isLogIn?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;

  > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${(props) => {
      if (props.isLogIn) {
        return css`
          width: 100%;
          margin: 0 40px;
        `;
      }

      return css`
        &:first-child {
          width: 55px;
          height: 74px;
        }

        &:last-child {
          width: 188px;
          margin-left: 10px;
        }
      `;
    }};

    * {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`;

const Logo = (props: Props) => {
  const { isLogIn, ...otherProps } = props;

  const { getRoutePath } = useRoutes();

  return (
    <StyledLogo {...otherProps} to={getRoutePath('main')} isLogIn={isLogIn}>
      <ReactSVG src={logo} alt='logo' />
    </StyledLogo>
  );
};

export default Logo;
