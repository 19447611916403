const getTableStatisticKey = (type: string) => {
  let result = type;

  if (type === 'offer') result = 'offerName';
  if (type === 'goal') result = 'goalName';
  if (type === 'country') result = 'countryName';
  if (type === 'aff_param5') result = 'employeeId';
  if (type === 'aff_param2') result = 'domain';
  if (type === 'device_type') result = 'deviceName';
  if (type === 'connection_type') result = 'connectionTypeName';
  if (type.includes('sub_id')) result = `subId${type[type.length - 1]}`;

  return result;
};

export default getTableStatisticKey;
