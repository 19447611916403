import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import useRoutes from '../../hooks/useRoutes';
import BalanceInfo from '../BillingPage/BalanceInfo';
import ViewAllLink from '../../components/ViewAllLink';

import { selectUser } from '../../redux/slices/userSlice';
import { selectBillings } from '../../redux/slices/billingsSlice';
import { fetchBillingsBalance } from '../../redux/slices/billingsSlice';

import { StyledStatBlock, StatsBlockHeader, StatsBlockTitle } from './styled';

const BalanceBlock = () => {
  const { t } = useTranslation();
  const { getRoutePath } = useRoutes();

  const dispatch = useDispatch();
  const { balance } = useSelector(selectBillings);

  const user = useSelector(selectUser);
  const isCreator = user.info?.creator;

  useEffect(() => {
    if (!isCreator) return;
    dispatch(fetchBillingsBalance());
  }, [dispatch, isCreator]);

  if (!isCreator) return null;

  return (
    <StyledStatBlock>
      <StatsBlockHeader>
        <StatsBlockTitle title={t('common.balance')} />
        <ViewAllLink link={getRoutePath('billing')} />
      </StatsBlockHeader>

      <BalanceInfo balance={balance} />
    </StyledStatBlock>
  );
};

export default BalanceBlock;
