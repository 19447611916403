import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Picker, { registerLocale } from 'react-datepicker';

import { DatePickerProps } from './DatePickerTypes';
// import Typography from '../../../bit/components/typography';

import ru from 'date-fns/locale/ru';
import ua from 'date-fns/locale/uk';
import en from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';

import Input from '../Input';
import calendar from '../../images/calendar.svg';
import Icon from '../../bit/components/icon';
import moment from 'moment';

registerLocale('ru', ru);
registerLocale('en', en);
registerLocale('ua', ua);

const PickerWrapper = styled('div')`
  width: 240px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  svg path {
    fill: transparent;
  }

  .react-datepicker__input-container > div {
    padding: 0;
    border: none;
  }

  & .react-datepicker {
    border-color: ${({ theme }) => theme.palette.border.main};
    background: ${({ theme }) => theme.palette.background.main};

    & .react-datepicker__day,
    & .react-datepicker__day-name,
    & .react-datepicker__current-month {
      color: ${({ theme }) => theme.palette.text.main};
    }

    & .react-datepicker__header {
      border-color: ${({ theme }) => theme.palette.border.main};
      background: ${({ theme }) => theme.palette.background.main};
    }

    & .react-datepicker__day--in-range,
    & .react-datepicker__day--in-selecting-range,
    & .react-datepicker__day--selected {
      color: ${({ theme }) => theme.palette.common.white};
    }

    & .react-datepicker__triangle {
      border-bottom-color: ${({ theme }) => theme.palette.background.main};
      &::before {
        border-bottom-color: ${({ theme }) => theme.palette.border.main};
      }
    }

    &
      .react-datepicker__day:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected) {
      &:hover {
        background: ${({ theme }) => theme.palette.border.main};
      }
    }
  }
`;

const StyledPicker = styled(Picker)`
  width: 100%;

  display: inline-flex;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #eaeef1;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  resize: vertical;
  transition: background-color 250ms ease-in-out;
`;

// const DatePickerWrapper = styled.div`
//   display: flex;
//   background: #f0f0f0;
//   border: 1px solid #aeaeae;
// `;

// const DatePickerContainer = styled.div`
//   border: none !important;
//   position: relative;
// `;

// const DatePickerSuggestions = styled.div`
//   padding: 10px 8px;
// `;

// const DatePickerSuggestion = styled.div`
//   & + & {
//     margin-top: 12px;
//   }
// `;

const removeDateTimeZone = (data: Date) => {
  const [date] = new Date(data).toISOString().split('T');
  return new Date([date, '00:00:00.000Z'].join('T'));
};

const prepareDate = (date: Date, isOut = false) => {
  return moment(removeDateTimeZone(date))
    .add(isOut ? -1 : 1, 'days')
    .toDate();
};

// TODO: локализация
// TODO: enum

const DatePicker = (props: DatePickerProps) => {
  const {
    onChange,
    showMonthDropdown,
    showYearDropdown,
    selectsRange = true,
    isValueWithoutTimeZone = false
  } = props;

  const { i18n } = useTranslation();

  const [isOpen, setOpenState] = useState(false);

  const endDate = useMemo(() => {
    if (!props.endDate) return null;
    if (!isValueWithoutTimeZone) return props.endDate;

    return prepareDate(props.endDate);
  }, [props.endDate, isValueWithoutTimeZone]);

  const startDate = useMemo(() => {
    if (!props.startDate) return null;
    if (!isValueWithoutTimeZone) return props.startDate;

    return prepareDate(props.startDate);
  }, [props.startDate, isValueWithoutTimeZone]);

  // const onCustomClick = React.useCallback(
  //   (period: 'month' | 'two weeks' | 'week') => () => {
  //     switch (period) {
  //       case 'month':
  //         onChange([new Date(), new Date()]);
  //         break;
  //       case 'two weeks':
  //         onChange([new Date(), new Date()]);
  //         break;
  //       case 'week':
  //         onChange([new Date(), new Date()]);
  //         break;

  //       default:
  //         break;
  //     }
  //   },
  //   [onChange]
  // );

  // const Custom = ({ className, children }: any) => {
  //   return (
  //     <DatePickerWrapper>
  //       <DatePickerSuggestions>
  //         <DatePickerSuggestion>
  //           <Typography
  //             variant='subtitle2'
  //             size={14}
  //             onClick={onCustomClick('month')}
  //           >
  //             Последний месяц
  //           </Typography>
  //         </DatePickerSuggestion>
  //         <DatePickerSuggestion>
  //           <Typography
  //             variant='subtitle2'
  //             size={14}
  //             onClick={onCustomClick('two weeks')}
  //           >
  //             Последние 14 дней
  //           </Typography>
  //         </DatePickerSuggestion>
  //         <DatePickerSuggestion>
  //           <Typography
  //             variant='subtitle2'
  //             size={14}
  //             onClick={onCustomClick('week')}
  //           >
  //             Последние 7 дней
  //           </Typography>
  //         </DatePickerSuggestion>
  //       </DatePickerSuggestions>
  //       <DatePickerContainer className={className}>
  //         {children}
  //       </DatePickerContainer>
  //     </DatePickerWrapper>
  //   );
  // };

  const toggleOpenState = useCallback(
    (state?: boolean) => {
      setOpenState(state || !isOpen);
    },
    [isOpen]
  );

  const CustomInput = useMemo(
    () => (
      <Input
        readOnly
        endIcon={<Icon src={calendar} onClick={() => toggleOpenState()} />}
      />
    ),
    [toggleOpenState]
  );

  const value = useMemo(() => {
    const start = startDate ? new Date(startDate).toLocaleDateString() : '';
    const end = endDate ? ` - ${new Date(endDate).toLocaleDateString()}` : '';

    return start + end;
  }, [startDate, endDate]);

  const onChangeDate = useCallback(
    (dates: [Date, Date]) => {
      const prevDates = dates;
      if (isValueWithoutTimeZone) {
        // NOTE
        // Дейтпикер некорректные типы имеет
        // По факту Date | [Date, Date]

        if (Array.isArray(dates)) {
          if (dates[0]) {
            dates[0] = prepareDate(dates[0], true);
          }

          if (dates[1]) {
            dates[1] = prepareDate(dates[1], true);
          }
        } else {
          if (dates) {
            // @ts-ignore
            dates = prepareDate(dates, true);

            // @ts-ignore
            const difference = prevDates.getTime() - dates.getTime();
            const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

            if (totalDays === 2) {
              // @ts-ignore
              dates = prepareDate(dates);
            }
          }
        }

        onChange(dates);
        return;
      }

      onChange(dates);
    },
    [onChange, isValueWithoutTimeZone]
  );

  return (
    <PickerWrapper>
      <StyledPicker
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        customInput={CustomInput}
        open={isOpen}
        onFocus={() => toggleOpenState(true)}
        onClickOutside={() => toggleOpenState(false)}
        shouldCloseOnSelect={false}
        selected={startDate}
        onChange={onChangeDate}
        endDate={endDate}
        startDate={startDate}
        selectsRange={selectsRange}
        locale={i18n.language}
        placeholderText={selectsRange ? 'Выберите даты' : 'Выберите дату'}
        // calendarContainer={Custom}
        dateFormat='dd/MM/yyyy'
        value={value}
      />
    </PickerWrapper>
  );
};

export default DatePicker;
