import {
  ApiCredentialsPut,
  ApiUpdateFromAdministration
} from '../types/api/credentials';
import Api from './index';

class CredentialsApi {
  static update(data: ApiCredentialsPut) {
    return Api.call('/credentials', {
      data,
      method: 'PUT'
    });
  }

  static updateFromAdministrator(data: ApiUpdateFromAdministration) {
    return Api.call('/credentials/updateFromAdministrator', {
      data,
      method: 'PUT'
    });
  }
}

export default CredentialsApi;
