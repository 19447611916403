import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

export enum AuthState {
  'auth' = 'auth',
  'code' = 'code',
  'forgot' = 'forgot',
  'register' = 'register',
  'confirmReset' = 'confirmReset'
}

export enum Currency {
  USD = '$',
  EUR = '€'
}

export type CurrencyKeys = keyof typeof Currency;

export enum TrackingLinkParams {
  aff_param5 = 'aff_param5',
  offerId = 'o',
  landing = 'link_id',
  creative = 'creative_id',
  subId = 'sub_id',
  clickId = 'click_id',
  appleId = 'idfa',
  googleId = 'gaid',
  scaleoId = 'a'
}

export enum LandingsTypes {
  preview = 2
}

export enum CompanyStatuses {
  active = 1,
  pending = 2,
  disabled = 3
}

export enum PaymentStatus {
  paid = 1,
  isProgress,
  unpaid
}

export enum Languages {
  en = 'en',
  ua = 'ua',
  ru = 'ru'
}

export interface UserMessengers {
  telegram: string;
  whatsapp: string;
  skype: string;
  wechat: string;
}

export interface UserMessengersResponse {
  telegram?: string | null;
  whatsapp?: string | null;
  skype?: string | null;
  wechat?: string | null;
}

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
