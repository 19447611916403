import { css } from 'styled-components';
import { Theme } from '../theme/theme';

export const color = (colorPath: string) => ({
  theme
}: {
  theme: Theme;
}): string => {
  const [colorGroup, colorValue] = colorPath.split('.');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return theme.palette[colorGroup][colorValue];
};

export const transition = (
  ...props: {
    name: string;
    duration?: keyof Theme['transitions']['duration'];
    easing?: keyof Theme['transitions']['easing'];
  }[]
) => ({ theme }: { theme: Theme }): string => {
  const str = props.map(
    ({ name, duration = 'short', easing = 'easeInOut' }) =>
      `${name} ${theme.transitions.duration[duration]}ms ${theme.transitions.easing[easing]}`
  );
  return `transition: ${str}`;
};

export const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const isFullWidth = <T extends { fullWidth?: boolean }>(props: T) =>
  props.fullWidth &&
  css`
    width: 100%;
  `;

export const classNames = (
  ...args: (string | boolean | undefined | { [key: string]: boolean } | any)[]
): string => {
  const className: string[] = [];

  args.forEach((arg) => {
    if (typeof arg === 'object') {
      Object.keys(arg).forEach((key) => {
        if (arg[key]) {
          className.push(key);
        }
      });
    } else if (typeof arg === 'string') {
      if (arg) {
        className.push(arg);
      }
    }
  });

  return className.join(' ');
};

export const updateArray = <T>(array: T[], value: T, findByProp?: keyof T) => {
  const result = array.slice();

  let matchIndex: number;

  if (findByProp) {
    matchIndex = result.findIndex(
      (a) => String(a[findByProp]) === String(value[findByProp])
    );
  } else {
    matchIndex = result.indexOf(value);
  }

  if (matchIndex !== -1) {
    result.splice(matchIndex, 1);
  } else {
    result.push(value);
  }

  return result;
};
