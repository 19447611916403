import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SectionsSettings from '../../components/SectionsSettings';
import useSections from '../../hooks/useSections';
import { selectUserId } from '../../redux/slices/userSlice';
import { Employee, SectionsCredentials } from '../../types/employees';

import { Props, MessengerInputValues } from './interface';

import Modal from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import LogIn, { ErrorsState, LogInConfig } from '../../bit/components/log-in';
import EmployeesApi from '../../api/employees';
import { ApiUsersPost } from '../../types/api/employees';
import useWithTelegram from '../../hooks/useWithTelegram';
import { mapUserMessengers } from '../../maps/userMessengersMap';
import Notification from '../../components/Notification';
import { patchUserCredentials } from '../../utils/setCredentials';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`;

const StyledForm = styled(LogIn)`
  padding: 0;
  background: none;
  max-width: none;
  min-width: auto;
`;

const getInitMessengerInputs = (employee?: Employee) =>
  mapUserMessengers(employee?.messengers || {});

const EmployeeModal = (props: Props) => {
  const { open, onClose, employee, onUpdate } = props;

  const userId = useSelector(selectUserId);
  const { checkSection, sections } = useSections();
  const { t } = useTranslation();

  const { withTelegramByUserLang } = useWithTelegram();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorsState, setErrorsState] = useState<ErrorsState>({});
  const [
    sectionsCredentials,
    setSectionsCredentials
  ] = useState<SectionsCredentials>();

  const [
    messengerInputValues,
    setMessengerInputValues
  ] = useState<MessengerInputValues>(() => getInitMessengerInputs(employee));

  useEffect(() => {
    if (employee?.sectionsCredentials) {
      setSectionsCredentials(employee.sectionsCredentials);
    } else {
      setSectionsCredentials(undefined);
    }
  }, [employee?.sectionsCredentials]);

  const isCreating = !employee;

  const onSubmitForm = useCallback(
    async ({ email, telegram, whatsapp, skype, wechat, password }) => {
      const body: ApiUsersPost = {
        type: 'admin',
        email,
        messengers: {
          telegram,
          whatsapp,
          skype,
          wechat
        },
        password
      };

      if (isCreating) {
        try {
          await EmployeesApi.createEmployee(body);
          onUpdate();
          onClose();
        } catch (error) {
          let message = '';
          const status = error.response?.status;

          if (status === 409) {
            message = t('employees.emailExist');
          } else {
            message = t('employees.creationError');
          }

          Notification.show({ message, type: 'error' });
        }
      } else if (employee) {
        try {
          delete body.password;
          await EmployeesApi.updateEmployee(employee.id, body);

          if (sectionsCredentials) {
            await patchUserCredentials(
              { id: employee.id, credentials: employee.sectionsCredentials },
              sectionsCredentials
            );
          }

          onUpdate();
          onClose();
        } catch (error) {
          let message = '';

          if (error.response?.status === 403) {
            message = t('common.accessDenied');
          } else {
            message = t('employees.creationError');
          }

          Notification.show({ message, type: 'error' });
        }
      }
    },
    [isCreating, employee, t, onUpdate, onClose, sectionsCredentials]
  );

  const title = useMemo(() => {
    return isCreating ? t('employees.newEmployee') : t('common.change');
  }, [isCreating, t]);

  const handleChangeMessengerInput = useCallback(
    (key: keyof MessengerInputValues) => (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setMessengerInputValues((prevState) => ({
        ...prevState,
        [key]: e.target.value
      }));
    },
    []
  );

  const messengerInputs = useMemo(() => {
    const commonInputProps = {
      placeholder: '@username',
      fullWidth: true
    };

    if (withTelegramByUserLang) {
      return [
        {
          _key: 'empoyee-telegram',
          required: true,
          name: 'telegram',
          label: t('fields.telegram.label'),
          value: employee?.messengers.telegram,
          ...commonInputProps
        }
      ];
    }

    const { whatsapp, skype, wechat } = messengerInputValues;
    return [
      {
        _key: 'empoyee-whatsapp',
        required: !skype && !wechat,
        name: 'whatsapp',
        label: t('fields.whatsapp.label'),
        value: whatsapp,
        onChange: handleChangeMessengerInput('whatsapp'),
        ...commonInputProps
      },
      {
        _key: 'empoyee-skype',
        required: !whatsapp && !wechat,
        name: 'skype',
        label: t('fields.skype.label'),
        value: skype,
        onChange: handleChangeMessengerInput('skype'),
        ...commonInputProps
      },
      {
        _key: 'empoyee-wechat',
        required: !whatsapp && !skype,
        name: 'wechat',
        label: t('fields.wechat.label'),
        value: wechat,
        onChange: handleChangeMessengerInput('wechat'),
        ...commonInputProps
      }
    ];
  }, [
    withTelegramByUserLang,
    messengerInputValues,
    t,
    employee?.messengers.telegram,
    handleChangeMessengerInput
  ]);

  const inputs = useMemo((): LogInConfig['inputs'] => {
    const result: LogInConfig['inputs'] = [
      {
        _key: 'empoyee-email',
        required: true,
        name: 'email',
        autoComplete: 'new-password',
        label: t('fields.email.label'),
        value: employee?.email,
        placeholder: t('fields.email.placeholder'),
        fullWidth: true
      },
      ...messengerInputs
    ];

    if (isCreating) {
      result.push({
        _key: 'empoyee-password',
        name: 'password',
        type: 'password',
        autoComplete: 'new-password',
        required: true,
        label: t('fields.password.label'),
        placeholder: t('fields.password.placeholder'),
        fullWidth: true
      });
    }

    return result;
  }, [t, employee?.email, messengerInputs, isCreating]);

  const buttons = useMemo((): LogInConfig['buttons'] => {
    const children = isCreating ? t('common.create') : t('common.save');

    return [
      employee &&
        // нельзя редактировать создателя
        !employee.creator &&
        // нельзя редактировать самого себя
        userId !== employee.id &&
        // проверка доступа к методу на редактирование прав
        checkSection('employees', 'change') && (
          <SectionsSettings
            key='sections'
            setSectionsCredentials={setSectionsCredentials}
            sections={sections}
            sectionsCredentials={sectionsCredentials}
          />
        ),
      {
        children,
        size: 'middle'
      }
    ].filter(Boolean);
  }, [
    isCreating,
    t,
    employee,
    userId,
    checkSection,
    sections,
    sectionsCredentials
  ]);

  const config = useMemo<LogInConfig>(() => {
    return {
      title: '',
      inputs,
      buttons
    };
  }, [inputs, buttons]);

  return (
    <Modal open={open} onClose={onClose} maxWidth={700}>
      <ModalTitle>{title}</ModalTitle>
      <Container>
        <StyledForm
          config={config}
          onSubmit={onSubmitForm}
          forceErrorsState={errorsState}
        />
      </Container>
    </Modal>
  );
};

export default EmployeeModal;
