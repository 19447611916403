import React from 'react';
import Page from '../../components/Page';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import useHeaderPopper from '../../hooks/useHeaderPopper';
import {
  fetchBillingsBalance,
  fetchBillingsDetails,
  selectBillings
} from '../../redux/slices/billingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../../components/Grid';
import Block from '../../components/Block';
import BlockHeader from '../../components/BlockHeader';
import BalanceInfo from './BalanceInfo';
import styled from 'styled-components';
import DetailsTable from './DetailsTable';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import media from '../../resources/media';
import Tabs, { Tab } from '../../components/Tabs';
import { BillingTabs } from './interface';
import PaymentsBlock from '../../components/PaymentsBlock';
import ReferralBlock from '../../components/ReferralBlock';

const DetailsBlock = styled(Block)`
  flex: 1;
`;

const BalanceBlock = styled(Block)`
  flex: 0;
`;

const Row = styled.div`
  display: flex;
  width: 100%;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  margin: 12px;
`;

const StyledTabs = styled(Tabs)`
  align-self: flex-start;
`;

const BillingPage = () => {
  const { balance, details } = useSelector(selectBillings);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const headerPopper = useHeaderPopper(t('billing.popper'), 10 * 1000);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, BillingTabs.payments)
  });

  React.useEffect(() => {
    dispatch(fetchBillingsBalance());
    dispatch(fetchBillingsDetails());
  }, [dispatch]);

  return (
    <Page>
      <Header {...headerPopper} title={t('sidebar.billing')} />
      <Grid direction='column'>
        <Row>
          <BalanceBlock>
            <BlockHeader title={t('common.balance')} />
            <BalanceInfo balance={balance} />
          </BalanceBlock>
          <DetailsBlock disablePadding>
            <BlockHeader addPadding title={t('common.payout', { count: 5 })} />
            <DetailsTable details={details} />
          </DetailsBlock>
        </Row>
        <Row>
          <BottomBlock>
            <StyledTabs
              variant='open'
              activeId={query.tab}
              onChange={(tabId) => setQuery({ tab: tabId })}
            >
              <Tab id={BillingTabs.payments}>{t('billing.payments')}</Tab>
              <Tab id={BillingTabs.referral}>{t('billing.referral')}</Tab>
            </StyledTabs>
            {query.tab === BillingTabs.payments && <PaymentsBlock />}
            {query.tab === BillingTabs.referral && <ReferralBlock />}
          </BottomBlock>
        </Row>
      </Grid>
    </Page>
  );
};

export default BillingPage;
