import React from 'react';
import { Props } from './interface';
import styled from 'styled-components';
import VVTabs from '../../bit/components/tabs';
import { color } from '../../bit/components/utils/utils';

const StyledTabs = styled(VVTabs)`
  background-color: ${color('background.main')};
`;

const Tabs = (props: Props) => {
  const { children, ...otherProps } = props;

  return <StyledTabs {...otherProps}>{children}</StyledTabs>;
};

export default Tabs;
