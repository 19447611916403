import { debounce } from 'throttle-debounce';
import { DependencyList, useCallback, useMemo, useRef, useState } from 'react';

// TODO: Типизация ответа callback

function useAwaitCallback<T extends (...args: any[]) => Promise<void>>(
  callback: T,
  deps: DependencyList,
  initialState?: boolean,
  debounceTime?: number
): [T, boolean] {
  const isLocalLoading = useRef(false);
  const [isLoading, setLoading] = useState(Boolean(initialState));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb: T = useCallback(callback, deps);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _callback = useCallback(
    (async (...args) => {
      if (isLocalLoading.current) return;

      setLoading(true);
      isLocalLoading.current = true;

      let result: any = undefined;

      try {
        result = await cb(...args);
      } catch (error) {
        // eslint-disable-next-line no-throw-literal
        throw error as any;
      } finally {
        setLoading(false);
        isLocalLoading.current = false;
      }

      return result;
    }) as T,
    [cb]
  );

  const _debounceCallback = useMemo(
    () => debounce(debounceTime || 1000, _callback),
    [_callback, debounceTime]
  );

  return [debounceTime ? _debounceCallback : _callback, isLoading];
}

export default useAwaitCallback;
