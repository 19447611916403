import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { getLocalStorage, setLocalStorage } from '../../resources/functions';
import { STORE_THEME } from '../../resources/constants';

export enum ThemeEnum {
  'light',
  'dark'
}

interface SettingsState {
  theme: ThemeEnum;
  isOpenOnMobile: boolean;
}

const initialState: SettingsState = {
  theme: getLocalStorage(STORE_THEME) ?? ThemeEnum.dark,
  isOpenOnMobile: false
};

const settingsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTheme(state, action) {
      const { theme, withoutSetStorage } = action.payload;
      state.theme = theme;
      if (!withoutSetStorage) {
        setLocalStorage(STORE_THEME, theme);
      }
    },
    setSidebarState(state, action) {
      state.isOpenOnMobile = action.payload;
    }
  }
});

export const { setTheme, setSidebarState } = settingsSlice.actions;

export const setThemeAsync = (
  theme?: ThemeEnum,
  withoutSetStorage?: boolean
): AppThunk => async (dispatch) => {
  dispatch(
    setTheme({
      withoutSetStorage,
      theme: theme ?? getLocalStorage(STORE_THEME) ?? ThemeEnum.dark
    })
  );
};

export const selectSettings = (state: RootState) => state.settings;

export const selectTheme = (state: RootState) => state.settings.theme;

export default settingsSlice.reducer;
