import React from 'react';
import { SidebarTitleProps } from './interface';
import styled from 'styled-components';
import theme from '../../bit/components/theme/theme';
import { transition } from '../../bit/components/utils/utils';
import Typography from '../../bit/components/typography';

const StyledSidebarTitle: any = styled(Typography)`
  ${transition({ name: 'color' })};
`;

StyledSidebarTitle.defaultProps = {
  theme
};

const SidebarTitle = (props: SidebarTitleProps) => {
  const { children, ...otherProps } = props;

  return <StyledSidebarTitle {...otherProps}>{children}</StyledSidebarTitle>;
};

export default SidebarTitle;
