import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import {
  InputBaseBaseProps,
  InputBaseProps,
  InputValidateState
} from './interface';
import { color, transition } from '../utils/utils';
import { isFullWidth } from '../utils/utils';
import theme from '../theme/theme';

const StyledInputBase = styled.input<InputBaseBaseProps>`
  display: inline-flex;
  padding: 16px;
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${color('border.main')};
  outline: none;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 17px;
  resize: vertical;
  ${isFullWidth};
  ${transition({ name: 'background-color' })};
  background-color: ${(p) =>
    p.readOnly ? p.theme.palette.background.main : 'transparent'};
  color: ${(p) =>
    p.readOnly ? p.theme.palette.text.third : p.theme.palette.text.main};

  &:focus {
    background-color: ${color('background.main')};
  }

  &::placeholder {
    color: ${color('text.second')};
  }

  ${(props) => {
    const { validate } = props;
    if (validate === InputValidateState.error) {
      return css`
        border-color: ${color('error.main')} !important;
        color: ${color('error.main')} !important;

        &::placeholder {
          color: ${color('error.main')} !important;
        }
      `;
    } else if (validate === InputValidateState.warning) {
      return css`
        border-color: ${color('warning.main')} !important;
        color: ${color('warning.main')} !important;

        &::placeholder {
          color: ${color('warning.main')} !important;
        }
      `;
    }
  }}
`;

StyledInputBase.defaultProps = {
  theme
};

const InputBaseBase = <T extends React.ElementType = 'input'>(
  props: InputBaseProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const { multiline, fullWidth, onChange, value, ...otherProps } = props;

  const component = multiline ? 'textarea' : 'input';

  const [localValue, setLocalValue] = React.useState(value || '');

  React.useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  return (
    <StyledInputBase
      {...otherProps}
      onChange={handleChange}
      value={localValue}
      fullWidth={fullWidth}
      ref={ref}
      as={component as any}
    />
  );
};

const InputBase = React.forwardRef(InputBaseBase) as typeof InputBaseBase;

export default InputBase;
