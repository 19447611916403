import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import Typography from '../typography';
import { ButtonBaseProps, ButtonProps, IconContainerProps } from './interface';
import theme from '../theme/theme';
import { color, transition } from '../utils/utils';

const StyledButton = styled.button<ButtonBaseProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderRadius};

  ${transition(
    { name: 'opacity' },
    { name: 'background-color' },
    { name: 'color' },
    { name: 'border-color' }
  )};

  ${(p: ButtonBaseProps) => {
    switch (p.size) {
      default:
      case 'small':
        return css`
          padding: 10px 16px;
        `;

      case 'middle':
        return css`
          padding: 14px 20px;
        `;

      case 'big':
        return css`
          padding: 16px 20px;
        `;
    }
  }};

  ${(p: ButtonBaseProps) => {
    let textColor = 'common.white';
    let bgColor = 'primary.main';
    let activeBgColor = 'primary.dark';

    if (p.color === 'light') {
      textColor = 'primary.main';
      bgColor = 'primary.light';
      activeBgColor = 'primary.lightSecond';
    }

    if (p.variant === 'contained') {
      return css`
        color: ${color(textColor)};
        background: ${color(bgColor)};

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 1;
          background: ${color(activeBgColor)};
        }
      `;
    } else if (p.variant === 'outlined') {
      return css`
        color: ${color(bgColor)};
        border: 1px solid ${color(bgColor)};
        background: transparent;

        &:hover {
          color: ${color(textColor)};
          background: ${color('primary.dark')};
        }
      `;
    }
  }};
`;

StyledButton.defaultProps = {
  theme
};

const IconContainer = styled.div<IconContainerProps>`
  ${(props) => {
    const direction = props.isStart ? 'right' : 'left';
    let value = 0;

    switch (props.size) {
      default:
      case 'small':
      case 'middle':
        value = 5;
        break;
      case 'big':
        value = 10;
        break;
    }

    return `margin-${direction}: ${value}px`;
  }};
`;

const typographySizes = {
  small: 14,
  middle: 16,
  big: 18
};

const iconSizes = {
  small: 16,
  middle: 20,
  big: 24
};

const ButtonBase = <T extends React.ElementType = 'button'>(
  props: ButtonProps<T>,
  ref: React.Ref<HTMLButtonElement>
) => {
  const {
    component,
    children,
    size = 'small',
    startIcon,
    endIcon,
    variant = 'contained',
    ...otherProps
  } = props;

  const extendIcon = React.useCallback(
    (icon: ReactElement) =>
      React.cloneElement(icon, {
        ...icon.props,
        color: 'inherit',
        size: iconSizes[size]
      }),
    [size]
  );
  return (
    <StyledButton
      {...otherProps}
      ref={ref}
      as={component as any}
      size={size}
      variant={variant}
    >
      {startIcon && (
        <IconContainer isStart size={size}>
          {extendIcon(startIcon)}
        </IconContainer>
      )}
      <Typography
        color='inherit'
        variant='subtitle1'
        size={typographySizes[size] as any}
      >
        {children}
      </Typography>
      {endIcon && (
        <IconContainer size={size}>{extendIcon(endIcon)}</IconContainer>
      )}
    </StyledButton>
  );
};

const Button = React.forwardRef(ButtonBase) as typeof ButtonBase;

export default Button;
