import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';

import Api from '../../api';
import EmployeesApi from '../../api/employees';

import { Employee } from '../../types/employees';
import { mapEmployee } from '../../maps/employessMap';
import { ApiUsersPost } from '../../types/api/employees';

interface EmployeesState {
  items: Employee[];
  meta: { totalPages: number };
}

const initialState: EmployeesState = {
  items: [],
  meta: { totalPages: 1 }
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.items = action.payload;
    }
  }
});

export const selectEmployess = (state: RootState) => state.employees.items;
export const { setEmployees } = employeesSlice.actions;

export const fetchEmployeesAsync = (): AppThunk => async (dispatch) => {
  try {
    const response = await EmployeesApi.getEmployees({});
    dispatch(setEmployees(response.data.data.map(mapEmployee)));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const deleteEmployeesAsync = (id: number): AppThunk => async (
  dispatch
) => {
  try {
    await EmployeesApi.deleteEmployee(id);
    dispatch(fetchEmployeesAsync());
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const createEmployeesAsync = (data: ApiUsersPost): AppThunk => async (
  dispatch
) => {
  try {
    await EmployeesApi.createEmployee(data);
    dispatch(fetchEmployeesAsync());
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export default employeesSlice.reducer;
