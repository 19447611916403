import React from 'react';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';
import { Props } from './interface';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../../bit/components/icon';
import chevronRightIcon from '../../images/chevronRight.svg';
import { color } from '../../bit/components/utils/utils';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${color('primary.main')};
  text-decoration: none;

  span {
    margin-right: 4px;
  }
`;

const ViewAllLink = (props: Props) => {
  const { link } = props;

  const { t } = useTranslation();

  return (
    <StyledLink to={link}>
      <Typography variant='subtitle2' size={16} color='inherit'>
        {t('common.viewAll')}
      </Typography>
      <Icon size={20} color='inherit' src={chevronRightIcon} />
    </StyledLink>
  );
};

export default ViewAllLink;
