import React, { useCallback } from 'react';

const useBind = <T>(
  state: T,
  dispatch: React.Dispatch<React.SetStateAction<T>>
) => {
  const onChange = useCallback(
    (key: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      dispatch((prevState) => ({
        ...prevState,
        [key]: e.target.value
      }));
    },
    [dispatch]
  );

  return useCallback(
    (key: keyof T) => ({
      value: state[key] as any,
      onChange: onChange(key)
    }),
    [state, onChange]
  );
};

export default useBind;
