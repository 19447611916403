import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiAppsPut,
  ApiAppsPutResponse,
  ApiAppsGetResponse,
  ApiAppsAddLandingToAppPost,
  ApiAppsLandingsOffersResponse,
  ApiAppRequestsResponse,
  ApiAppsPutParams,
  ApiAppRequestsParams,
  ApiAppsGetQuery,
  ApiAppCabinetsGetParams
} from '../types/api/apps';
import { AppCabinets } from '../types/apps';

class AppsApi {
  static getApps(params?: ApiAppsGetQuery): AxiosPromise<ApiAppsGetResponse> {
    return Api.call('/apps', { params });
  }

  static getAppsCabinets(
    params: ApiAppCabinetsGetParams
  ): AxiosPromise<AppCabinets> {
    return Api.call('/apps/cabinets', { params });
  }

  static editApp(
    data: ApiAppsPut,
    params: ApiAppsPutParams
  ): AxiosPromise<ApiAppsPutResponse> {
    return Api.call('/apps', { data, params, method: 'PUT' });
  }

  static getLandingsOffer(): AxiosPromise<ApiAppsLandingsOffersResponse> {
    return Api.call('/landings_offers');
  }

  static addLandingToApp(data: ApiAppsAddLandingToAppPost) {
    return Api.call('/landings_offers', { method: 'POST', data });
  }

  static getAppRequests(
    appId: string,
    params: ApiAppRequestsParams
  ): AxiosPromise<ApiAppRequestsResponse> {
    return Api.call(`/support/apps/${appId}`, { params });
  }
  static downloadAppRequests(
    appId: string,
    params: ApiAppRequestsParams
  ): AxiosPromise {
    return Api.call(`/support/apps/${appId}/download`, {
      params,
      responseType: 'blob'
    });
  }
}

export default AppsApi;
