import React from 'react';
import InputBase from '../../bit/components/input-base';
import styled from 'styled-components';
import { Props } from './interface';
import { color, isFullWidth } from '../../bit/components/utils/utils';
import FormElementLabel from '../../bit/components/form-element-label';

const InputContainer = styled.div<{ fullWidth?: boolean; width?: number }>`
  display: flex;
  flex-direction: column;
  ${isFullWidth};
  width: ${(props) => props.width && `${props.width}px`};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  font-size: 16px;
  color: ${color('text.second')};
  cursor: pointer;
`;

const InputBaseContainer = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

const Input = (props: Props) => {
  const {
    endIcon,
    label,
    className,
    style,
    fullWidth,
    width,
    ...inputProps
  } = props;

  const extendedEndIcon = endIcon
    ? React.cloneElement(endIcon, {
        ...endIcon.props,
        size: 'inherit',
        color: 'inherit'
      })
    : null;

  return (
    <InputContainer
      width={width}
      className={className}
      style={style}
      fullWidth={fullWidth}
    >
      {label && <FormElementLabel>{label}</FormElementLabel>}
      <InputBaseContainer>
        <InputBase {...inputProps} fullWidth />
        {endIcon && <IconContainer>{extendedEndIcon}</IconContainer>}
      </InputBaseContainer>
    </InputContainer>
  );
};

export default Input;
