import styled from 'styled-components';

export const StyledInfinityScroll = styled.div`
  display: flex;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .infinite-scroll-component {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;
