import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from './interface';
import theme from '../theme/theme';
import { limitPageOptions } from '../../../resources/constants';
import { PaginationContainer, PageLimit } from './PaginationStyled';

PaginationContainer.defaultProps = { theme };

const Pagination = (props: PaginationProps) => {
  const { page, pages, onChange, limitProps } = props;

  const handleChange = React.useCallback(
    ({ selected }: { selected: number }) => onChange(selected + 1),
    [onChange]
  );

  if (pages <= 1) {
    return null;
  }

  return (
    <PaginationContainer $hasLimit={Boolean(limitProps?.onChange)}>
      <ReactPaginate
        onPageChange={handleChange}
        disableInitialCallback
        pageCount={pages}
        forcePage={page - 1}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        nextLabel='Далее'
        previousLabel='Назад'
      />

      {limitProps?.onChange && (
        <PageLimit
          value={limitProps.value}
          options={limitPageOptions}
          onChange={limitProps.onChange}
        />
      )}
    </PaginationContainer>
  );
};

export default Pagination;
