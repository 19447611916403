import 'reset-css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import { initI18n } from './lib/localization';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import ThemeProvider from './components/ThemeProvider';
import Metrics from './components/Metrics';
import { getLanguage } from './resources/functions';
import Notification from './components/Notification';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/ErrorPage/ErrorPage';

initI18n();

const isProd = process.env.NODE_ENV === 'production';

const GlobalStyles = createGlobalStyle`
  html {
    font-family: Montserrat, sans-serif;
  }

  html, body {
    height: 100%;
  }

  #root {
    display: flex;
    min-height: 100%; 
  }

  * {
    box-sizing: border-box;

    /* Customize website's scrollbar */
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    /* For Firefox */
    scrollbar-color: darkgrey;
    scrollbar-width: thin;

  }
`;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={getLanguage()}>
      <Provider store={store}>
        {isProd && <Metrics />}
        <GlobalStyles />
        <ThemeProvider>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App />
              <Notification />
            </QueryParamProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
