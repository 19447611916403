import { TFunction } from 'i18next';

const getTableStatisticGroupTitle = (t: TFunction, type: string) => {
  let result = '';

  if (type === 'day') result = t('common.day');
  if (type === 'offer') result = t('offers.offer');
  if (type === 'goal') result = t('offers.goal');
  if (type === 'hour') result = t('common.hour');
  if (type === 'country') result = t('common.country');
  if (type === 'aff_param2') result = t('reportTypes.domain');
  if (type === 'aff_param5') result = t('reportTypes.employeeId');
  if (type === 'device_type') result = t('statistics.deviceType');
  if (type === 'connection_type') result = t('statistics.connectionType');
  if (type.includes('sub_id')) result = `Sub id ${type[type.length - 1]}`;

  return result;
};

export default getTableStatisticGroupTitle;
