import styled from 'styled-components';
import BlockHeader from '../../components/BlockHeader';
import { color } from '../../bit/components/utils/utils';
import Icon from '../../bit/components/icon';
import Block from '../../components/Block';
import media from '../../resources/media';
import StatInfo from '../../components/StatInfo';

export const StatsRow = styled.div`
  display: flex;

  width: 100%;

  @media ${media.largeDesktop} {
    flex-direction: column;
  }
`;

export const StatsBlockTitle = styled(BlockHeader)`
  padding: 0;
`;

export const StatsBlockHeader = styled.div<{ addPadding?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: -8px -8px 16px;
  ${(props) => props.addPadding && 'padding: 24px'};

  > * {
    margin: 8px;
  }

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

export const StatsBlockFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  > * {
    margin: 8px;
  }

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

export const StatsBlockInfo = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${color('border.main')};
  border-radius: ${(props) => props.theme.borderRadius};

  > div {
    display: flex;

    > div {
      & + div {
        border-left: 1px solid ${color('border.main')};
      }
    }
  }
`;

export const StatsOffersBlock = styled(Block)`
  flex: 2;
`;

export const OfferTableIcon = styled(Icon)`
  color: ${color('text.first')};
  cursor: pointer;
`;

export const StyledStatInfo = styled(StatInfo)`
  min-width: 164px;

  justify-content: space-between;
`;

export const StyledStatBlock = styled(Block)`
  max-width: 508px;
`;
