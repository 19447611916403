import React, { useMemo } from 'react';
import { InfinityScrollProps } from './InfinityScrollTypes';

import InfiniteScroll from 'react-infinite-scroll-component';

import * as S from './InfinityScrollStyled';
import styled from 'styled-components';
import Loader from '../Loader/Loader';

const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
`;

const InfinityScroll = ({
  children,
  hasMore,
  className,
  itemsCount,
  fetchNextItems
}: InfinityScrollProps) => {
  const loader = useMemo(
    () => (
      <StyledLoaderContainer>
        <Loader isStatic />
      </StyledLoaderContainer>
    ),
    []
  );

  return (
    <S.StyledInfinityScroll className={className}>
      <InfiniteScroll
        hasMore={hasMore}
        next={fetchNextItems}
        dataLength={itemsCount}
        loader={loader}
      >
        {children}
      </InfiniteScroll>
    </S.StyledInfinityScroll>
  );
};

export default InfinityScroll;
