import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';

import Api from '../../api';
import OffersApi from '../../api/offers';

import { Country, GoalType, Offer, Tag } from '../../types/offers';
import {
  mapCountries,
  mapGoalTypes,
  mapOffers,
  mapTags
} from '../../maps/offersMap';
import { ApiOffersGet, ApiOffersCountriesGet } from '../../types/api/offers';
import { DEFAULT_LIMIT } from '../../resources/constants';

interface OffersState {
  items: Offer[];
  tags: Tag[];
  goalTypes: GoalType[];
  countries: Country[];
  meta: {
    limit: number;
    page: number;
    pages: number;
  };
}

const initialState: OffersState = {
  items: [],
  tags: [],
  countries: [],
  goalTypes: [],
  meta: {
    limit: DEFAULT_LIMIT,
    page: 1,
    pages: 1
  }
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffers: (state, action) => {
      if (action.payload.isAdding) {
        state.items = [
          ...state.items,
          ...mapOffers(action.payload.offers.data)
        ];
      } else {
        state.items = mapOffers(action.payload.offers.data);
      }

      if (action.payload.isOverWrite) {
        const { limit, pages } = action.payload.offers.meta;
        const page = limit / DEFAULT_LIMIT;

        state.meta = {
          ...state.meta,
          page,
          pages: (pages * limit) / DEFAULT_LIMIT
        };
      } else {
        state.meta = action.payload.offers.meta;
      }
    },
    setOffersTags: (state, action) => {
      state.tags = mapTags(action.payload.data);
    },
    setGoalTypes: (state, action) => {
      state.goalTypes = mapGoalTypes(action.payload.data);
    },
    setCountries: (state, action) => {
      state.countries = mapCountries(action.payload.data);
    }
  }
});

const {
  setOffers,
  setOffersTags,
  setCountries,
  setGoalTypes
} = offersSlice.actions;

export const fetchOffers = (
  params?: ApiOffersGet,
  isAdding?: boolean,
  isOverWrite?: boolean
): AppThunk => async (dispatch, getState) => {
  try {
    const response = await OffersApi.getOffers(params);
    dispatch(setOffers({ offers: response.data, isAdding, isOverWrite }));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchOffersTags = (): AppThunk => async (dispatch) => {
  try {
    const response = await OffersApi.getOffersTags();
    dispatch(setOffersTags(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchOffersGoalTypes = (): AppThunk => async (dispatch) => {
  try {
    const response = await OffersApi.getOffersGoalTypes();
    dispatch(setGoalTypes(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchOffersCountries = (
  params?: ApiOffersCountriesGet
): AppThunk => async (dispatch) => {
  try {
    const response = await OffersApi.getOffersCountries(params);
    dispatch(setCountries(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const selectOffers = (state: RootState) => state.offers;

export default offersSlice.reducer;
