import React from 'react';
import { MergeElementProps } from '../types/types';

export enum InputValidateState {
  error = 'error',
  warning = 'warning'
}

export interface InputBaseBaseProps {
  multiline?: boolean;
  fullWidth?: boolean;
  rows?: number;
  required?: boolean;
  validate?: InputValidateState;
  type?: string;
}

export type InputBaseProps<
  P extends React.ElementType = 'input'
> = MergeElementProps<P, InputBaseBaseProps>;
