import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import Divider from '../../components/Divider';
import { BalanceInfoProps } from './interface';
import { color } from '../../bit/components/utils/utils';
import { getCurrencySymbol } from '../../resources/functions';

const StyledBalanceInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledBalanceInfo = styled.div`
  display: flex;
  align-self: center;

  width: 100%;

  overflow-x: auto;
`;

const BalanceInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DividerContainer = styled.div`
  flex: 1;
  display: flex;
  align-self: stretch;
  margin: 0 12px;

  hr {
    margin: 0 auto;
  }
`;

const Title = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  size: 14
}))`
  margin-bottom: 16px;
  white-space: pre-wrap;
  color: ${color('text.themeMain')};
`;

const Value = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
  size: 18
}))<{ type?: 'approved' | 'pending' | 'total' }>`
  color: ${(props) => {
    const { type } = props;

    if (type === 'approved') {
      return color('success.main')(props);
    } else if (type === 'pending') {
      return color('status.5')(props);
    } else if (type === 'total') {
      return color('text.themeMain')(props);
    }
  }};
`;

const BalanceInfo = (props: BalanceInfoProps) => {
  const { balance } = props;

  const { t } = useTranslation();

  const currency = React.useMemo(() => getCurrencySymbol(balance?.currency), [
    balance
  ]);

  return (
    <StyledBalanceInfoContainer>
      <StyledBalanceInfo>
        <BalanceInfoColumn>
          <Title>{t('billing.confirmedPay')}</Title>
          <Value type='approved'>
            {currency}
            {balance?.approvedPayout}
          </Value>
        </BalanceInfoColumn>
        <DividerContainer>
          <Divider vertical />
        </DividerContainer>
        <BalanceInfoColumn>
          <Title>{t('billing.unconfirmedPay')}</Title>
          <Value type='pending'>
            {currency}
            {balance?.pendingPayout}
          </Value>
        </BalanceInfoColumn>
        <DividerContainer>
          <Divider vertical />
        </DividerContainer>
        <BalanceInfoColumn>
          <Title>{t('billing.totalPay')}</Title>
          <Value type='total'>
            {currency}
            {balance?.paidToDate}
          </Value>
        </BalanceInfoColumn>
      </StyledBalanceInfo>
    </StyledBalanceInfoContainer>
  );
};

export default BalanceInfo;
