import { NotificationItemData } from '../NotificationItem/NotificationItemTypes';
import NotificationItem from '../NotificationItem';
import { NotificationProps, NotificationState } from './NotificationTypes';
import { StyledNotification } from './NotificationStyled';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Notification extends Component<NotificationProps, NotificationState> {
  static instance?: Notification = undefined;

  static show(notification: NotificationItemData) {
    if (!Notification.instance) return;
    Notification.instance.show(notification);
  }

  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      notifications: {}
    };

    Notification.instance = this;
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  private show(notification: NotificationItemData) {
    const id = Math.random().toString(36).slice(2);

    const notifications = {
      ...this.state.notifications,
      [id]: notification
    };

    this.setState({ notifications });
  }

  private hide(id: string) {
    const notifications = { ...this.state.notifications };
    delete notifications[id];

    this.setState({ notifications });
  }

  render() {
    if (Object.keys(this.state.notifications).length === 0) return null;
    if (!document.body) return null;

    return ReactDOM.createPortal(
      <StyledNotification>
        {Object.entries(this.state.notifications).map(([id, data]) => (
          <NotificationItem key={id} id={id} data={data} hide={this.hide} />
        ))}
      </StyledNotification>,
      document.body
    );
  }
}

export default Notification;
