import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiAlertsGetParams,
  ApiAlertsGetResponse,
  ApiRemindersGetParams,
  ApiManagersGetResponse,
  ApiAffiliatesGetParams,
  ApiRemindersGetResponse,
  ApiAffiliatesGetResponse,
  ApiFreeAffiliatesGetParams,
  ApiFreeAffiliatesGetResponse,
  ApiUpdateAffiliateCommentPostBody,
  ApiCapsGetParams,
  ApiCapsGetResponse,
  ApiOffersByAffiliateIdPutParams,
  ApiOffersByAffiliateIdGetParams,
  ApiOffersByAffiliateIdGetResponse,
  ApiOffersByAffiliateIdPutBody,
  ApiOffersByAffiliateIdPutResponse,
  ApiLinkUserToManagerPatchBody,
  ApiAlertsPromoGetParams,
  ApiAlertsPromoGetResponse,
  ApiAddDuplicateAffiliates,
  ApiApproveAffiliatePromoOfferPutBody
} from '../types/api/managerial';

class ManagerialApi {
  static getManagers(): AxiosPromise<ApiManagersGetResponse> {
    return Api.call('/managerial/managers');
  }

  static getAlerts(
    params: ApiAlertsGetParams
  ): AxiosPromise<ApiAlertsGetResponse> {
    return Api.call(`/managerial/alerts`, { params, method: 'GET' });
  }

  static getAlertsPromo(
    params: ApiAlertsPromoGetParams
  ): AxiosPromise<ApiAlertsPromoGetResponse> {
    return Api.call(`/managerial/offers_approvals`, { params, method: 'GET' });
  }

  static getCaps(params: ApiCapsGetParams): AxiosPromise<ApiCapsGetResponse> {
    return Api.call(`/managerial/alerts_limit`, { params, method: 'GET' });
  }

  static getReminders(
    params: ApiRemindersGetParams
  ): AxiosPromise<ApiRemindersGetResponse> {
    return Api.call(`/managerial/reminders`, { params, method: 'GET' });
  }

  static getAffiliates(
    params: ApiAffiliatesGetParams
  ): AxiosPromise<ApiAffiliatesGetResponse> {
    return Api.call(`/managerial/affiliates`, { params, method: 'GET' });
  }

  static getOffersByAffiliateId(
    params: ApiOffersByAffiliateIdGetParams
  ): AxiosPromise<ApiOffersByAffiliateIdGetResponse> {
    return Api.call(`/managerial/affiliate_offers`, { params, method: 'GET' });
  }

  static updateOfferByIdAndByAffiliateId(
    id: string | number,
    data: ApiOffersByAffiliateIdPutBody,
    params: ApiOffersByAffiliateIdPutParams
  ): AxiosPromise<ApiOffersByAffiliateIdPutResponse> {
    return Api.call(`/offers/${id}`, { data, params, method: 'PATCH' });
  }

  static updateOfferAffiliates(
    id: number | string
  ): AxiosPromise<ApiOffersByAffiliateIdPutResponse> {
    return Api.call(`/managerial/refresh_offer_affiliates/${id}`, {
      method: 'PUT'
    });
  }

  static getFreeAffiliates(
    params: ApiFreeAffiliatesGetParams
  ): AxiosPromise<ApiFreeAffiliatesGetResponse> {
    return Api.call(`/managerial/free_affiliates`, { params, method: 'GET' });
  }

  static downloadStatistic(
    params: Pick<
      ApiAffiliatesGetParams,
      'to' | 'from' | 'managerId' | 'withManagerId'
    >
  ): AxiosPromise {
    return Api.call('/managerial/download_statistic', {
      params,
      method: 'GET',
      responseType: 'blob'
    });
  }

  static updateAffiliatesComments(
    affiliateComments: ApiUpdateAffiliateCommentPostBody
  ): AxiosPromise {
    return Api.call(`/managerial/affiliates`, {
      data: { affiliateComments },
      method: 'POST'
    });
  }

  static approveAffiliatePromoOffer({
    id,
    data
  }: ApiApproveAffiliatePromoOfferPutBody): AxiosPromise {
    return Api.call(`/managerial/offers_approvals/${id}`, {
      data,
      method: 'PUT'
    });
  }

  static getAffiliatesStatistic(): AxiosPromise {
    return Api.call(`/managerial/affiliates_statistic`, {
      method: 'PUT'
    });
  }

  static linkUserToManager(
    id: string | number,
    data: ApiLinkUserToManagerPatchBody
  ): AxiosPromise<{ success: boolean } | void> {
    return Api.call(`/managerial/managers/${id}`, {
      data,
      method: 'PATCH'
    });
  }

  static unlinkUserFromManger(
    id: string | number
  ): AxiosPromise<{ success: boolean } | void> {
    return Api.call(`/managerial/managers/${id}/user`, {
      method: 'DELETE'
    });
  }

  static addDuplicateAffiliates({
    id,
    duplicateIds
  }: ApiAddDuplicateAffiliates): AxiosPromise {
    return Api.call(`/managerial/affiliates_duplicates/${id}`, {
      data: { duplicateIds },
      method: 'PUT'
    });
  }
}

export default ManagerialApi;
