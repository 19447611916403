const theme = {
  borderRadius: '4px',
  font: {
    family: 'Inter, sans-serif'
  },
  palette: {
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      light: '#EBF3FF',
      lightSecond: '#E1E9F5',
      main: '#0066FF',
      dark: '#005CF5'
    },
    text: {
      main: '#212126',
      first: '#CCCDD3',
      second: '#989FA6',
      third: '#6D6D78'
    },
    success: {
      main: '#51DB67'
    },
    error: {
      main: '#FF5A5A'
    },
    warning: {
      main: '#FFB55C'
    },
    border: {
      main: '#EAEEF1'
    },
    background: {
      main: '#F6F6FA',
      dark: '#373737',
      light: '#FCFAFA',
      darkWhite: '#FAFAFA'
    },
    status: {
      1: '#25C0DE',
      2: '#A731BC',
      3: '#33B069',
      4: '#88D12C',
      5: '#FEB931',
      6: '#2D7ECA'
    }
  },
  transitions: {
    easing: {
      easeInOut: 'ease-in-out'
    },
    duration: {
      short: 250
    }
  },
  components: {
    sidebar: {
      background: '#fff',
      collapsedWidth: 80,
      width: 300
    },
    modal: {
      background: '#FAFAFA',
      zIndex: 10000
    },
    dropdownList: {
      hoverBackground: '#f0f0f0'
    },
    formElementLabel: {
      color: '#6D6D78'
    }
  },
  media: {
    tablet: '(max-width: 768px)'
  }
};

export type Theme = typeof theme;

export default theme;
