import { useCallback, useState } from 'react';

const useModalState = () => {
  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), []);

  const closeModal = useCallback(() => setOpen(false), []);

  return {
    open,
    setOpen,
    openModal,
    closeModal
  };
};

export default useModalState;
