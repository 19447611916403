import { UserMessengers } from '../types';
import { UserMessengersResponse } from '../types/index';

export const mapUserMessengers = ({
  telegram,
  whatsapp,
  skype,
  wechat
}: UserMessengersResponse): UserMessengers => ({
  telegram: telegram || '',
  whatsapp: whatsapp || '',
  skype: skype || '',
  wechat: wechat || ''
});
