import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyInfo,
  fetchUserInfo,
  selectUser
} from './redux/slices/userSlice';

import Router from './components/Router';
import LogInPage from './pages/LogInPage';
import AppSidebar from './components/AppSidebar';
import { setThemeAsync, ThemeEnum } from './redux/slices/settingsSlice';
import useRouteFound from './hooks/useRouteFound';

import ErrorPage from './pages/ErrorPage/ErrorPage';
import ModalLinkUserToManager from './static-modals/ModalLinkUserToManager';
import ModalAdministrationCredentials from './static-modals/ModalAdministrationCredentials';
import ModalSettingUpDuplicateAffiliates from './static-modals/ModalSettingUpDuplicateAffiliates';
import ModalManagerialUpdateOfferAffiliates from './static-modals/ModalManagerialUpdateOfferAffiliates';

function App() {
  const dispatch = useDispatch();

  const { isFoundRoute } = useRouteFound();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.token) {
      dispatch(fetchUserInfo());
      dispatch(fetchCompanyInfo());
      dispatch(setThemeAsync());
    } else {
      dispatch(setThemeAsync(ThemeEnum.dark, true));
    }
  }, [dispatch, user.token]);

  if (user.token && user.info?.id) {
    if (!isFoundRoute) return <ErrorPage />;

    return (
      <>
        <AppSidebar />
        <Router />

        <ModalLinkUserToManager />
        <ModalAdministrationCredentials />
        <ModalSettingUpDuplicateAffiliates />
        <ModalManagerialUpdateOfferAffiliates />
      </>
    );
  }

  return <LogInPage />;
}

export default App;
