import React from 'react';
import Table from '../../components/Table';
import { useTranslation } from 'react-i18next';
import { DetailsTableProps } from './interface';

const DetailsTable = (props: DetailsTableProps) => {
  const { details } = props;

  const { t } = useTranslation();

  const options = React.useMemo(
    () => [
      { dataKey: 'currency', title: t('common.currency') },
      { dataKey: 'payout', title: t('common.sum') },
      { dataKey: 'payoutDate', title: t('common.payoutDate') },
      { dataKey: 'paymentMethod', title: t('common.paymentMethod') }
    ],
    [t]
  );

  const rows = React.useMemo(
    () =>
      details.map((detail, index) => ({
        key: index,
        currency: detail.currency,
        payout: detail.payout,
        payoutDate: detail.paymentDate,
        paymentMethod: detail.paymentMethodInfo?.title
      })),
    [details]
  );

  return <Table options={options} rows={rows} isEmpty={!rows.length} />;
};

export default DetailsTable;
