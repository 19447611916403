import React, { useEffect, useState } from 'react';
import {
  ApiManager,
  ApiLinkUserToManagerPatchBody
} from '../types/api/managerial';
import { useTranslation } from 'react-i18next';

import withStaticModal, {
  StaticModalWrappedComponent
} from './withStaticModal';
import { useDispatch } from 'react-redux';
import { setManagerialManagersAction } from '../redux/slices/managerialSlice';

import Input from '../components/Input';
import Button from '../components/Button';
import Modal from '../bit/components/modal';
import ModalTitle from '../components/ModalTitle';
import Notification from '../components/Notification';
import useBoolState from '../hooks/useBoolState';
import ManagerialApi from '../api/managerial';
import Api from '../api';
import { AxiosError } from 'axios';

const ModalLinkUserToManager: StaticModalWrappedComponent<ApiManager> = ({
  data,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const { state: isLoading, setTrue, setFalse } = useBoolState();

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const onSubmit = async () => {
    if (!data?.id) return;

    try {
      setTrue();

      const body: ApiLinkUserToManagerPatchBody = { email };
      await ManagerialApi.linkUserToManager(data.id, body);
      dispatch(setManagerialManagersAction());

      Notification.show({
        delay: 1200,
        message: t('administration.modalLink.saveManagerUserSuccess')
      });

      onClose();
    } catch (error) {
      Api.handleDefaultError(error as AxiosError);
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setEmail(data?.userEmail || '');
  }, [data?.userEmail, isOpen]);

  const message = data?.userEmail
    ? t('administration.modalLink.changeManagerUser')
    : t('administration.modalLink.linkManagerUser');

  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={700}>
      <ModalTitle>{message}</ModalTitle>

      <Input
        value={email}
        label={t('fields.email.label')}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t('fields.email.placeholder')}
      />

      <Button
        size='big'
        onClick={onSubmit}
        isLoading={isLoading}
        style={{ marginTop: 20 }}
      >
        {t('administration.modalLink.saveManagerUser')}
      </Button>
    </Modal>
  );
};

export default withStaticModal<ApiManager>(ModalLinkUserToManager);
