import React, { useEffect } from 'react';

import { LoaderProps } from './LoaderTypes';
import { StyledLoader } from './LoaderStyled';

import { ClipLoader } from 'react-spinners';

const Loader = ({
  onMount,
  color = 'inherit',
  size = 24,
  isStatic
}: LoaderProps) => {
  useEffect(() => {
    if (onMount) onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLoader $isStatic={isStatic}>
      <ClipLoader size={size} color={color} />
    </StyledLoader>
  );
};

export default Loader;
