import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './interface';
import VVUIDropdown, { DropdownOnChange } from '../../bit/components/dropdown';
import LocalStorage from '../../utils/LocalStorage';
import generateDropdownStorageKey from '../../utils/generateDropdownsStorageKey';

const Dropdown = ({
  onChange,
  withInitialSetSearchValue,
  savedKey,
  options,
  ...props
}: Props & { savedKey?: string }) => {
  const { t } = useTranslation();

  const handleChange: DropdownOnChange = useCallback(
    (value) => {
      if (withInitialSetSearchValue && savedKey) {
        LocalStorage.set(generateDropdownStorageKey(savedKey), options);
      }

      onChange(value);
    },
    [onChange, options, savedKey, withInitialSetSearchValue]
  );

  return (
    <VVUIDropdown
      {...props}
      options={options}
      onChange={handleChange}
      withInitialSetSearchValue={withInitialSetSearchValue}
      emptyOptionText={t('common.startSearch')}
      notFoundOptionText={t('common.notFound')}
      fetchingOptionText={t('common.isFetching')}
    />
  );
};

export default Dropdown;
