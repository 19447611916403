import { CurrencyKeys } from './index';

export interface OfferCreative {
  title: string;
  type: number;
  id: number;
}

export interface OfferLanding {
  id: number;
  title: string;
  type: number;
  url: string;
}

export interface OfferGoal {
  id: number;
  typeName: string;
  payout: string;
  name: string;
  conversionStatus: number;
}

export interface OfferTargeting {
  connectionType: {
    allowed: string;
  };
  geo: {
    allowed: string;
    denied: string;
  };
}

export interface Offer {
  id: number;
  image: string;
  title: string;
  goals: OfferGoal[];
  targeting: OfferTargeting;
  tags: string;
  cr: string;
  epc: string;
  linksCount: number;
  creativesCount: number;
  description: string;
  trafficTypes: string;
  timezone: string;
  currency: CurrencyKeys;
  landings: OfferLanding[];
  creatives: OfferCreative[];
  canPromote?: boolean;
}

export enum ConversionStatus {
  approved = 2,
  pending = 3,
  rejected = 4
}

export enum GoalLimitType {
  approved = 1,
  pending = 2,
  budget = 3
}

export enum GoalLimitPeriod {
  daily = 1,
  weekly = 2,
  monthly = 3,
  total = 4
}

export enum LandingType {
  default = 1,
  email = 2,
  other = 3
}

export enum CreativeType {
  banner = 1,
  email = 2,
  other = 3
}

export interface Tag {
  id: number;
  title: string;
}

export interface GoalType {
  id: number;
  title: string;
}

export interface Country {
  id: string;
  title: string;
}

export interface Requests {
  id: string;
  offer_id: string;
  affiliate_id: string;
  status: string;
  additional_info: string;
  created: string;
  updated: string;
}

export enum RootDataItemTitle {
  private = 'Private',
  public = 'Public',
  byRequest = 'By Request'
}

export interface RootDataItem {
  availability: string;
  id: number;
  requests: Requests[];
  title: RootDataItemTitle;
}

export type OfferVisibility = RootDataItem[];
