import React, { useState, useMemo, useCallback } from 'react';

import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';

import OffersApi from '../api/offers';

import Dropdown, { DropdownOption } from './Dropdown';

import { mapCountries } from '../maps/offersMap';
import { ApiOffersCountriesGet } from '../types/api/offers';
import { CommonFilterProps } from '../types/utils';
import useAwaitCallback from '../hooks/useAwaitCallback';
import {
  getDataFromLocalStorage,
  DROPDOWNS_SAVE_KEYS
} from '../utils/generateDropdownsStorageKey';

const StatisticPageCountry = ({
  value,
  onChange,
  withSavingData
}: CommonFilterProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState(
    getDataFromLocalStorage<DropdownOption>(
      withSavingData,
      DROPDOWNS_SAVE_KEYS.country
    )
  );

  const [fetchCountriesList, isFetchingCountries] = useAwaitCallback(
    async (params?: ApiOffersCountriesGet) => {
      const response = await OffersApi.getOffersCountries({
        limit: 10,
        ...params
      });

      const array = mapCountries(response.data.data).map((a) => ({
        offer: a,
        value: a.id,
        name: a.title
      }));

      setCountriesList(array);
    },
    []
  );

  const debouncedCountriesSearch = useMemo(
    () =>
      debounce(300, false, async (search: string) => {
        fetchCountriesList({ search });
      }),
    [fetchCountriesList]
  );

  const onChangeCountriesSearchInput = useCallback(
    (search: string) => {
      debouncedCountriesSearch(search);
    },
    [debouncedCountriesSearch]
  );

  return (
    <Dropdown
      savedKey={DROPDOWNS_SAVE_KEYS.country}
      withInitialSetSearchValue={withSavingData}
      searchable
      clearable
      isSearchableForApi
      isFetchingForApi={isFetchingCountries}
      value={value}
      onChange={onChange}
      options={countriesList}
      label={t('common.country')}
      onInput={onChangeCountriesSearchInput}
    />
  );
};

export default StatisticPageCountry;
