import styled from 'styled-components';

import { StyledIconProps } from './IconTypes';
import transientProps from '../../utils/transientProps';

export const StyledIcon = styled('svg').withConfig(
  transientProps
)<StyledIconProps>`
  cursor: ${({ $isPointer, onClick }) => ($isPointer || onClick) && 'pointer'};
`;
