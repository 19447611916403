import React, { FC, useCallback } from 'react';
import FormElementLabel from '../../bit/components/form-element-label';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../bit/components/checkbox';
import styled from 'styled-components';
import {
  SectionsCredentials,
  SectionsCredentialsName,
  SectionsCredentialsValue
} from '../../types/employees';
import { Props } from './interface';

const StyledCheckbox = styled(Checkbox)`
  & + & {
    margin-top: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SectionGroup = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const SectionsSettings: FC<Props> = ({
  style,
  sections,
  className,
  withTitle = true,
  isAdministration,
  sectionsCredentials,
  setSectionsCredentials
}) => {
  const { t } = useTranslation();

  const createCheckboxHandler = useCallback(
    (
      sectionName: SectionsCredentialsName,
      access: SectionsCredentialsValue
    ) => () => {
      setSectionsCredentials((prevState) => {
        const nextState = { ...prevState } as SectionsCredentials;
        const prevAccess = nextState[sectionName];

        if (!prevAccess) {
          nextState[sectionName] = access;
          return nextState;
        }

        let nextAccess = access;

        if (nextAccess === prevAccess) {
          nextAccess -= 1;
        } else if (nextAccess < prevAccess) {
          nextAccess = (nextAccess - 1) as SectionsCredentialsValue;
        }

        nextState[sectionName] = nextAccess as SectionsCredentialsValue;

        return nextState;
      });
    },
    [setSectionsCredentials]
  );

  if (!sectionsCredentials || !sections) {
    return null;
  }

  return (
    <Container style={style} className={className}>
      {withTitle && (
        <FormElementLabel>{t('employees.sectionsSettings')}</FormElementLabel>
      )}

      {sections.map((section) => {
        if (
          !isAdministration &&
          ['main', 'support', 'managerial'].includes(section)
        ) {
          return null;
        }

        const standardAccessLabel = isAdministration
          ? t('sections.standard')
          : t('sections.read');

        const administratorAccessLabel = isAdministration
          ? t('sections.administrator')
          : t('sections.change');

        return (
          <SectionGroup key={section}>
            <FormElementLabel>{t(`sections.${section}`)}</FormElementLabel>

            <StyledCheckbox
              label={standardAccessLabel}
              checked={sectionsCredentials[section] >= 1}
              onChange={createCheckboxHandler(section, 1)}
            />

            {section !== 'support' && (
              <StyledCheckbox
                label={administratorAccessLabel}
                checked={sectionsCredentials[section] >= 2}
                onChange={createCheckboxHandler(section, 2)}
              />
            )}
          </SectionGroup>
        );
      })}
    </Container>
  );
};

export default SectionsSettings;
