import React, { useCallback } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useFromToDatePicker from '../../hooks/useFromToDatePicker';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import Page from '../../components/Page';
import Grid from '../../components/Grid';
import Block from '../../components/Block';
import TableClicks from '../../components/TableClicks';
import TableStatistics from '../../components/TableStatistics';
import TableConversions from '../../components/TableConversions';

import { StatisticTab } from './interface';
import { MANAGERIAL_PAGE_HEAD } from '../../resources/constants';

import {
  StyledTab,
  HeaderBody,
  HeaderTabs,
  HeaderContainer,
  StyledTabContainer
} from './styled';

const fromLastMonth = moment().subtract(1, 'month');

const ReportsPage = () => {
  const { t } = useTranslation();
  const { DatePicker } = useFromToDatePicker(fromLastMonth, true);

  const [{ activeTab }, setQuery] = useQueryParams({
    activeTab: withDefault(StringParam, StatisticTab.statistic)
  });

  const onChangeTab = useCallback(
    (newTab: string) => {
      setQuery({ activeTab: newTab });
    },
    [setQuery]
  );

  return (
    <Page>
      <HeaderContainer
        title={t('sidebar.reports')}
        additionalTitle={<DatePicker />}
      >
        <HeaderBody>
          <div id={MANAGERIAL_PAGE_HEAD} />

          <HeaderTabs>
            <StyledTabContainer activeId={activeTab} onChange={onChangeTab}>
              <StyledTab variant='open' id={StatisticTab.statistic}>
                {t('tabs.statistic')}
              </StyledTab>
              <StyledTab variant='open' id={StatisticTab.conversion}>
                {t('tabs.conversion')}
              </StyledTab>
              <StyledTab variant='open' id={StatisticTab.clicks}>
                {t('tabs.clicks')}
              </StyledTab>
            </StyledTabContainer>
          </HeaderTabs>
        </HeaderBody>
      </HeaderContainer>

      <Grid>
        <Block disablePadding fullWidth>
          {activeTab === StatisticTab.clicks && <TableClicks />}
          {activeTab === StatisticTab.statistic && <TableStatistics />}
          {activeTab === StatisticTab.conversion && <TableConversions />}
        </Block>
      </Grid>
    </Page>
  );
};

export default ReportsPage;
