import { BillingsBalance, BillingsDetails } from '../../types/billings';

export interface BalanceInfoProps {
  balance?: BillingsBalance;
}

export interface DetailsTableProps {
  details: BillingsDetails[];
}

export enum BillingTabs {
  payments = 'payments',
  referral = 'referral'
}
