import {
  Statistic,
  StatisticGeo,
  StatisticClick,
  StatisticConversion
} from '../types/statistics';

import {
  ApiStatisticsGetResponse,
  ApiStatisticsClicksGetResponse,
  ApiStatisticsConversionsGetResponse
} from './../types/api/statistics';

export const mapStatistic = (
  item: ApiStatisticsGetResponse['data']['rows'][0]
): Statistic => ({
  date: item.date || '',
  cpa: Number(item.cpa_total || 0),
  cpl: Number(item.cpl_total || 0),
  payout: {
    total: Number(item.total_payout || 0),
    approved: Number(item.approved_payout || 0),
    revshare: Number(item.cps_total_payout || 0),
    cpa_total: Number(item.cpa_total_payout || 0),
    cpl_total: Number(item.cpl_total_payout || 0)
  },
  clicks: {
    total: Number(item.clicks || 0),
    unique: Number(item.unique_clicks || 0)
  },
  upsale: Number(item.upsale || 0),
  connectionTypeName: item.connection_type_name,
  countryName: item.country_name,
  domain: item.aff_param2,
  employeeId: item.aff_param5,
  deviceName: item.device_name,
  goalName: item.goal_name,
  hour: item.hour,
  offerName: item.offer_name,
  subId1: item.sub_id1,
  subId2: item.sub_id2,
  subId3: item.sub_id3,
  subId4: item.sub_id4,
  subId5: item.sub_id5
});

export const mapStatisticClick = (
  item: ApiStatisticsClicksGetResponse['data'][0]
): StatisticClick => ({
  partner: '',
  clickId: '',
  partnerClickId: '',
  offer: item.offer || '',
  date: item.added_timestamp || '',
  geo: mapStatisticGeo(item.geo)
});

export const mapStatisticConversion = (
  item: ApiStatisticsConversionsGetResponse['data'][0]
): StatisticConversion => ({
  income: '',
  profit: '',
  partner: '',
  goal: item.goal || '',
  offer: item.offer || '',
  payout: item.payout || '',
  date: item.added_timestamp || '',
  status: item.conversion_status || '',
  geo: mapStatisticGeo(item.geo)
});

export const mapStatisticGeo = (
  item: ApiStatisticsConversionsGetResponse['data'][0]['geo']
): StatisticGeo => ({
  city: item.city || '',
  region: item.region || '',
  country: item.country || '',
  countryCode: item.country_code || ''
});
