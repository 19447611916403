import {
  ApiBillingsBalance,
  ApiBillingsDetails,
  ApiBillingsPaymentsResponse
} from '../types/api/billings';
import {
  BillingsBalance,
  BillingsDetails,
  BillingsDetainsPaymentMethodInfo,
  BillingsPayment
} from '../types/billings';

export const mapBillingsDetailsPaymentMethodInfo = (
  paymentMethodInfo?: ApiBillingsDetails['payment_method_info']
): BillingsDetainsPaymentMethodInfo | undefined =>
  paymentMethodInfo
    ? {
        details: paymentMethodInfo.details,
        id: paymentMethodInfo.id,
        paymentMethodLogo: paymentMethodInfo.payment_method_logo,
        paymentThreshold: paymentMethodInfo.payment_threshold,
        paymentThresholdCurrency: paymentMethodInfo.payment_threshold_currency,
        title: paymentMethodInfo.title
      }
    : undefined;

export const mapBillingsDetails = (
  details: ApiBillingsDetails[]
): BillingsDetails[] =>
  details.map((detail) => ({
    currency: detail.currency,
    paymentDate: detail.payment_date,
    paymentMethod: detail.payment_method,
    paymentMethodInfo: mapBillingsDetailsPaymentMethodInfo(
      detail.payment_method_info
    ),
    payout: detail.payout,
    pendingPayout: detail.pending_payout
  }));

export const mapBillingsBalance = (
  balance: ApiBillingsBalance
): BillingsBalance => ({
  approvedPayout: balance.approved_payout,
  currency: balance.currency,
  paidToDate: balance.paid_to_date,
  pendingPayout: balance.pending_payout
});

export const mapBillingsPayments = (
  data: ApiBillingsPaymentsResponse['data']
): BillingsPayment[] =>
  data.map((payment) => ({
    currency: payment.currency,
    date: payment.added_timestamp,
    notes: payment.notes,
    paymentId: payment.payment_details?.id || null,
    status: payment.status,
    sum: payment.amount,
    id: payment.id,
    invoice: payment.invoice_number
  }));
