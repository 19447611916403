import React from 'react';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';
import { Props } from './interface';

const StyledBlock = styled.div<Props>`
  background-color: ${color('background.main')};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.04);

  width: calc(100% - 24px);
  display: flex;
  flex-direction: column;

  margin: 12px;
  padding: ${(p) => !p.disablePadding && '24px'};
`;

const Block = (props: Props) => {
  const { children, disablePadding, fullWidth, ...otherProps } = props;

  return (
    <StyledBlock
      {...otherProps}
      disablePadding={disablePadding}
      fullWidth={fullWidth}
    >
      {children}
    </StyledBlock>
  );
};

export default Block;
