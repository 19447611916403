import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import withStaticModal, {
  StaticModalWrappedComponent
} from '../withStaticModal';
import {
  StyledIcon,
  StyledButton,
  StyledWrapper,
  StyledDropdownWrapper
} from './ModalSettingUpDuplicateAffiliatesStyled';
import Button from '../../components/Button';
import Modal from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import { ApiAddDuplicateAffiliates } from '../../types/api/managerial';
import useBoolState from '../../hooks/useBoolState';
import Api from '../../api';
import { AxiosError } from 'axios';
import Notification from '../../components/Notification';
import DeleteIcon from '../../images/delete.svg';
import ManagerialApi from '../../api/managerial';
import Input from '../../components/Input';
import { AffiliateComponentProps } from '../../pages/SupportManagerialPage/interface';

const delay = 1400;
const type = 'error';

const ModalSettingUpDuplicateAffiliates: StaticModalWrappedComponent<AffiliateComponentProps> = ({
  data,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();

  const { state: isLoading, setTrue, setFalse } = useBoolState();

  const [duplicateIds, setDuplicateIds] = useState<string[]>([]);

  const onSubmit = async () => {
    if (!data?.affiliate.id) return;

    const uniqueValues = new Set(duplicateIds);

    if (uniqueValues.size !== duplicateIds.length && duplicateIds.length > 0) {
      Notification.show({
        delay,
        message: t('common.duplicateIDsDetected'),
        type
      });
      return;
    }

    if (duplicateIds.some((a) => Number(a) === data?.affiliate.id)) {
      Notification.show({
        delay,
        message: t('common.notPossibleToAddID'),
        type
      });
      return;
    }

    try {
      setTrue();

      const body: ApiAddDuplicateAffiliates = {
        id: data.affiliate.id,
        duplicateIds: duplicateIds.filter(Boolean).map(Number)
      };
      await ManagerialApi.addDuplicateAffiliates(body);

      data.onUpdate?.();

      Notification.show({ delay, message: t('common.dataSaved') });

      onClose();
    } catch (error) {
      Api.handleDefaultError(error as AxiosError);
    } finally {
      setFalse();
    }
  };

  const handleAddDuplicate = useCallback(() => {
    setDuplicateIds((prevState) => [...prevState, '']);
  }, []);

  const handleRemoveDuplicate = useCallback(
    (duplicateId: string) => () => {
      setDuplicateIds((prevState) => {
        const newState = prevState.filter((a) => a !== duplicateId);
        return newState;
      });
    },
    []
  );

  const handleChangeDuplicate = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDuplicateId = e.target.value;
      const newState = [...duplicateIds];

      newState[index] = newDuplicateId;
      setDuplicateIds(newState);
    },
    [duplicateIds]
  );

  const config = useMemo(
    () =>
      duplicateIds.map((a, i) => (
        <StyledDropdownWrapper key={i}>
          <Input
            fullWidth
            value={a}
            placeholder='ID'
            onChange={handleChangeDuplicate(i)}
          />
          <StyledIcon src={DeleteIcon} onClick={handleRemoveDuplicate(a)} />
        </StyledDropdownWrapper>
      )),
    [duplicateIds, handleChangeDuplicate, handleRemoveDuplicate]
  );

  useEffect(() => {
    if (!isOpen) return;
    setDuplicateIds(data?.affiliate.duplicateIds?.map(String) || []);
  }, [data?.affiliate.duplicateIds, isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={700}>
      <ModalTitle>
        {t('managerial.affiliates.modalDuplicates.title')}
      </ModalTitle>

      <StyledButton onClick={handleAddDuplicate}>
        {t('common.add')}
      </StyledButton>

      <StyledWrapper>{config}</StyledWrapper>

      <Button
        size='big'
        onClick={onSubmit}
        isLoading={isLoading}
        style={{ marginTop: 20 }}
      >
        {t('common.save')}
      </Button>
    </Modal>
  );
};

export default withStaticModal<AffiliateComponentProps>(
  ModalSettingUpDuplicateAffiliates
);
