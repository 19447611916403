import Modal from './Modal';
export type { ModalProps } from './interface';
export {
  ModalBackdrop,
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalScrollWrapper
} from './Modal';
export default Modal;
