import React from 'react';
import { Props } from './interface';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../../bit/components/icon';
import chevronLeftIcon from '../../images/chevronLeft.svg';
import Typography from '../../bit/components/typography';
import { color } from '../../bit/components/utils/utils';

const StyledGoBackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${color('text.second')};
  text-decoration: none;
  margin-right: 24px;
`;

const GoBackLink = (props: Props) => {
  const { to, label, ...otherProps } = props;

  return (
    <StyledGoBackLink {...otherProps} to={to}>
      <Icon src={chevronLeftIcon} size={20} color='inherit' />
      <Typography variant='subtitle1' size={18} color='inherit'>
        {label}
      </Typography>
    </StyledGoBackLink>
  );
};

export default GoBackLink;
