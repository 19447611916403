import { ApiReferralsGetResponse } from '../types/api/referrals';
import { Referral } from '../types/referrals';

export const mapReferrals = (
  data: ApiReferralsGetResponse['data']
): Referral[] =>
  data.map((item) => ({
    rate: item.rate,
    referral: item.referral,
    referralCommission: item.referral_commission
  }));
