import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import Table from '../Table/Table';
import Typography from '../Typography';
import OutlinedButton from '../OutlinedButton';

export const StyledTable = styled(Table)<{ minWidthFirstColumn?: number }>`
  table tr td:first-child {
    min-width: ${(props) =>
      props.minWidthFirstColumn && `${props.minWidthFirstColumn}px`};
  }
`;

export const CellText = styled(Typography)<{ isTotal?: boolean }>`
  font-weight: ${(props) => props.isTotal && 700};
`;

export const StyledExpandButton = styled(OutlinedButton)<{
  onClick: () => void;
  $isExpanded?: boolean;
  children: ReactElement;
}>`
  padding: 2px;
  margin-left: 16px;
  vertical-align: sub;

  span {
    display: flex;
    line-height: 1;
    transition: all 250ms;
    transform: rotate(-270deg);
  }

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      span {
        transform: rotate(-90deg);
      }
    `}
`;
