import React, { useCallback } from 'react';

const useHeaderPopper = (text: string, timer?: number) => {
  const [forceShowPopper, setForceShowPopper] = React.useState(true);

  React.useEffect(() => {
    if (timer === undefined) return;

    let timeout: NodeJS.Timeout | null = null;

    timeout = setTimeout(() => {
      setForceShowPopper(false);
    }, timer);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timer]);

  const onPopperIconClick = useCallback(() => {
    if (forceShowPopper) {
      setForceShowPopper(false);
    }
  }, [forceShowPopper]);

  return {
    popper: text,
    forceShowPopper,
    onPopperIconClick
  };
};

export default useHeaderPopper;
