import { DropdownOnChange } from '../../components/Dropdown';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from '../../components/Input';
import InputCopy from '../../components/InputCopy';
import Modal, { ModalContent } from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import OffersDropdown from '../../components/OffersDropdown';
import useBind from '../../hooks/useBind';
import useNamingLinkGenerator, {
  NamingLinkGeneratorParams
} from '../../hooks/useNamingLinkGenerator';
import { RootState } from '../../redux/store';
import { paperAppsId } from '../../resources/constants';
import { Props, State } from './interface';
import { InputsContainer, StyledDivider } from './NamingGeneratorModalStyled';

const initialState: State = {
  streamId: '',
  offerId: '',
  landingId: '',
  subId3: '',
  subId4: '',
  subId5: ''
};

const NamingGeneratorModal = ({ onClose, open }: Props) => {
  const { t } = useTranslation();

  const partnerId = useSelector(
    (state: RootState) => state.user.info?.company.scaleoId
  );

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (open) return;
    setState(initialState);
  }, [open]);

  const inputBind = useBind(state, setState);
  const { streamId, offerId, landingId, subId3, subId4, subId5 } = state;

  const createInputHandler = useCallback(
    (property: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setState((prevState) => ({
        ...prevState,
        [property]: event.target.value.replace(/[^a-zA-Z0-9]/g, '')
      }));
    },
    []
  );

  const createDropdownHandler = useCallback(
    (property: 'offerId' | 'landingId'): DropdownOnChange => (value) => {
      if (property === 'offerId') {
        setState((prevState) => ({
          ...prevState,
          offerId: value as string,
          landingId: ''
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [property]: value as string
        }));
      }
    },
    []
  );

  const linkParams = useMemo<NamingLinkGeneratorParams>(
    () => ({
      appKey: paperAppsId,
      streamId,
      partnerId: partnerId ? String(partnerId) : undefined,
      offerId,
      landingId,
      subId3,
      subId4,
      subId5
    }),
    [landingId, offerId, partnerId, streamId, subId3, subId4, subId5]
  );

  const link = useNamingLinkGenerator(linkParams);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{t('apps.namingGeneration')}</ModalTitle>
      <ModalContent>
        <InputsContainer>
          <OffersDropdown
            value={offerId}
            landing={landingId}
            formLabel={t('offers.offer')}
            onChange={createDropdownHandler('offerId')}
            onChangeLanding={createDropdownHandler('landingId')}
          />
        </InputsContainer>
        <InputsContainer>
          <Input
            {...inputBind('streamId')}
            label={t('support.streamId')}
            placeholder={t('support.streamIdPlaceholder')}
          />
          <Input
            value={subId3}
            onChange={createInputHandler('subId3')}
            label='Sub ID 3'
          />
          <Input
            value={subId4}
            onChange={createInputHandler('subId4')}
            label='Sub ID 4'
          />
          <Input
            value={subId5}
            onChange={createInputHandler('subId5')}
            label='Sub ID 5'
          />
        </InputsContainer>
        <StyledDivider />
        <InputCopy
          fullWidth
          label={t('apps.yoursLink')}
          text={link}
          multiline
          rows={4}
        />
      </ModalContent>
    </Modal>
  );
};

export default NamingGeneratorModal;
