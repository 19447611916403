import CredentialsApi from '../api/credentials';
import {
  SectionsCredentials,
  SectionsCredentialsName
} from '../types/employees';

interface User {
  id: number;
  credentials: SectionsCredentials;
}

export const patchUserCredentials = async (
  user: User,
  credentials: SectionsCredentials,
  isAdmin?: boolean
) => {
  const promises = [];

  for (const key in credentials) {
    const sectionName = key as SectionsCredentialsName;
    if (credentials[sectionName] === user.credentials[sectionName]) continue;

    const body = {
      userId: user.id,
      section: sectionName,
      access: credentials[sectionName]
    };

    if (isAdmin) {
      promises.push(CredentialsApi.updateFromAdministrator(body));
    } else {
      promises.push(CredentialsApi.update(body));
    }
  }

  await Promise.all(promises);
};
