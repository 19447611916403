import { createSlice } from '@reduxjs/toolkit';
import { ApiUsersGet, ApiUserInfoResponse } from '../../types/api/users';
import { AppThunk, RootState } from '../store';
import i18n from 'i18next';

import Api from '../../api';
import UserApi from '../../api/user';

import { getLocalStorage, setLocalStorage } from '../../resources/functions';
import {
  STORE_COMPANY_INFO,
  STORE_USER_INFO,
  STORE_USER_TOKEN
} from '../../resources/constants';
import { CompanyInfo, User, UserInfo } from '../../types/users';
import { mapCompanyInfo, mapUserInfo } from '../../maps/userMap';
import Notification from '../../components/Notification';

let isLogoutedByError = false;

interface UserState {
  token: string;
  info?: UserInfo;
  company?: CompanyInfo;
  users: User[];
}

const initUserInfo =
  (getLocalStorage(STORE_USER_INFO) as ApiUserInfoResponse) || undefined;

const initialState: UserState = {
  token: getLocalStorage(STORE_USER_TOKEN) || '',
  info: initUserInfo && mapUserInfo(initUserInfo),
  company: getLocalStorage(STORE_COMPANY_INFO),
  users: []
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: (state, action) => {
      state.info = undefined;
      state.token = '';
      localStorage.removeItem(STORE_USER_INFO);
      localStorage.removeItem(STORE_USER_TOKEN);
      localStorage.removeItem(STORE_COMPANY_INFO);
      Api.setToken('');

      const { isError } = action.payload;
      if (isError && !isLogoutedByError) {
        isLogoutedByError = true;
        Notification.show({
          type: 'error',
          message: i18n.t('common.repeatLogin')
        });
      }
    },
    setToken: (state, action) => {
      state.token = action.payload;
      Api.setToken(action.payload);
      setLocalStorage(STORE_USER_TOKEN, action.payload);
      isLogoutedByError = false;
    },
    setInfo: (state, action) => {
      const info = mapUserInfo(action.payload.data);
      state.info = info;
      setLocalStorage(STORE_USER_INFO, info);
    },
    setCompanyInfo: (state, action) => {
      const info = mapCompanyInfo(action.payload.data);
      state.company = info;
      setLocalStorage(STORE_COMPANY_INFO, info);
    },
    setUsers: (state, action) => {
      state.users = action.payload.data;
    }
  }
});

export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user.info?.id;
export const selectSectionsCredentials = (state: RootState) =>
  state.user.info?.sectionsCredentials;
export const selectUsers = (state: RootState) => state.user.users;
export const selectCompanyStatus = (state: RootState) =>
  state.user.company?.status;

export const {
  logOut,
  setToken,
  setInfo,
  setCompanyInfo,
  setUsers
} = userSlice.actions;

export const setTokenAsync = (token: string): AppThunk => async (dispatch) => {
  dispatch(setToken(token));
};

export const logOutAsync = (params?: { isError: boolean }): AppThunk => async (
  dispatch
) => {
  dispatch(logOut(params || {}));
};

export const fetchUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const response = await UserApi.getInfo();
    dispatch(setInfo(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchCompanyInfo = (): AppThunk => async (dispatch) => {
  try {
    const response = await UserApi.getCompanyInfo();
    dispatch(setCompanyInfo(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export const fetchUsers = (params?: ApiUsersGet): AppThunk => async (
  dispatch
) => {
  try {
    const response = await UserApi.getUsers(params);
    dispatch(setUsers(response.data));
  } catch (error) {
    Api.handleDefaultError(error);
  }
};

export default userSlice.reducer;
