import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedButton from '../OutlinedButton';
import { Props } from './interface';
import OffersApi from '../../api/offers';
import Api from '../../api';
import styled from 'styled-components';
import Button from '../../bit/components/button';
import Notification from '../Notification';

const StyledOutlinedButton: typeof Button = styled(OutlinedButton)`
  color: ${(props) => props.theme.components.outlinedButton.color};
  border-color: ${(props) => props.theme.components.outlinedButton.color};

  &:hover,
  &:active {
    color: ${(props) => props.theme.components.outlinedButton.hover};
    border-color: ${(props) => props.theme.components.outlinedButton.hover};
  }
`;

const RequestPromoteButton = (props: Props) => {
  const { visible, offerId, ...otherProps } = props;

  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    try {
      await OffersApi.gettingOfferAccess(offerId);

      Notification.show({
        type: 'success',
        message: t('offers.requestPromoteSuccess')
      });
    } catch (e) {
      Api.handleDefaultError(e);
    }
  }, [offerId, t]);

  if (!visible) {
    return null;
  }

  return (
    <StyledOutlinedButton {...otherProps} onClick={handleClick}>
      {t('offers.requestPromote')}
    </StyledOutlinedButton>
  );
};

export default RequestPromoteButton;
