import { color, transition } from '../../bit/components/utils/utils';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Props } from './interface';

const Arrow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border: 10px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
`;

const Container = styled.div<{ maxWidth?: number }>`
  pointer-events: none;
  white-space: pre-wrap;
  display: inline-flex;
  max-width: ${(props) => props.maxWidth && props.maxWidth + 'px'};
  padding: 16px;
  opacity: 0;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${color('common.white')};
  ${transition({ name: 'opacity' })};
  z-index: ${(props) => {
    return props.theme.components.modal.zIndex + 1;
  }};
  font-family: ${(props) => props.theme.font.family};
  line-height: 22px;
  font-size: 16px;

  &[data-show='true'] {
    pointer-events: auto;
    opacity: 1;
  }

  &[data-popper-placement^='bottom'] {
    margin-top: 15px;

    > ${Arrow} {
      top: -20px;
    }
  }
`;

const Popper = (props: Props) => {
  const { referenceElement, children, isShow, maxWidth } = props;

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: 'fixed',
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
    }
  );

  useEffect(() => {
    if (isShow && forceUpdate) {
      forceUpdate();
    }
  }, [isShow, forceUpdate]);

  return createPortal(
    <Container
      maxWidth={maxWidth}
      data-show={isShow}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
      <Arrow ref={setArrowElement} style={styles.arrow} />
    </Container>,
    document.getElementById('root') as HTMLDivElement
  );
};

export default Popper;
