import React, {
  ReactText,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { classNames, color, isFullWidth, updateArray } from '../utils/utils';
import InputBase from '../input-base';
import Typography from '../typography';
import { DropdownOption, DropdownProps } from './interface';
import useOpenState from './useOpenState';
import DropdownList from './DropdownList';
import FormElementLabel from '../form-element-label';
import Icon from '../icon';
import miniChevronDownIcon from './miniChevronDown.svg';
import closeIcon from './close.svg';

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${isFullWidth};
`;

const Styled = styled.div`
  display: inline-flex;
  min-width: 0;
  user-select: none;
  ${isFullWidth};

  .Dropdown {
    &__control {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1px solid ${color('border.main')};
      background-color: transparent;
      border-radius: ${(props) => props.theme.borderRadius};
      cursor: pointer;

      span {
        box-sizing: content-box;
        width: 100%;
        min-height: 1em;
        padding: 16px 40px 16px 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__icon {
      position: absolute;
      right: 12px;
    }

    &__search,
    &__chevron {
      font-size: 19px;
      top: calc(50% - 19px / 2);
      color: ${color('text.first')};
    }

    &__chevron {
      &--inverted {
        transform: rotate(180deg);
      }
    }

    &__clear {
      font-size: 16px;
      top: calc(50% - 8px);
      color: ${color('text.third')};
      cursor: pointer;
    }

    &__input {
      width: 100%;
      padding: 16px 40px 16px 16px;
      border: none;
      background-color: transparent;
    }
  }
`;

function Dropdown(props: DropdownProps) {
  const {
    withInitialSetSearchValue,
    options,
    value,
    multiple,
    label,
    onChange,
    clearable,
    onInput,
    isFetchingForApi,
    isSearchableForApi,
    searchable,
    fullWidth,
    className,
    style,
    virtualized,
    firstActives,
    onClick,
    formLabel,
    validate,
    onClear,
    renderOption,
    renderControl,
    inputValue,
    emptyOptionText = 'Начните поиск',
    fetchingOptionText = 'Загрузка...',
    notFoundOptionText = 'Ничего не найдено'
  } = props;

  const anchorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isInitialSetSearchValue = useRef<boolean>(false);

  const { open, toggleOpenState } = useOpenState(options.length);

  const [lastSearchValue, setLastSearchValue] = useState('');

  const [search, setSearch] = useState('');
  const [isSelected, setSelected] = useState(true);

  useEffect(() => {
    if (!value) {
      setSearch('');
      setLastSearchValue('');
    }
  }, [value]);

  useEffect(() => {
    if (!withInitialSetSearchValue) return;
    if (isInitialSetSearchValue.current) return;

    if (search) return;
    if (!value) return;
    if (!options.length) return;

    const foundOption = options.find((a) => String(a.value) === String(value));
    if (!foundOption) return;

    const newValue = String(foundOption.name);
    isInitialSetSearchValue.current = true;

    setSearch(newValue);
    setLastSearchValue(newValue);
  }, [value, options, withInitialSetSearchValue, search]);

  useEffect(() => {
    if (inputValue !== undefined) setSearch(inputValue);
  }, [inputValue]);

  const onChangeSearch = useCallback(
    (e) => {
      const value = e.target.value;

      setSearch(value);
      if (onInput) {
        onInput(value);
      }
    },
    [setSearch, onInput]
  );

  const getLabel = useCallback(() => {
    if (Array.isArray(value) && (value as string[]).length) {
      const result: ReactText[] = [];
      (value as ReactText[]).forEach((val) => {
        const option = options.find((opt) => String(opt.value) === String(val));
        if (option) result.push(option.name);
      });
      return result.join(', ');
    }
    if (!Array.isArray(value) && value) {
      const option = options.find((opt) => String(opt.value) === String(value));
      if (option) return option.name;
    }
    return label || '';
  }, [value, label, options]);

  const handleItemClick = useCallback(
    (opt: DropdownOption) => {
      onChange(
        multiple
          ? updateArray((value as ReactText[]) || [], opt.value)
          : String(opt.value),
        opt
      );

      if (!multiple) {
        setSelected(true);

        let value = '';
        if (isSearchableForApi) {
          value = opt.name as string;
          inputRef.current?.blur();
        }

        setSearch(value);
        setLastSearchValue(value);

        toggleOpenState();
      }

      if (!isSearchableForApi) {
        if (searchable && inputRef.current) {
          inputRef.current.focus();
        }
      }
    },
    [multiple, onChange, searchable, toggleOpenState, isSearchableForApi, value]
  );

  const handleClear = useCallback(
    (e: any) => {
      e.stopPropagation();
      onChange(multiple ? [] : '');
      setLastSearchValue('');
      setSearch('');
      if (onClear) {
        onClear();
      }
    },
    [multiple, onChange, onClear]
  );

  const filteredOptions = useMemo(() => {
    const items: DropdownOption[] = [];

    if (firstActives) {
      const otherItems: DropdownOption[] = [];

      if (multiple) {
        if ((value as ReactText[]).length) {
          options.forEach((opt) => {
            for (let i = 0; i < (value as ReactText[]).length; i++) {
              if (String(opt.value) === String((value as ReactText[])[i])) {
                items.push(opt);
                return;
              }
            }

            otherItems.push(opt);
          });
        } else {
          otherItems.push(...options);
        }

        items.push(...otherItems);
      } else {
        options.forEach((opt) => {
          if (String(opt.value) === String(value)) {
            items.push(opt);
          } else {
            otherItems.push(opt);
          }
        });

        items.push(...otherItems);
      }
    } else {
      items.push(...options);
    }

    return items.filter((opt) =>
      String(opt.name).toLowerCase().includes(search.toLowerCase())
    );
  }, [firstActives, multiple, options, search, value]);

  const renderIcon = useCallback(() => {
    // if (open && searchable) {
    //   return (
    //     <SearchIcon
    //       className='Dropdown__icon Dropdown__search'
    //       color='inherit'
    //       size='inherit'
    //     />
    //   );
    // }
    if (clearable && value && String(value).length) {
      return (
        <Icon
          onClick={handleClear}
          src={closeIcon}
          color='inherit'
          size='inherit'
          className='Dropdown__icon Dropdown__clear'
        />
      );
    }
    return (
      <Icon
        src={miniChevronDownIcon}
        className={classNames(
          'Dropdown__icon Dropdown__chevron',
          open && 'Dropdown__chevron--inverted'
        )}
        color='inherit'
        size='inherit'
      />
    );
  }, [clearable, handleClear, open, value]);

  // const onSearchFocus = useCallback(() => {
  //   if (isSearchableForApi) {
  //     setFocusState(true);
  //   }
  // }, [isSearchableForApi, setFocusState]);

  // const onSearchBlur = useCallback(() => {
  //   console.log('blur');

  //   if (isSearchableForApi && search !== lastSearchValue) {
  //     setSearch(lastSearchValue);
  //   }
  // }, [lastSearchValue, search, isSearchableForApi]);

  const inputOrLabel = useMemo(() => {
    if (searchable && (isSearchableForApi || open)) {
      return (
        <InputBase
          value={search}
          onChange={onChangeSearch}
          placeholder={label}
          autoFocus={!isSearchableForApi}
          // onFocus={onSearchFocus}
          // onBlur={onSearchBlur}
          className='Dropdown__input'
          ref={inputRef}
        />
      );
    }

    return (
      <Typography
        variant='subtitle3'
        size={14}
        color={String(value).length ? 'main' : 'second'}
      >
        {getLabel()}
      </Typography>
    );
  }, [
    open,
    label,
    value,
    search,
    searchable,
    isSearchableForApi,
    getLabel,
    // onSearchBlur,
    // onSearchFocus,
    onChangeSearch
  ]);

  const notFoundOptions: DropdownOption[] = useMemo(
    () => [{ value: 'notFound', name: notFoundOptionText, isDisabled: true }],
    [notFoundOptionText]
  );

  const fetchingOptions: DropdownOption[] = useMemo(
    () => [{ value: 'isFetching', name: fetchingOptionText, isDisabled: true }],
    [fetchingOptionText]
  );

  const emptyOptions: DropdownOption[] = useMemo(
    () => [{ value: 'isEmpty', name: emptyOptionText, isDisabled: true }],
    [emptyOptionText]
  );

  const items = useMemo(() => {
    if (isSearchableForApi) {
      if (!search && !options) return emptyOptions;
      if (isFetchingForApi) return fetchingOptions;
      if (!options.length) return notFoundOptions;
      return options;
    }

    if (searchable) {
      return filteredOptions;
    }

    return options;
  }, [
    isSearchableForApi,
    searchable,
    options,
    search,
    isFetchingForApi,
    filteredOptions,
    notFoundOptions,
    fetchingOptions,
    emptyOptions
  ]);

  const onClickControl = useCallback(
    (isForceClose?: boolean) => () => {
      if (isForceClose) {
        setSearch(lastSearchValue);
      }

      setSelected(Boolean(isForceClose));
      toggleOpenState();
    },
    [toggleOpenState, lastSearchValue]
  );

  const isOpen = React.useMemo(() => {
    const isOpenForSearchableApi = isSearchableForApi && !isSelected;
    return Boolean(open || isOpenForSearchableApi);
  }, [isSearchableForApi, isSelected, open]);

  const rendererControl = useCallback(() => {
    const controlClassName = 'Dropdown__control';

    const Icon = renderIcon();

    if (renderControl) {
      return renderControl({
        className: controlClassName,
        onClick: onClickControl(),
        label: getLabel(),
        Icon
      });
    }

    return (
      <div className={controlClassName} onClick={onClickControl()}>
        {inputOrLabel}
        {Icon}
      </div>
    );
  }, [renderIcon, renderControl, onClickControl, inputOrLabel, getLabel]);

  return (
    <DropdownContainer fullWidth={fullWidth}>
      {formLabel && (
        <FormElementLabel validate={validate}>{formLabel}</FormElementLabel>
      )}
      <Styled
        className={classNames('Dropdown', className)}
        fullWidth={fullWidth}
        ref={anchorRef}
        style={style}
        onClick={onClick}
      >
        {rendererControl()}
        <DropdownList
          anchorEl={anchorRef.current}
          items={items}
          multiple={multiple}
          open={isOpen}
          value={value}
          virtualized={virtualized}
          onChange={handleItemClick}
          onClose={onClickControl(true)}
          renderOption={renderOption}
        />
      </Styled>
    </DropdownContainer>
  );
}

export default Dropdown;
