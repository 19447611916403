import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonFilterProps } from '../types/utils';
import { StyledDropdown } from '../pages/StatisticsPage/styled';

const StatisticPageType = ({ value, onChange }: CommonFilterProps) => {
  const { t } = useTranslation();

  const typeOptions = useMemo(
    () => [
      { value: 'common', name: t('reportTypes.common') },
      { value: 'offer', name: t('reportTypes.offer') },
      { value: 'goal', name: t('reportTypes.goal') },
      { value: 'day', name: t('reportTypes.day') },
      { value: 'country', name: t('reportTypes.country') },
      { value: 'aff_param5', name: t('reportTypes.employeeId') },
      { value: 'aff_param2', name: t('reportTypes.domain') },
      { value: 'sub_id1', name: 'Sub id 1' },
      { value: 'sub_id2', name: 'Sub id 2' },
      { value: 'sub_id3', name: 'Sub id 3' },
      { value: 'sub_id4', name: 'Sub id 4' },
      { value: 'sub_id5', name: 'Sub id 5' }
    ],
    [t]
  );

  return (
    <StyledDropdown value={value} onChange={onChange} options={typeOptions} />
  );
};

export default StatisticPageType;
