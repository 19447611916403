import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import PepperWithFlameIcon from '../PepperWithFlameIcon';
import PepperWithoutFlameIcon from '../PepperWithoutFlameIcon';

const AnimationBlock = styled.div<{ opacity?: number }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  opacity: ${(p) => `${p.opacity || 1}`};

  display: flex;
  overflow: hidden;
`;

const AnimationColumn = styled.div<{ sizeX: number; offsetY: number }>`
  width: ${(props) => `${props.sizeX}px`};
  margin-top: ${(props) => `${props.offsetY}px`};
  height: ${(props) => `calc(100% - ${props.offsetY * 2}px)`};

  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled.div<{ sizeY: number }>`
  opacity: 0.6;

  width: 100%;
  height: ${(props) => `${props.sizeY}px`};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconSize = 100;

const isEven = (numb: number) => numb % 2 === 0;

// TODO: почистить компонент
// TODO: завязаться на resize

interface AnimationProps {
  opacity?: number;
  withoutAnimation?: boolean;
}

function useForceUpdate(): [number, () => void] {
  const [value, setValue] = useState(0);

  const forceUpdate = React.useCallback(() => {
    setValue((prevState) => prevState + 1);
  }, []);

  return [value, forceUpdate];
}

const Animation = (props: AnimationProps) => {
  const [force, forceUpdate] = useForceUpdate();

  React.useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    return () => window.removeEventListener('resize', forceUpdate);
  }, [forceUpdate, props]);

  const { withoutAnimation } = props;

  const [changedColumn, setChangedColumn] = useState(withoutAnimation ? 0 : 3);
  const [isReverse, setReverse] = useState(false);
  const [stage, setStage] = useState(0);

  const columnsCount = useMemo(() => {
    const { innerWidth } = window;
    return innerWidth > 1400 ? 7 : 5;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force]);

  const size = useMemo(() => {
    const { innerWidth, innerHeight } = window;

    return {
      x: innerWidth / columnsCount,
      y: innerHeight / 2
    };
  }, [columnsCount]);

  const Icon1 = useCallback(
    (key) => {
      return (
        <StyledIcon sizeY={size.y} key={key}>
          <PepperWithoutFlameIcon />
        </StyledIcon>
      );
    },
    [size]
  );

  const Icon2 = useCallback(
    (key) => {
      return (
        <StyledIcon sizeY={size.y} key={key}>
          <PepperWithFlameIcon
            isDark
            size={iconSize}
            stage={stage}
            color='initial'
          />
        </StyledIcon>
      );
    },
    [size, stage]
  );

  const columns = useMemo(() => {
    const columns = [];
    const { innerHeight } = window;

    for (let i = 0; i < columnsCount; i++) {
      let height = 0;
      const array = [];

      if (isEven(columns.length - 1)) {
        height -= size.y;
      }

      while (height < innerHeight) {
        const conditionForWithoutAnimation =
          changedColumn <= columns.length && columns.length % 2 === 0;

        const is = withoutAnimation
          ? conditionForWithoutAnimation
          : changedColumn <= columns.length;

        let icon: (key: string) => JSX.Element = is ? Icon2 : Icon1;
        if (isReverse) icon = is ? Icon1 : Icon2;

        array.push(icon);
        height += size.y;
      }

      columns.push(array);
    }

    return columns;
  }, [
    size,
    Icon1,
    Icon2,
    isReverse,
    columnsCount,
    changedColumn,
    withoutAnimation
  ]);

  React.useEffect(() => {
    if (!props.withoutAnimation) {
      setTimeout(() => {
        const changed = changedColumn >= columnsCount ? 0 : changedColumn + 1;
        if (changed === 0) setReverse(!isReverse);
        setChangedColumn(changed);
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedColumn, props, columnsCount]);

  React.useEffect(() => {
    if (!props.withoutAnimation) {
      setTimeout(() => {
        const newStage = stage + 1 > 2 ? 0 : stage + 1;
        setStage(newStage);
      }, 1000 || 225);
    }
  }, [stage, props]);

  return (
    <AnimationBlock opacity={props.opacity}>
      {columns.map((a, c) => (
        <AnimationColumn
          key={`column-${c}-${a.length}`}
          offsetY={isEven(c) ? 0 : -200}
          sizeX={size.x}
        >
          {a.map((icon, i) => {
            return icon(`column-${c}-${i}`);
          })}
        </AnimationColumn>
      ))}
    </AnimationBlock>
  );
};

export default Animation;
