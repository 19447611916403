import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiUsersGet,
  ApiUsersGetResponse,
  ApiUsersIdGetResponse,
  ApiUsersPatch,
  ApiUsersPost,
  ApiUsersPostResponse
} from './../types/api/employees';

class EmployeesApi {
  static getEmployees(params: ApiUsersGet): AxiosPromise<ApiUsersGetResponse> {
    return Api.call('/users', { params });
  }

  static getEmployee(id: string): AxiosPromise<ApiUsersIdGetResponse> {
    return Api.call(`/users${id}`);
  }

  static createEmployee(
    data: ApiUsersPost
  ): AxiosPromise<ApiUsersPostResponse> {
    return Api.call(`/users`, { data, method: 'POST' });
  }

  static updateEmployee(
    id: number,
    data: ApiUsersPatch
  ): AxiosPromise<unknown> {
    return Api.call(`/users/${id}`, { data, method: 'PATCH' });
  }

  static deleteEmployee(id: number): AxiosPromise {
    return Api.call(`/users/${id}`, { method: 'DELETE' });
  }
}

export default EmployeesApi;
