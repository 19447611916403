import Api from './index';
import { AxiosPromise } from 'axios';
import {
  ApiOffersGet,
  ApiOfferGetResponse,
  ApiOffersGetResponse,
  ApiOffersTagsResponse,
  ApiOffersGoalTypesResponse,
  ApiOffersCountriesResponse,
  ApiOffersCountriesGet
} from '../types/api/offers';

class OffersApi {
  static getOffers(params?: ApiOffersGet): AxiosPromise<ApiOffersGetResponse> {
    return Api.call('/offers', { params });
  }

  static getOffersTags(): AxiosPromise<ApiOffersTagsResponse> {
    return Api.call('/offers/tags');
  }

  static getOffersGoalTypes(): AxiosPromise<ApiOffersGoalTypesResponse> {
    return Api.call('/offers/goal_types');
  }

  static getOffersCountries(
    params?: ApiOffersCountriesGet
  ): AxiosPromise<ApiOffersCountriesResponse> {
    return Api.call('/offers/countries', { params });
  }

  static getOffer(id: string): AxiosPromise<ApiOfferGetResponse> {
    return Api.call(`/offers/${id}`);
  }

  static gettingOfferAccess(offerId: string | number) {
    return Api.call('/offers/accesses', {
      method: 'POST',
      data: { offerId }
    });
  }
}

export default OffersApi;
