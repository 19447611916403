import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from '../../resources/functions';
import { Languages } from '../../types';

import en from './en.json';
import ru from './ru.json';
import ua from './ua.json';

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    resources: { ru, en, ua },
    lng: getLanguage(Languages.en),

    interpolation: {
      escapeValue: false
    }
  });
};

export default i18n;
