import React from 'react';
import styled, { css } from 'styled-components';
import {
  color,
  isFullWidth,
  transition
} from '../../bit/components/utils/utils';
import { SwitcherIndicatorProps, SwitcherProps } from './interface';
import FormElementLabel from '../../bit/components/form-element-label';

const SwitcherContainer = styled.div<{
  isRow?: boolean;
  fullWidth?: boolean;
  isReadonly?: boolean;
}>`
  display: flex;
  flex-direction: ${(p) => (p.isRow ? 'row-reverse' : 'column')};
  justify-content: ${(p) => (p.isRow ? 'flex-end' : 'flex-start')};

  ${isFullWidth};

  ${({ isReadonly }) => {
    if (isReadonly)
      return css`
        & > div {
          cursor: not-allowed;
          opacity: 0.4;
        }
      `;
  }};

  ${(p) => {
    if (p.isRow)
      return css`
        & > * {
          margin-bottom: 0px;
        }

        & > * + * {
          margin-right: 16px;
        }
      `;
  }};
`;

const SwitcherIndicator = styled.div`
  position: absolute;
  display: inline-flex;
  left: 2px;
  top: 2px;
  bottom: 2px;
  width: 18px;
  height: 18px;
  background-color: ${color('background.darkWhite')};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
    0 3px 1px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  ${transition({ name: 'left' })};
`;

const SwitcherIndicatorContainer = styled.div<SwitcherIndicatorProps>`
  flex: none;
  position: relative;
  display: inline-flex;
  height: 22px;
  width: 40px;
  background-color: ${(props) => {
    if (props.active) {
      return props.theme.palette.primary.main;
    }
    return props.theme.palette.border.main;
  }};
  border-radius: 36px;
  ${transition({ name: 'background-color' })};
  cursor: pointer;
  user-select: none;

  ${(props) => {
    if (props.active) {
      return css`
        ${SwitcherIndicator} {
          left: calc(100% - 20px);
        }
      `;
    }
  }}
`;

const Switcher = (props: SwitcherProps) => {
  const {
    label,
    validate,
    active,
    onChange,
    isRow,
    isReadonly = false,
    ...otherProps
  } = props;

  const handleClick = React.useCallback(() => {
    if (isReadonly) return;
    onChange();
  }, [isReadonly, onChange]);

  return (
    <SwitcherContainer isRow={isRow} isReadonly={isReadonly} {...otherProps}>
      {label && (
        <FormElementLabel validate={validate}>{label}</FormElementLabel>
      )}
      <SwitcherIndicatorContainer active={active} onClick={handleClick}>
        <SwitcherIndicator />
      </SwitcherIndicatorContainer>
    </SwitcherContainer>
  );
};

export default Switcher;
