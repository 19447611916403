import { AppThunk, RootState } from '../store';
import BillingApi from '../../api/billing';
import {
  BillingsBalance,
  BillingsDetails,
  BillingsPayment
} from '../../types/billings';
import { createSlice } from '@reduxjs/toolkit';
import {
  mapBillingsBalance,
  mapBillingsDetails,
  mapBillingsPayments
} from '../../maps/billingsMap';
import { ApiGetBillings } from '../../types/api/billings';

interface BillingsState {
  balance?: BillingsBalance;
  details: BillingsDetails[];
  billings: any[];
  payments: BillingsPayment[];
}

const initialState: BillingsState = {
  details: [],
  billings: [],
  payments: []
};

const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    setBalance: (state, action) => {
      state.balance = mapBillingsBalance(action.payload.data || {});
    },
    setDetails: (state, action) => {
      state.details = mapBillingsDetails(action.payload.data);
    },
    setPayments: (state, action) => {
      state.payments = mapBillingsPayments(action.payload.data || []);
    }
  }
});

const { setDetails, setBalance, setPayments } = billingsSlice.actions;

export const fetchBillingsBalance = (): AppThunk => async (dispatch) => {
  try {
    const response = await BillingApi.getBillingsBalance();
    dispatch(setBalance(response.data));
  } catch (error) {
    BillingApi.handleDefaultError(error);
  }
};

export const fetchBillings = (params?: ApiGetBillings): AppThunk => async (
  dispatch
) => {
  try {
    const response = await BillingApi.getBillings(params);
    dispatch(setPayments(response.data));
  } catch (error) {
    BillingApi.handleDefaultError(error);
  }
};

export const fetchBillingsDetails = (): AppThunk => async (dispatch) => {
  try {
    const response = await BillingApi.getBillingsDetails();
    dispatch(setDetails(response.data));
  } catch (error) {
    BillingApi.handleDefaultError(error);
  }
};

export const selectBillings = (state: RootState) => state.billings;

export const selectBillingPayments = (state: RootState) =>
  state.billings.payments;

export default billingsSlice.reducer;
