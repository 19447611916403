import React from 'react';
import styled from 'styled-components';
import { LabelSide, CheckboxProps } from './interface';
import checkboxIcon from './checkbox.svg';
import { color, transition } from '../utils/utils';
import Typography from '../typography';
import Icon from '../icon';

const Container = styled.div`
  flex: none;
  position: relative;
  display: inline-flex;
`;

const CheckMark = styled.div<{ checked?: boolean }>`
  flex: none;
  display: inline-flex;
  padding: 4px;
  background-color: ${(props) =>
    props.checked ? props.theme.palette.primary.main : 'transparent'};
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.palette.primary.main
        : props.theme.palette.border.main};
  border-radius: 4px;
  ${transition({ name: 'border' }, { name: 'background-color' })};
  z-index: 1;
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 14px;

  &:hover {
    ${CheckMark} {
      border-color: ${color('primary.main')};
    }
  }
`;

const LabelValue: any = styled(Typography)<{
  labelSide: LabelSide;
}>`
  margin-${(props) =>
    (props.labelSide === 'right' && 'left') ||
    (props.labelSide === 'left' && 'right')}: 10px;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;

const StyledIcon = styled(Icon)<{ checked?: boolean }>`
  color: ${color('common.white')};
  opacity: ${(props) => (props.checked ? 1 : 0)};
  ${transition({ name: 'opacity' })};
`;

const Checkbox = ({
  className,
  label,
  labelSide = 'right',
  style,
  ...props
}: CheckboxProps) => {
  return (
    <Container className={className} style={style}>
      <Label>
        {label && labelSide === 'left' && (
          <LabelValue variant='subtitle3' labelSide='left'>
            {label}
          </LabelValue>
        )}
        <StyledInput {...props} type='checkbox' />
        <CheckMark checked={props.checked}>
          <StyledIcon
            src={checkboxIcon}
            checked={props.checked}
            color='inherit'
            size={12}
          />
        </CheckMark>
        {label && labelSide === 'right' && (
          <LabelValue variant='subtitle3' labelSide='right'>
            {label}
          </LabelValue>
        )}
      </Label>
    </Container>
  );
};

export default Checkbox;
