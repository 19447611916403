import React, { Ref } from 'react';
import styled from 'styled-components';
import InputBase from '../input-base';
import { InputProps } from './interface';
import { isFullWidth } from '../utils/utils';
import FormElementLabel from '../form-element-label';

const StyledInput = styled.div<{ fullWidth?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  ${isFullWidth};
`;

const InputWithRef = (props: InputProps, ref: Ref<HTMLInputElement>) => {
  const {
    id,
    name,
    type,
    label,
    value,
    style,
    onChange,
    required,
    validate,
    className,
    multiline,
    fullWidth,
    inputProps,
    placeholder,
    ...otherProps
  } = props;

  return (
    <StyledInput fullWidth={fullWidth} className={className} style={style}>
      {label && (
        <FormElementLabel validate={validate} id={id}>
          {label}
        </FormElementLabel>
      )}
      <InputBase
        {...otherProps}
        {...inputProps}
        validate={validate}
        ref={ref}
        fullWidth={fullWidth}
        multiline={multiline}
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </StyledInput>
  );
};

const Input = React.forwardRef(InputWithRef) as typeof InputWithRef;

export default Input;
