import React from 'react';
import styled from 'styled-components';

import Animation from '../Animation';
import { PageProps, ContentWrapperProps } from './interface';
import media from '../../resources/media';

const ContentWrapper = styled.div<ContentWrapperProps>`
  z-index: 10;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: ${(props) => {
    return props.isRow ? 'row' : 'column';
  }};
  align-items: ${(props) => {
    if (props.isHorizontalCenter && !props.isRow) return 'center';
    if (props.isVerticalCenter && props.isRow) return 'center';
    return 'flex-start';
  }};
  justify-content: ${(props) => {
    if (props.isHorizontalCenter && props.isRow) return 'center';
    if (props.isVerticalCenter && !props.isRow) return 'center';
    return 'flex-start';
  }};
`;

const PageWrapper = styled.div<PageProps>`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  position: relative;
  opacity: ${(props) => {
    return props.opacity ? Math.min(props.opacity, 1) : 1;
  }};
  padding-left: ${(p) =>
    (!p.isFullWidth && p.theme.components.sidebar.width) || 0}px;
  background: ${(props) => {
    return props.isDarkBackground
      ? props.theme.palette.background.dark
      : props.theme.components.page.background;
  }};

  @media ${media.tablet} {
    padding-left: 0;
  }
`;

const Page = (props: PageProps) => {
  return (
    <PageWrapper
      opacity={props.opacity}
      isFullWidth={props.isFullWidth}
      isDarkBackground={props.isDarkBackground}
    >
      <ContentWrapper
        isRow={props.isRow}
        isVerticalCenter={props.isVerticalCenter}
        isHorizontalCenter={props.isHorizontalCenter}
      >
        {props.children}
      </ContentWrapper>
      {!props.withoutBackgroundPattern && (
        <Animation withoutAnimation opacity={0.1} />
      )}
    </PageWrapper>
  );
};

export default Page;
