import React, { useCallback } from 'react';
import { List, ListRowProps, ListRowRenderer } from 'react-virtualized';
import styled, { createGlobalStyle, css } from 'styled-components';
import { color } from '../utils/utils';
import Typography from '../typography';
import Popper from '@bit/mui-org.material-ui.popper';
import ClickAwayListener from '@bit/mui-org.material-ui.click-away-listener';
import { DropdownListProps, DropdownOption } from './interface';
import Checkbox from '../checkbox';

const StyledItem = styled.div<{ isDisabled?: boolean }>`
  ${(p) => {
    if (p.isDisabled)
      return css`
        pointer-events: none;
      `;
  }}
`;

const GlobalStyle = createGlobalStyle`
  .DropdownPopper {
    z-index: 100000;
  }
  
  .DropdownList {
    outline: none;
    max-height: 400px;
    background-color: ${color('background.main')};
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.08);
    border-radius: ${(props) => props.theme.borderRadius};
    overflow-y: auto;

    &__item {
      flex: none;
      display: flex;
      align-items: center;
      padding: 16px;
      color: ${color('text.main')};
      cursor: pointer;

      & + .DropdownList__item {
        border-top: 1px solid ${color('border.main')};
      }

      &:hover {
        background-color: ${(props) =>
          props.theme.components.dropdownList.hoverBackground};
      }
    }

    &__checkbox {
      margin-right: 10px;
      pointer-events: none;
    }
  }
`;

// GlobalStyle.defaultProps = {
//   theme
// };

const DropdownList = (props: DropdownListProps) => {
  const {
    anchorEl,
    open,
    onClose,
    virtualized,
    items,
    onChange,
    multiple,
    value,
    renderOption
  } = props;

  const handleItemClick = useCallback(
    (option: DropdownOption) => () => {
      onChange(option);
    },
    [onChange]
  );

  const checkOption = useCallback(
    (opt: DropdownOption) => {
      if (Array.isArray(value)) {
        return !!value.find((val) => String(val) === String(opt.value));
      }
      return !!(value && String(value) === String(opt.value));
    },
    [value]
  );

  const rowRenderer = useCallback<ListRowRenderer>(
    ({ key, index, style: itemStyle }) => {
      const opt = items[index];

      const className = 'DropdownList__item';

      if (renderOption) {
        return renderOption({
          onClick: handleItemClick(opt),
          style: itemStyle,
          option: opt,
          className,
          key
        });
      }

      return (
        <StyledItem
          isDisabled={opt.isDisabled}
          className={className}
          key={key}
          style={itemStyle}
          onClick={handleItemClick(opt)}
        >
          {multiple && (
            <Checkbox
              readOnly
              checked={checkOption(opt)}
              className='DropdownList__checkbox'
            />
          )}
          <Typography variant='subtitle3' size={14} color='main'>
            {opt.name}
          </Typography>
        </StyledItem>
      );
    },
    [checkOption, handleItemClick, items, multiple, renderOption]
  );

  return (
    <Popper
      anchorEl={anchorEl}
      className='DropdownPopper'
      open={open}
      placement='bottom-start'
    >
      <GlobalStyle />
      <ClickAwayListener mouseEvent='onMouseUp' onClickAway={onClose}>
        {virtualized ? (
          <List
            className='DropdownList'
            height={Math.min(400, items.length * 50)}
            rowCount={items.length}
            rowHeight={50}
            rowRenderer={rowRenderer}
            width={anchorEl?.offsetWidth || 0}
          />
        ) : (
          <div
            className='DropdownList'
            style={{ minWidth: anchorEl?.offsetWidth }}
          >
            {items.map((item, index) =>
              rowRenderer({
                index,
                key: String(item.value)
              } as ListRowProps)
            )}
          </div>
        )}
      </ClickAwayListener>
    </Popper>
  );
};

export default DropdownList;
