import React, { useCallback, useRef, useState } from 'react';
import Page from '../../components/Page';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import Grid from '../../components/Grid';
import Block from '../../components/Block';
import {
  StatsRow,
  StatsBlockTitle,
  StatsBlockHeader,
  StatsBlockFilters,
  StatsBlockInfo,
  StatsOffersBlock,
  StyledStatInfo
} from './styled';
import {
  StringParam,
  useQueryParams,
  NumberParam,
  withDefault
} from 'use-query-params';
import DatePicker from '../../components/DatePicker';
import Button from '../../bit/components/button';
import { getDate, orNull } from '../../resources/functions';
import ViewAllLink from '../../components/ViewAllLink';
import useRoutes from '../../hooks/useRoutes';
import OffersTable from './OffersTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  EMPTY_STATISTIC,
  fetchStatistics,
  selectStatistics
} from '../../redux/slices/statisticsSlice';
import { Statistic } from '../../types/statistics';
import { useEffect } from 'react';
import { fetchOffers, selectOffers } from '../../redux/slices/offersSlice';
import BalanceBlock from './BalanceBlock';
import InfinityScroll from '../../components/InfinityScroll';
import { DEFAULT_LIMIT } from '../../resources/constants';
import { ApiOffersGet } from '../../types/api/offers';
import useMount from '../../hooks/useMount';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

const MainPage = () => {
  const { t } = useTranslation();
  const { getRoutePath } = useRoutes();

  const dispatch = useDispatch();
  const statistics = useSelector(selectStatistics);
  const { meta, items } = useSelector(selectOffers);

  const [isFirstLoading, setFirstLoadingState] = useState(true);
  const isFirstLoadingRef = useRef(true);

  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
    page: withDefault(NumberParam, 1)
  });

  const { page } = query;

  const fetchNextItems = useCallback(() => {
    setQuery((prevState) => ({ page: Number(prevState.page) + 1 }));
  }, [setQuery]);

  const getOffers = useCallback(
    async (
      page: number,
      limit: number,

      isOverWrite?: boolean
    ) => {
      const params: ApiOffersGet = { page, limit };
      await dispatch(fetchOffers(params, page !== 1, isOverWrite));

      setFirstLoadingState(false);
      isFirstLoadingRef.current = false;
    },
    [dispatch]
  );

  useMount(() => {
    (async () => {
      const isOverWrite = page > 1;
      const limit = isOverWrite ? DEFAULT_LIMIT * page : DEFAULT_LIMIT;

      await getOffers(1, limit, isOverWrite);
    })();
  });

  useDidUpdateEffect(() => {
    if (isFirstLoadingRef.current) return;
    getOffers(page, DEFAULT_LIMIT);
  }, [getOffers, page]);

  const [startDate, setStartDate] = React.useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());

  const handleChangeDatePicker = React.useCallback(([start, end]) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const applyFilters = React.useCallback(() => {
    setQuery({
      from: startDate ? getDate(startDate) : undefined,
      to: endDate ? getDate(endDate) : undefined
    });
  }, [endDate, setQuery, startDate]);

  useEffect(() => {
    const to = getDate(new Date(query.to || new Date()));
    const from = getDate(
      new Date(
        query.from || new Date(new Date().setMonth(new Date().getMonth() - 1))
      )
    );

    dispatch(fetchStatistics({ to, from }));
  }, [query, dispatch]);

  const stats = React.useMemo(() => {
    const result: Statistic = JSON.parse(JSON.stringify(EMPTY_STATISTIC));

    statistics.rows.forEach((a) => {
      result.cpa += a.cpa;
      result.cpl += a.cpl;
      result.upsale += a.upsale;
      result.clicks.total += a.clicks.total;
      result.payout.total += a.payout.total;
      result.clicks.unique += a.clicks.unique;
      result.payout.approved += a.payout.approved;
      result.payout.revshare += a.payout.revshare;
    });

    return result;
  }, [statistics]);

  return (
    <Page>
      <Header title={t('sidebar.main')} />
      <Grid>
        <StatsRow>
          <Block>
            <StatsBlockHeader>
              <StatsBlockTitle title={t('main.yourStats')} />
              <StatsBlockFilters>
                <DatePicker
                  endDate={endDate}
                  startDate={startDate}
                  onChange={handleChangeDatePicker}
                />
                <Button size='middle' onClick={applyFilters}>
                  {t('common.apply')}
                </Button>
              </StatsBlockFilters>
            </StatsBlockHeader>
            <StatsBlockInfo>
              <div>
                <StyledStatInfo
                  title={t('main.registration')}
                  value={orNull(stats.cpl)}
                />
                <StyledStatInfo
                  title={t('main.registrationRation')}
                  value={`${orNull(
                    (stats.cpl / stats.clicks.unique) * 100
                  ).toFixed(2)} %`}
                />
                <StyledStatInfo
                  title={t('statistics.payout')}
                  value={`${orNull(stats.payout.total)} $`}
                />
                <StyledStatInfo
                  title={t('main.newAccountsWithDeposits')}
                  value={orNull(stats.cpa)}
                />
                <StyledStatInfo
                  title={t('main.depositRation')}
                  value={`${orNull(
                    (stats.cpa / stats.clicks.unique) * 100
                  ).toFixed(2)} %`}
                />
                <StyledStatInfo
                  title='EPC'
                  value={`${orNull(
                    stats.payout.total / stats.clicks.unique
                  ).toFixed(2)} $`}
                />
              </div>
            </StatsBlockInfo>
          </Block>

          <BalanceBlock />
        </StatsRow>
        <StatsRow>
          <StatsOffersBlock disablePadding>
            <StatsBlockHeader addPadding>
              <StatsBlockTitle title={t('main.offersForPromotion')} />
              <ViewAllLink link={getRoutePath('offers')} />
            </StatsBlockHeader>

            {isFirstLoading ? (
              <OffersTable isLoading={isFirstLoading} />
            ) : (
              <InfinityScroll
                itemsCount={items.length}
                hasMore={meta.page < meta.pages}
                fetchNextItems={fetchNextItems}
              >
                <OffersTable />
              </InfinityScroll>
            )}
          </StatsOffersBlock>
        </StatsRow>
      </Grid>
    </Page>
  );
};

export default MainPage;
