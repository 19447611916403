import styled from 'styled-components';
import Dropdown from '../../components/Dropdown';
import Header from '../../components/Header';
import Tabs, { Tab } from '../../bit/components/tabs';
import media from '../../resources/media';

export const AdditionalHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: -8px;
  order: 1;

  > * {
    margin: 8px;
  }

  @media ${media.tablet} {
    margin-top: 8px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 180px;
`;

export const HeaderContainer = styled(Header)`
  padding-bottom: 0 !important;

  > div {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * + * {
    margin-left: 32px;
  }
`;

export const HeaderFiltration = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFiltrationRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px -12px 12px;

  > * {
    width: 240px;
    margin: 12px;

    @media ${media.tablet} {
      width: 100%;
    }
  }
`;

export const HeaderTabs = styled.div`
  height: 48px;
  display: flex;
  margin-top: 16px;
  align-items: center;
  align-self: flex-end;
`;

export const StyledTabContainer = styled(Tabs)`
  height: 100%;
  border: none;
  border-radius: 0;
`;

export const StyledTab = styled(Tab)<{ $isAutoWidth?: boolean }>`
  ${({ $isAutoWidth }) => $isAutoWidth && `flex: initial`}
`;
