import { useCallback, useState } from 'react';

const useOpenState = (optionsLength: number) => {
  const [open, setOpen] = useState(false);

  const toggleOpenState = useCallback(() => {
    if (optionsLength) {
      setOpen(!open);
    } else if (open) {
      setOpen(false);
    }
  }, [open, optionsLength]);

  return { open, toggleOpenState };
};

export default useOpenState;
