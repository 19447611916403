import React from 'react';

import { PaginationProps } from '../../bit/components/pagination/interface';
import VVUIPagination from '../../bit/components/pagination';
import styled from 'styled-components';

const StyledPagination = styled.div`
  ul li:not(.selected) a {
    background: transparent;
  }
`;

const Pagination = (props: PaginationProps) => {
  return (
    <StyledPagination>
      <VVUIPagination {...props} />
    </StyledPagination>
  );
};

export default Pagination;
