import React, { useMemo } from 'react';
import useRoutes from '../../hooks/useRoutes';
import { useTranslation } from 'react-i18next';

import {
  ErrorPageWrapper,
  StyledErrorPageButton,
  StyledErrorPageDescription,
  StyledErrorPageTitle
} from './ErrorPageStyled';
import { ErrorPageProps } from './ErrorPageTypes';

import Icon500 from '../../icons/Icon500';
import Icon404 from '../../icons/Icon404';
import { useHistory } from 'react-router-dom';

const ErrorPage = ({ resetErrorBoundary, title }: ErrorPageProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { routes } = useRoutes();

  const buttonProps = useMemo(() => {
    if (resetErrorBoundary) {
      return {
        onClick: resetErrorBoundary
      };
    }

    const routerPath = routes.main.path;

    return {
      onClick: () =>
        history.push(Array.isArray(routerPath) ? routerPath[0] : routerPath)
    };
  }, [history, resetErrorBoundary, routes.main.path]);

  const isError = Boolean(resetErrorBoundary);

  return (
    <ErrorPageWrapper>
      {isError ? <Icon500 /> : <Icon404 />}
      <StyledErrorPageTitle>
        {title ||
          t(
            isError ? 'errorPage.somethingWentWrong' : 'errorPage.pageNotFound'
          )}
      </StyledErrorPageTitle>
      <StyledErrorPageDescription>
        {t(
          isError
            ? 'errorPage.refreshPageOrTryAgainLater'
            : 'errorPage.returnToMain'
        )}
      </StyledErrorPageDescription>
      <StyledErrorPageButton {...buttonProps}>
        {t(isError ? 'errorPage.refreshPage' : 'errorPage.return')}
      </StyledErrorPageButton>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
