import React from 'react';
import { Props } from './interface';
import Modal from '../../bit/components/modal';
import ModalTitle from '../../components/ModalTitle';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';
import Typography from '../../components/Typography';
import InputCopy from '../../components/InputCopy';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`;

const StyledTable = styled(Table)`
  border-right: 1px solid ${color('border.main')};

  table {
    th {
      display: none;
    }

    td {
      border: 1px solid ${color('border.main')} !important;
    }
  }
`;

const SubTitle: typeof Typography = styled(Typography)`
  margin-top: 28px;
  color: ${color('text.themeMain')};
`;

const StyledInputCopy = styled(InputCopy)`
  margin-top: 24px;
`;

const params = [
  {
    param: 'aff_click_id',
    description:
      'Клик ID партнера. Уникальный ID отправляемый партнером с каждым кликом.'
  },
  { param: 'sub_id1', description: 'Sub ID 1 партнера.' },
  { param: 'sub_id2', description: 'Sub ID 2 партнера.' },
  { param: 'sub_id3', description: 'Sub ID 3 партнера.' },
  { param: 'sub_id4', description: 'Sub ID 4 партнера.' },
  { param: 'sub_id5', description: 'Sub ID 5 партнера.' },
  { param: 'aff_param1', description: 'Дополнительный параметр 1 партнера.' },
  { param: 'aff_param2', description: 'Дополнительный параметр 2 партнера.' },
  { param: 'aff_param3', description: 'Дополнительный параметр 3 партнера.' },
  { param: 'aff_param4', description: 'Дополнительный параметр 4 партнера.' },
  { param: 'aff_param5', description: 'Дополнительный параметр 5 партнера.' },
  {
    param: 'source_id',
    description:
      'Источник (площадка) партнера, который был добавлен предварительно (должен существовать и быть активным).'
  },
  {
    param: 'link_id',
    description:
      'ID альтернативной ссылки для оффера (должна существовать и быть активной).'
  },
  {
    param: 'deep_link',
    description:
      'Ссылка на внутреннюю страницу сайта рекламодателя. Возможность должна быть включена для оффера.'
  },
  {
    param: 'creative_id',
    description: 'ID креатива для оффера (должен существовать и быть активным).'
  },
  { param: 'idfa', description: 'Apple iOS идентификатор рекламодателя' },
  { param: 'gaid', description: 'Google Android идентификатор рекламодателя' }
];

const useExamples = [
  'https://pepperpartners.scaletrk.com/click?o=1&a=18&aff_click_id=a3p22snfbg53shfgtekt3s',
  'https://pepperpartners.scaletrk.com/click?o=1&a=18&sub_id1=Google&sub_id2=Ads',
  'https://pepperpartners.scaletrk.com/click?o=1&a=18&aff_param1=John&aff_param2=john@gmail.com',
  'https://pepperpartners.scaletrk.com/click?o=1&a=18&deep_link=https://brand.com/catalog/item/123'
];

const AdditionalParamsModal = (props: Props) => {
  const { open, onClose } = props;

  const { t } = useTranslation();

  const tableOptions = React.useMemo(
    () => [
      { dataKey: 'param', title: '' },
      { dataKey: 'description', title: '' }
    ],
    []
  );

  const tableRows = React.useMemo(
    () =>
      params.map((param) => ({
        key: param.param,
        param: (
          <Typography variant='subtitle2' size={16}>
            {param.param}
          </Typography>
        ),
        description: (
          <Typography variant='subtitle2' size={16}>
            {param.description}
          </Typography>
        )
      })),
    []
  );

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle>{t('offers.additionalParams')}</ModalTitle>
      <Container>
        <StyledTable options={tableOptions} rows={tableRows} />
        <SubTitle variant='h2'>{t('offers.useExamples')}</SubTitle>
        {useExamples.map((example) => (
          <StyledInputCopy multiline key={example} text={example} />
        ))}
      </Container>
    </Modal>
  );
};

export default AdditionalParamsModal;
