import { useMemo } from 'react';
import { debounce } from 'throttle-debounce';
import { useQueryParams, withDefault, StringParam } from 'use-query-params';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slices/userSlice';

export type AffiliateParam = 'affParam5' | 'affParam2';
export type ChangeAffiliateParamHandler = (
  param: AffiliateParam,
  value?: string
) => void;

const useStatisticPageSubIds = () => {
  const user = useSelector(selectUser);

  const [query, setQuery] = useQueryParams({
    subId1: withDefault(StringParam, ''),
    subId2: withDefault(StringParam, ''),
    subId3: withDefault(StringParam, ''),
    subId4: withDefault(StringParam, ''),
    subId5: withDefault(StringParam, ''),
    affParam5: withDefault(StringParam, ''),
    affParam2: withDefault(StringParam, '')
  });

  const onChangeSubId = useMemo(
    () =>
      debounce(500, false, (index: number, value?: string) => {
        const key = `subId${index + 1}`;
        setQuery({ [key]: value });
      }),
    [setQuery]
  );

  const onChangeAffiliateParam = useMemo(() => {
    const callback: ChangeAffiliateParamHandler = (param, value) => {
      setQuery({ [param]: value });
    };

    return debounce(500, false, callback);
  }, [setQuery]);

  return {
    ...query,
    onChangeSubId,
    onChangeAffiliateParam,

    affParam5: user.info?.creator ? query.affParam5 : undefined
  };
};

export default useStatisticPageSubIds;
