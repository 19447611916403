import {
  ApiUserInfoResponse,
  ApiUsersCompanyInfoResponse
} from '../types/api/users';
import { CompanyInfo, UserInfo } from '../types/users';
import { mapUserMessengers } from './userMessengersMap';

export const mapCompanyInfo = (
  data: ApiUsersCompanyInfoResponse['data']
): CompanyInfo => ({
  status: data.affiliate.status
});

export const mapUserInfo = (data: ApiUserInfoResponse): UserInfo => ({
  ...data,
  messengers: mapUserMessengers(data.messengers || {})
});
