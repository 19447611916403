/* eslint-disable prefer-rest-params */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const YANDEX_KEY = 71730703;
const GOOGLE_KEY = 'G-Y66KG82TPT';

const loadScript = (url: string, onload: () => void) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;

  script.onload = onload;

  document.head.append(script);
};

const Metrics = () => {
  const location = useLocation();

  const [isYMetricInit, setIsYMetricInit] = useState(false);
  const [isGTagInit, setIsGTagInit] = useState(false);

  useEffect(() => {
    const w = window as any;

    w.ym =
      w.ym ||
      function () {
        (w.ym.a = w.ym.a || []).push(arguments);
      };

    w.ym.l = Date.now();

    loadScript('https://mc.yandex.ru/metrika/tag.js', () => {
      w.ym(YANDEX_KEY, 'init', {
        defer: true,
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
      });

      setIsYMetricInit(true);
    });
  }, []);

  useEffect(() => {
    if (isYMetricInit) {
      const url = `${window.location.origin}${location.pathname}`;

      (window as any).ym(YANDEX_KEY, 'hit', url);
    }
  }, [isYMetricInit, location]);

  useEffect(() => {
    loadScript(
      `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_KEY}`,
      () => {
        const w = window as any;

        w.dataLayer = w.dataLayer || [];
        w.gtag =
          w.gtag ||
          function () {
            w.dataLayer.push(arguments);
          };
        w.gtag('js', new Date());
        w.gtag('config', 'G-Y66KG82TPT');

        setIsGTagInit(true);
      }
    );
  }, []);

  useEffect(() => {
    if (isGTagInit) {
      const url = `${window.location.origin}${location.pathname}`;

      (window as any).gtag('config', GOOGLE_KEY, { page_path: url });
    }
  }, [isGTagInit, location]);

  return null;
};

export default Metrics;
