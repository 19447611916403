import React, { useCallback, useEffect, useRef, useState } from 'react';
import Typography from '../Typography';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Props } from './interface';
import UserApi from '../../api/user';

const StyledTypography = styled(Typography)<{ disabled?: boolean }>`
  pointer-events: ${(props) => props.disabled && 'none'};
`;

const ResendCode = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const interval = useRef<any>();
  const [timer, setTimer] = useState(0);

  const handleClick = useCallback(() => {
    if (data && timer === 0) {
      setTimer(60);
      UserApi.register(data);
    }
  }, [data, timer]);

  useEffect(() => {
    if (timer !== 0 && !interval.current) {
      interval.current = setInterval(() => {
        setTimer((prevState) => {
          if (prevState - 1 === 0) {
            clearInterval(interval.current);
            interval.current = undefined;
          }
          return prevState - 1;
        });
      }, 1000);
    }
  }, [timer]);

  return (
    <div>
      <Typography color='first' variant='subtitle1' size={14}>
        {t('code.haventCode')}
      </Typography>{' '}
      <StyledTypography
        disabled={timer !== 0}
        isLinkStyles
        onClick={handleClick}
        color={'accent' as any}
        variant='subtitle1'
        size={14}
      >
        {t('code.repeatSend')}
        {timer !== 0 && ` (${timer})`}
      </StyledTypography>
    </div>
  );
};

export default ResendCode;
