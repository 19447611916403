import React from 'react';
import styled, { css } from 'styled-components';
import { DividerProps } from './interface';
import { color } from '../../bit/components/utils/utils';

const StyledDivider = styled.hr<DividerProps>`
  background-color: ${color('border.main')};
  border: none;

  ${(p) => {
    if (p.vertical) {
      return css`
        width: 1px;
        height: auto;
        align-self: stretch;
      `;
    } else {
      return css`
        width: 100%;
        height: 1px;
      `;
    }
  }}
`;

const Divider = (props: DividerProps) => {
  const { style, className, vertical } = props;

  return (
    <StyledDivider style={style} className={className} vertical={vertical} />
  );
};

export default Divider;
