import { useMemo } from 'react';
import useRoutes from './useRoutes';
import { matchPath, useLocation } from 'react-router-dom';

const useRouteFound = () => {
  const location = useLocation();

  const { routes } = useRoutes();

  const isFoundRoute = useMemo(() => {
    const keys = Object.keys(routes);

    for (let i = 0; i < keys.length; i++) {
      // @ts-ignore
      const route = routes[keys[i]];
      const match = matchPath(location.pathname, route);

      if (match) return true;
    }
  }, [location.pathname, routes]);

  return { isFoundRoute };
};

export default useRouteFound;
