import React from 'react';
import styled from 'styled-components';
import { color } from '../../bit/components/utils/utils';
import Typography from '../../bit/components/typography';
import { Props } from './interface';

const StyledGoals = styled.div`
  display: flex;
  flex-direction: column;
`;

const Goal = styled.div`
  display: inline-flex;
  align-items: center;
`;

const GoalType: typeof Typography = styled(Typography)`
  margin-right: 4px;
  font-size: 10px;
  color: ${color('status.4')};
`;

const GoalsPayout = (props: Props) => {
  const { goals } = props;

  return (
    <StyledGoals>
      {goals.map((goal) => (
        <Goal key={goal.id}>
          <GoalType>{goal.typeName}</GoalType>
          <Typography variant='subtitle2' size={14} color='main'>
            {goal.payout}
          </Typography>
        </Goal>
      ))}
    </StyledGoals>
  );
};

export default GoalsPayout;
