import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectSectionsCredentials } from '../redux/slices/userSlice';
import { SectionsCredentialsName } from '../types/employees';
import { allowedSections } from '../resources/constants';

const useSections = () => {
  const sectionsCredentials = useSelector(selectSectionsCredentials);

  const checkSection = useCallback(
    (
      sectionName: SectionsCredentialsName,
      conditionalType?: 'read' | 'change'
    ): boolean => {
      if (!sectionsCredentials) return false;

      if (conditionalType === 'read') {
        return sectionsCredentials[sectionName] === 1;
      }

      if (conditionalType === 'change') {
        return sectionsCredentials[sectionName] === 2;
      }

      return Boolean(sectionsCredentials[sectionName]);
    },
    [sectionsCredentials]
  );

  const sections = (sectionsCredentials
    ? Object.keys(sectionsCredentials).filter((el) =>
        allowedSections.includes(el)
      )
    : []) as SectionsCredentialsName[];

  return { sectionsCredentials, checkSection, sections };
};

export default useSections;
