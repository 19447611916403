import React from 'react';
import Table from '../../components/Table';
import { LandingsTableProps } from './interface';
import { useTranslation } from 'react-i18next';

const LandingsTable = (props: LandingsTableProps) => {
  const { landings } = props;

  const { t } = useTranslation();

  const options = React.useMemo(
    () => [
      { title: t('common.title'), dataKey: 'title' },
      // { title: t('common.type'), dataKey: 'type' },
      { title: 'URL', dataKey: 'url' }
    ],
    [t]
  );

  const rows = React.useMemo(
    () =>
      landings.map((landing) => ({
        key: landing.id,
        title: landing.title,
        // type: landing.type,
        url: landing.url
      })),
    [landings]
  );

  return <Table addBorderTop options={options} rows={rows} />;
};

export default LandingsTable;
