import styled from 'styled-components';

export const StyledNotification = styled('div')`
  top: 24px;
  right: 24px;

  position: fixed;
  z-index: 100000;

  display: flex;
  flex-direction: column;

  width: 300px;
`;
