import { OfferTargeting } from '../../types/offers';

export interface TargetingProps {
  targeting: OfferTargeting;
}

export interface TargetProps {
  icon: string;
  label: string;
}

export enum OffersTabs {
  all = 'all',
  top = 'top',
  new = 'new'
}
