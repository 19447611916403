import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from './interface';
import styled from 'styled-components';
import Avatar from '../Avatar';
import Typography from '../../bit/components/typography';
import Icon from '../../bit/components/icon';
import logInIcon from '../../images/sidebar/logIn.svg';
import { color } from '../../bit/components/utils/utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/userSlice';
import { getUserPosition } from '../../resources/functions';
import useWithTelegram from '../../hooks/useWithTelegram';

const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;

  .user-info {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    &-position {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .logout-icon {
    margin-left: auto;
    color: ${color('text.first')};
    cursor: pointer;
  }
`;

const StyledAvatar = styled(Avatar)`
  align-self: flex-start;
  user-select: none;
  margin-top: 3px;
`;

const UserInfo = (props: Props) => {
  const { onLogOut, ...otherProps } = props;

  const { t } = useTranslation();

  const { info } = useSelector(selectUser);

  const { withTelegramByUserLang } = useWithTelegram();

  const username = useMemo(() => {
    if (info) {
      const { telegram, whatsapp, skype, wechat } = info.messengers;
      return withTelegramByUserLang ? telegram : whatsapp || skype || wechat;
    }
    return '';
  }, [info, withTelegramByUserLang]);

  return (
    <StyledUserInfo {...otherProps}>
      <StyledAvatar />
      <div className='user-info'>
        <Typography variant='subtitle1' size={16} color='main'>
          {username}
        </Typography>
        <Typography className='user-info-position' color='second'>
          {getUserPosition(t, info?.type)}
        </Typography>
      </div>
      <Icon
        onClick={onLogOut}
        size={16}
        className='logout-icon'
        src={logInIcon}
        color='inherit'
      />
    </StyledUserInfo>
  );
};

export default UserInfo;
