import styled from 'styled-components';
import media from '../../resources/media';
import Divider from '../../components/Divider';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -8px -8px;

  &:first-child {
    margin-top: -8px;
  }

  @media ${media.tablet} {
    flex-direction: column;
    margin: 0;
  }

  > div {
    width: calc(50% - 16px);
    height: 100%;
    margin: 8px;

    @media ${media.tablet} {
      width: 100%;
      margin: 16px 0 0;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 28px 0;
`;
