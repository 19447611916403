import React from 'react';
import VVTypography from '../../bit/components/typography';
import { Props, StyledTypographyProps } from './interface';
import styled, { css } from 'styled-components';

const StyledTypography: any = styled(VVTypography)<StyledTypographyProps>`
  color: ${(props) => {
    if (props.forceColor === 'themeMain') {
      return props.theme.palette.primary.main;
    } else if (props.forceColor === 'inherit') {
      return 'inherit';
    } else if (props.forceColor) {
      return props.theme.palette.text[props.forceColor];
    }
  }};
  cursor: ${(props) => {
    if (props.isLinkStyles) {
      return 'pointer';
    }

    return props.cursor;
  }};
  ${(props) => {
    if (props.isLinkStyles) {
      return css`
        &:hover {
          text-decoration: underline;
        }
      `;
    }
  }};
`;

const Typography = (props: Props) => {
  const { color, children, cursor, isLinkStyles, ...otherProps } = props;

  return (
    <StyledTypography
      {...otherProps}
      color='inherit'
      forceColor={color}
      cursor={cursor}
      isLinkStyles={isLinkStyles}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
