import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import { PepperWithoutFlameProps } from './PepperWithoutFlameIconTypes';
import pepperWithoutFlame from '../../images/pepperWithoutFlame.svg';

const StyledPepperIcon = styled(ReactSVG)`
  width: 118px;
  height: 132px;

  * {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const PepperWithoutFlame = (props: PepperWithoutFlameProps) => {
  return (
    <StyledPepperIcon className={props.className} src={pepperWithoutFlame} />
  );
};

export default PepperWithoutFlame;
