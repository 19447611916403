const trimObjectStrings = <T extends Record<string, any>>(obj: T): T => {
  const trimmedObj: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else if (
      obj[key] &&
      typeof obj[key] === 'object' &&
      !Array.isArray(obj[key])
    ) {
      trimmedObj[key] = trimObjectStrings(obj[key]);
    } else {
      trimmedObj[key] = obj[key];
    }
  }

  return trimmedObj as T;
};

export default trimObjectStrings;
