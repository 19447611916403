import React from 'react';
import { ReactSVG } from 'react-svg';
import { IconContainerProps, IconProps, StyledIconProps } from './interface';
import styled, { css } from 'styled-components';
import theme from '../theme/theme';
import { transition } from '../utils/utils';

const IconContainer = styled.div<IconContainerProps>`
  flex: none;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: ${(p) => (typeof p.size === 'number' ? `${p.size}px` : p.size)};
  line-height: normal;
`;

const StyledIcon = styled(ReactSVG)<StyledIconProps>`
  &,
  div {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    ${transition({ name: 'fill' })};
    ${(p) => {
      if (p.type === 'fill') {
        return css`
          .vvui-stroke {
            display: none;
          }
        `;
      } else if (p.type === 'stroke') {
        return css`
          .vvui-fill {
            display: none;
          }
        `;
      }
    }};

    ${(p) => {
      if (p.color !== 'initial') {
        let color = p.theme.palette.border.main;

        if (p.color === 'inherit') {
          color = 'inherit';
        }

        return css`
          color: ${color};
        `;
      }
    }}
  }

  ${(p) => {
    if (p.color !== 'initial') {
      return css`
        * {
          fill: inherit;
        }
      `;
    }
  }}
`;

StyledIcon.defaultProps = {
  theme
};

const Icon = React.forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  const {
    color,
    className,
    src,
    style,
    size = 24,
    type = 'fill',
    ...otherProps
  } = props;
  return (
    <IconContainer
      {...otherProps}
      ref={ref}
      size={size}
      className={className}
      style={style}
    >
      <StyledIcon type={type} color={color} src={src} />
    </IconContainer>
  );
});

export default Icon;
