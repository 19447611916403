import React from 'react';
import styled, { css } from 'styled-components';
import { TypographyProps } from './interface';
import defaultTheme, { Theme } from '../theme/theme';

const titlesLineHeights = {
  h1: 39,
  h2: 29,
  h3: 24
};

const titlesSizes = {
  h1: 32,
  h2: 24,
  h3: 20
};

const subtitlesLineHeights = {
  '18': 22,
  '16': 19,
  '14': 17
};

const subtitlesWeights = {
  subtitle1: 600,
  subtitle2: 500,
  subtitle3: 400
};

const StyledTypography = styled.span<TypographyProps>`
  font-family: ${(props) => props.theme.font.family};
  font-weight: ${({ variant = 'subtitle1' }: TypographyProps) => {
    if (variant in subtitlesWeights) {
      return subtitlesWeights[variant as keyof typeof subtitlesWeights];
    }

    return 700;
  }};
  color: ${({ theme, color = 'main' }: TypographyProps & { theme: Theme }) => {
    return color === 'inherit' ? 'inherit' : theme.palette.text[color];
  }};

  ${({ variant = 'subtitle1', size }: TypographyProps) => {
    const isSubtitle = variant.startsWith('subtitle');
    const defaultSize: string = isSubtitle ? '16' : '32';
    const currentSize = isSubtitle
      ? size
        ? String(size)
        : defaultSize
      : titlesSizes[variant as keyof typeof titlesSizes];
    const lineHeight = isSubtitle
      ? subtitlesLineHeights[currentSize as keyof typeof subtitlesLineHeights]
      : titlesLineHeights[variant as keyof typeof titlesLineHeights];

    return css`
      font-size: ${currentSize}px;
      line-height: ${lineHeight}px;
    `;
  }};
`;

StyledTypography.defaultProps = {
  theme: defaultTheme
};

const TypographyBase = <T extends React.ElementType = 'span'>(
  props: TypographyProps<T>,
  ref: React.Ref<HTMLSpanElement>
) => {
  const { component, children, ...otherProps } = props;

  return (
    <StyledTypography {...otherProps} ref={ref} as={component as any}>
      {children}
    </StyledTypography>
  );
};

const Typography = React.forwardRef(TypographyBase) as typeof TypographyBase;

export default Typography;
