import styled from 'styled-components';
import Typography from '../../bit/components/typography';
import Button from '../../bit/components/button';

export const ErrorPageWrapper = styled('div')`
  padding: 24px;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.components.page.background};
`;

export const StyledErrorPageTitle = styled(Typography)`
  margin-top: 44px;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.5;
`;

export const StyledErrorPageDescription = styled(Typography)`
  margin-top: 16px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
`;

export const StyledErrorPageButton = styled(Button)`
  margin-top: 46px;
  width: 270px;
  height: 42px;
`;
