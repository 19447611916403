import React from 'react';
import { Props } from './interface';
import styled from 'styled-components';
import Typography from '../../bit/components/typography';
import { color } from '../../bit/components/utils/utils';

const StyledBlockHeader = styled.div<{ addPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: ${(props) => props.addPadding && '24px 24px 0'};
  color: ${color('text.themeMain')};
`;

const BlockHeaderSubTitle: typeof Typography = styled(Typography)`
  margin-top: 8px;
`;

const BlockHeader = (props: Props) => {
  const { subtitle, title, addPadding, ...otherProps } = props;

  return (
    <StyledBlockHeader {...otherProps} addPadding={addPadding}>
      <Typography variant='subtitle1' size={18}>
        {title}
      </Typography>
      {subtitle && (
        <BlockHeaderSubTitle color='second' variant='subtitle2' size={14}>
          {subtitle}
        </BlockHeaderSubTitle>
      )}
    </StyledBlockHeader>
  );
};

export default BlockHeader;
