import React, { useMemo } from 'react';
import Table from '../../components/Table';
import { TableProps } from '../../bit/components/table';
import { useTranslation } from 'react-i18next';
import Offer from '../../components/Offer';
import GoalsPayout from '../../components/GoalPayout';
import { useSelector } from 'react-redux';
import { selectOffers } from '../../redux/slices/offersSlice';

const OffersTable = ({ isLoading }: { isLoading?: boolean }) => {
  const { t } = useTranslation();

  const { items } = useSelector(selectOffers);

  const options = useMemo<TableProps['options']>(
    () => [
      { dataKey: 'offer', title: t('offers.offer') },
      { dataKey: 'goals', title: t('offers.goal', { count: 5 }) }
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      items.map((offer) => ({
        key: offer.id,
        offer: (
          <Offer
            offerId={offer.id}
            title={offer.title}
            image={offer.image}
            canPromote={offer.canPromote}
          />
        ),
        goals: <GoalsPayout goals={offer.goals} />
      })),
    [items]
  );

  return (
    <Table isLoading={isLoading} fullWidth options={options} rows={rows} />
  );
};

export default OffersTable;
