import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import Grid from '../../components/Grid';
import Page from '../../components/Page';
import Block from '../../components/Block';
import Icon from '../../bit/components/icon';
import Header from '../../components/Header';
import Button from '../../bit/components/button';
import EmployeeModal from '../../modals/EmpoyeeModal';
import TableEmployees from '../../components/TableEmployees';

import PlusIcon from '../../images/plus.svg';
import { Employee } from '../../types/employees';
import { useDispatch } from 'react-redux';
import {
  fetchEmployeesAsync,
  deleteEmployeesAsync
} from '../../redux/slices/employeesSlice';
import media from '../../resources/media';
import { color } from '../../bit/components/utils/utils';

const AdditionalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  * > {
    text-transform: uppercase;
  }
`;

const MobileNewEmployeeButtonContainer = styled.div`
  display: none;
  padding: 16px;
  border-bottom: 1px solid ${color('border.main')};

  @media ${media.tablet} {
    display: flex;
  }
`;

const NewEmployeeButton: any = styled(Button)<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};

  @media ${media.tablet} {
    display: ${(props) => (props.isMobile ? 'inline-flex' : 'none')};
  }
`;

const EmployeesPage = () => {
  const { t } = useTranslation();

  const [isOpenEmployeeModal, setOpenEmployeeModal] = React.useState(false);
  const [editableEmployee, setEditableEmployee] = React.useState<Employee>();

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openEmployeeModal = useCallback(() => {
    setOpenEmployeeModal(true);
  }, []);

  const closeEmployeeModal = useCallback(() => {
    setOpenEmployeeModal(false);
  }, []);

  const onCreateEmployee = useCallback(() => {
    setEditableEmployee(undefined);
    openEmployeeModal();
  }, [openEmployeeModal]);

  const onEditEmployee = useCallback(
    (item: Employee) => () => {
      setEditableEmployee(item);
      openEmployeeModal();
    },
    [openEmployeeModal]
  );

  const onDeleteEmployee = useCallback(
    (item: Employee) => async () => {
      if (window.confirm(`Вы точно хотите удалить «${item.email}»`)) {
        dispatch(deleteEmployeesAsync(item.id));
      }
    },
    [dispatch]
  );

  const renderNewEmployeeButton = React.useCallback(
    (isMobile?: boolean) => (
      <NewEmployeeButton
        size='middle'
        isMobile={isMobile}
        onClick={onCreateEmployee}
        startIcon={<Icon src={PlusIcon} type='stroke' size={20} />}
      >
        {t('employees.newEmployee')}
      </NewEmployeeButton>
    ),
    [onCreateEmployee, t]
  );

  const additionalTitle = React.useMemo(() => {
    return <AdditionalHeader>{renderNewEmployeeButton()}</AdditionalHeader>;
  }, [renderNewEmployeeButton]);

  return (
    <Page>
      <Header
        title={t('sidebar.employees')}
        additionalTitle={additionalTitle}
      />

      <Grid>
        <Block disablePadding fullWidth>
          <MobileNewEmployeeButtonContainer>
            {renderNewEmployeeButton(true)}
          </MobileNewEmployeeButtonContainer>

          <TableEmployees
            onEditEmployee={onEditEmployee}
            onDeleteEmployee={onDeleteEmployee}
          />
        </Block>
      </Grid>

      {isOpenEmployeeModal && (
        <EmployeeModal
          onUpdate={fetchData}
          employee={editableEmployee}
          open={isOpenEmployeeModal}
          onClose={closeEmployeeModal}
        />
      )}
    </Page>
  );
};

export default EmployeesPage;
