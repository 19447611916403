import styled from 'styled-components';
import Button from '../../components/Button';
import Icon from '../../bit/components/icon';

export const StyledWrapper = styled('div')`
  margin-top: 20px;

  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledDropdownWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:first-child {
    margin-right: 12px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 100px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
