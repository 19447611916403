import {
  Country,
  GoalType,
  Offer,
  OfferCreative,
  OfferGoal,
  OfferLanding,
  OfferVisibility,
  Tag,
  RootDataItemTitle
} from '../types/offers';
import {
  ApiOfferGetResponse,
  ApiOfferResponseCreatives,
  ApiOfferResponseGoals,
  ApiOfferResponseLinks,
  ApiOffersCountry,
  ApiOffersGoalType,
  ApiOffersTag
} from '../types/api/offers';
import { LandingsTypes } from '../types';

export const mapOfferCreatives = (
  creatives: ApiOfferResponseCreatives[] = []
): OfferCreative[] =>
  creatives.map((creative) => ({
    title: creative.title,
    type: creative.type,
    id: creative.id
  }));

export const mapOfferLandings = (
  landings: ApiOfferResponseLinks[] = []
): OfferLanding[] =>
  landings
    .filter((landing) => landing.type !== LandingsTypes.preview)
    .map((landing) => ({
      title: landing.title,
      type: landing.type,
      url: landing.url,
      id: landing.id
    }));

export const mapOfferGoals = (
  goals: ApiOfferResponseGoals[] = []
): OfferGoal[] =>
  goals
    .filter((goal) => goal.payout_for_affiliate.slice(1) !== '0.00')
    .map((goal) => ({
      id: goal.goal_id,
      payout: goal.payout_for_affiliate || '',
      typeName: goal.goal_type_name || '',
      conversionStatus: goal.conversion_status || 0,
      name: goal.goal_name || ''
    }));

export const checkCanPromote = (offerVisibility: OfferVisibility): boolean => {
  try {
    const [{ title, availability }] = offerVisibility;
    return title === RootDataItemTitle.public || availability === 'approved';
  } catch (_) {
    return false;
  }
};

export const mapOffer = (offer: ApiOfferGetResponse['data']): Offer => ({
  id: offer.id || 0,
  cr: offer.cr || '',
  creativesCount: offer.creatives_count || 0,
  epc: offer.epc || '',
  goals: mapOfferGoals(offer.goals),
  image: offer.image || '',
  linksCount: offer.links_count || 0,
  tags: offer.tags || '',
  targeting: {
    connectionType: { allowed: '' },
    // ts жалуется на перезапись,
    // но geo может не оказаться
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geo: { allowed: '', denied: '' },
    ...offer.targeting
  },
  title: offer.title || '',
  description: offer.description || '',
  trafficTypes: offer.traffic_types || '',
  timezone: offer.timezone || '',
  currency: offer.currency || '',
  landings: mapOfferLandings(offer.links),
  creatives: mapOfferCreatives(offer.creatives),
  canPromote: checkCanPromote(offer.visible_type_selected || [])
});

export const filterOffer = (offer: ApiOfferGetResponse['data']) => {
  // TODO
  // Кажется очень специфичная тестовая логика
  // Когда-нибудь вникнуть и актуализировать

  if (offer.visible_type_selected?.length) {
    const [{ title, availability }] = offer.visible_type_selected;

    if (title === RootDataItemTitle.private && availability !== 'approved') {
      return false;
    }
  }

  return true;
};

export const mapOffers = (offers: ApiOfferGetResponse['data'][]): Offer[] => {
  return offers.filter(filterOffer).map(mapOffer);
};

export const mapTags = (tags: ApiOffersTag[]): Tag[] =>
  tags.map((tag) => ({
    id: tag.id || 0,
    title: tag.title || ''
  }));

export const mapGoalTypes = (goalTypes: ApiOffersGoalType[]): GoalType[] =>
  goalTypes.map((goalType) => ({
    id: goalType.id || 0,
    title: goalType.title || ''
  }));

export const mapCountries = (countries: ApiOffersCountry[]): Country[] =>
  countries.map((country) => ({
    id: country.id || '',
    title: country.title || ''
  }));
