import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slices/userSlice';
import { Languages } from '../types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export enum RegisterMode {
  all = 'all',
  onlyTelegram = 'onlyTelegram',
  unknown = 'unknown'
}

const useWithTelegram = () => {
  const { info } = useSelector(selectUser);
  const {
    i18n: { language }
  } = useTranslation();

  const withTelegramByUserLang = !info?.lang || info.lang === Languages.ru;

  const registerMode = useMemo(() => {
    switch (language) {
      case Languages.ru:
        return RegisterMode.onlyTelegram;
      case Languages.en:
        return RegisterMode.all;
      case Languages.ua:
        return RegisterMode.all;
      default:
        return RegisterMode.unknown;
    }
  }, [language]);

  return { withTelegramByUserLang, registerMode };
};

export default useWithTelegram;
