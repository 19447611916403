import LocalStorage from './LocalStorage';

export const DROPDOWNS_SAVE_KEYS = {
  offer: 'offer',
  country: 'country',
  landing: 'landing'
};

const generateDropdownStorageKey = (key: string) => {
  return `DROPDOWN_${key}`;
};

export const getDataFromLocalStorage = <T extends any>(
  isEnabled: boolean | undefined,
  key: string
) => (): T[] => {
  try {
    if (isEnabled) {
      const data = LocalStorage.get<T[]>(generateDropdownStorageKey(key));

      if (data && Array.isArray(data)) {
        return data;
      }
    }
  } catch (error) {
    return [];
  }

  return [];
};

export default generateDropdownStorageKey;
