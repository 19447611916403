import queryString from 'query-string';
import { useMemo } from 'react';
import { paperAppsPush, pepperPartners } from '../resources/constants';

export type NamingLinkGeneratorParams = Partial<{
  appKey: string;
  partnerId: string;
  offerId: string;
  landingId: string;
  streamId: string;
  subId1: string;
  subId2: string;
  subId3: string;
  subId4: string;
  subId5: string;
}>;

const useNamingLinkGenerator = (
  params: NamingLinkGeneratorParams,
  isNewNaming = false
) => {
  const {
    partnerId,
    appKey,
    landingId,
    streamId,
    offerId,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5
  } = params;

  return useMemo(() => {
    if (!appKey) {
      return '';
    }

    if (isNewNaming) {
      const params = queryString.stringify(
        {
          a: partnerId,
          o: offerId,
          link_id: landingId,
          sub_id3: subId3,
          sub_id4: subId4,
          sub_id5: subId5
        },
        { skipEmptyString: true, sort: false }
      );

      return `${pepperPartners}${params ? `&${params}` : ''}`;
    }

    const params = queryString.stringify(
      {
        sub1: subId1,
        sub2: subId2,
        sub3: subId3,
        sub4: subId4,
        sub5: subId5,
        sub6: offerId,
        sub7: partnerId,
        sub8: landingId
      },
      { skipEmptyString: true }
    );

    return `${appKey}${streamId}${paperAppsPush}${params ? `&${params}` : ''}`;
  }, [
    appKey,
    isNewNaming,
    landingId,
    offerId,
    partnerId,
    streamId,
    subId1,
    subId2,
    subId3,
    subId4,
    subId5
  ]);
};

export default useNamingLinkGenerator;
