import React from 'react';
import Table from '../../components/Table';
import { useTranslation } from 'react-i18next';
import { GoalsTableProps } from './interface';
import GoalsPayout from '../../components/GoalPayout';

const GoalsTable = (props: GoalsTableProps) => {
  const { goals } = props;

  const { t } = useTranslation();

  const options = React.useMemo(
    () => [
      { title: t('common.title'), dataKey: 'title' },
      { title: t('common.payout'), dataKey: 'payout' }
    ],
    [t]
  );

  const rows = React.useMemo(
    () =>
      goals.map((goal) => ({
        key: goal.id,
        title: goal.name,
        payout: <GoalsPayout goals={[goal]} />
      })),
    [goals]
  );

  return <Table addBorderTop fullWidth options={options} rows={rows} />;
};

export default GoalsTable;
