import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import withStaticModal, {
  StaticModalWrappedComponent
} from './withStaticModal';

import Input from '../components/Input';
import Button from '../components/Button';
import Modal from '../bit/components/modal';
import ModalTitle from '../components/ModalTitle';
import Notification from '../components/Notification';
import ManagerialApi from '../api/managerial';
import Api from '../api';
import { AxiosError } from 'axios';
import useAwaitCallback from '../hooks/useAwaitCallback';

type ModalManagerialUpdateOfferAffiliatesData = { onUpdate: () => void };

const ModalManagerialUpdateOfferAffiliates: StaticModalWrappedComponent<ModalManagerialUpdateOfferAffiliatesData> = ({
  isOpen,
  onClose,
  data
}) => {
  const { t } = useTranslation();
  const [offerId, setOfferId] = useState('');

  const [onSubmit, isLoading] = useAwaitCallback(async () => {
    try {
      await ManagerialApi.updateOfferAffiliates(offerId);

      Notification.show({
        delay: 1500,
        message: t('modals.modalUpdateOffer.successNotify')
      });

      data?.onUpdate();
      onClose();
    } catch (error) {
      Api.handleDefaultError(error as AxiosError);
    }
  }, [offerId]);

  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={700}>
      <ModalTitle>{t('modals.modalUpdateOffer.title')}</ModalTitle>

      <Input
        value={offerId}
        disabled={isLoading}
        label={t('modals.modalUpdateOffer.inputLabel')}
        placeholder={t('modals.modalUpdateOffer.inputPlaceholder')}
        onChange={(e) => {
          e.target.value = e.target.value.replace(/[^\d]/gm, '');
          setOfferId(e.target.value);
        }}
      />

      <Button
        size='big'
        onClick={onSubmit}
        isLoading={isLoading}
        style={{ marginTop: 20 }}
      >
        {t('modals.modalUpdateOffer.button')}
      </Button>
    </Modal>
  );
};

export default withStaticModal<ModalManagerialUpdateOfferAffiliatesData>(
  ModalManagerialUpdateOfferAffiliates
);
