import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { color } from '../utils/utils';
import defaultTheme from '../theme/theme';
import { StyledTabsProps, TabsProps } from './interface';

export const TabsContext = React.createContext<any>({});

const StyledTabs = styled.div<StyledTabsProps>`
  display: inline-flex;
  width: ${({ fullWidth }: StyledTabsProps) => fullWidth && '100%'};
  ${({ variant = 'closed' }: StyledTabsProps) => {
    if (variant === 'closed') {
      return css`
        border: 1px solid ${color('border.main')};
        border-radius: 4px;
      `;
    }
  }};
`;

StyledTabs.defaultProps = {
  theme: defaultTheme
};

const Tabs = (props: TabsProps) => {
  const {
    children,
    size,
    variant,
    activeId,
    onChange,
    fullWidth,
    ...otherProps
  } = props;

  const contextValue = useMemo(
    () => ({
      size,
      variant,
      activeId,
      onChange
    }),
    [activeId, onChange, size, variant]
  );

  return (
    <StyledTabs {...otherProps} variant={variant} fullWidth={fullWidth}>
      <TabsContext.Provider value={contextValue}>
        {children}
      </TabsContext.Provider>
    </StyledTabs>
  );
};

export default Tabs;
