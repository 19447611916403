import React from 'react';

import Loader from './Loader';
import LibraryButton from '../bit/components/button';
import { ButtonProps } from '../bit/components/button/interface';

const Button = ({
  isLoading,
  ...props
}: ButtonProps & { isLoading?: boolean }) => {
  if (isLoading) {
    props.disabled = true;
    props.children = <Loader isStatic />;
  }

  return <LibraryButton {...props} />;
};

export default Button;
