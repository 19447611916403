import React from 'react';
import styled from 'styled-components';
import Typography from '../typography';
import { color } from '../utils/utils';
import { TableProps } from './interface';

const StyledTable = styled.div`
  overflow: hidden;
  border: 1px solid ${color('border.main')};
  border-radius: 4px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 16px;
      background-color: ${color('common.white')};
      border: 1px solid ${color('border.main')};
    }

    th {
      border-top: none;
      border-bottom: none;
    }

    td,
    th {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

const Table = (props: TableProps) => {
  const { options, rows, ...otherProps } = props;

  return (
    <StyledTable {...otherProps}>
      <table>
        <thead>
          <tr>
            {options.map((option) => (
              <th key={option.dataKey}>
                <Typography variant='subtitle3' color='third'>
                  {option.title}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.key}>
              {options.map((option) => {
                const data = row[option.dataKey];
                return (
                  <td
                    key={option.dataKey}
                    style={{ width: option.width || undefined }}
                  >
                    {data}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default Table;
