import React, { useCallback, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Typography from '../typography';
import { color, transition } from '../utils/utils';
import defaultTheme, { Theme } from '../theme/theme';
import { StyledTabProps, TabProps } from './interface';
import { TabsContext } from './Tabs';

const StyledTab = styled.div<StyledTabProps>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size }: StyledTabProps) => {
    switch (size) {
      case 'big':
        return 20;
      case 'small':
        return 4;
      default:
      case 'standard':
        return 12;
    }
  }}px;
  color: ${({
    theme,
    active,
    variant = 'closed'
  }: StyledTabProps & { theme: Theme }) => {
    if (variant === 'closed') {
      return active ? theme.palette.common.white : theme.palette.text.second;
    } else if (variant === 'open') {
      return active ? theme.palette.primary.main : theme.palette.text.second;
    }
  }};
  background-color: ${({
    theme,
    active,
    variant = 'closed'
  }: StyledTabProps & { theme: Theme }) => {
    if (variant === 'open') {
      return 'transparent';
    }
    return active ? theme.palette.primary.main : theme.palette.common.white;
  }};
  user-select: none;
  cursor: pointer;

  ${({
    active,
    variant = 'closed',
    theme
  }: StyledTabProps & { theme: Theme }) => {
    if (variant === 'closed') {
      return css`
        ${transition({ name: 'background-color' }, { name: 'color' })};

        & + & {
          border-left-color: ${color('border.main')};
        }

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      `;
    } else if (variant === 'open') {
      return css`
        border-bottom: 2px solid
          ${active ? theme.palette.primary.main : theme.palette.border.main};
        ${transition({ name: 'border-color' }, { name: 'color' })};
      `;
    }
  }};

  span {
    text-align: center;
  }
`;

StyledTab.defaultProps = {
  theme: defaultTheme
};

const Tab = (props: TabProps) => {
  const tabsContext = useContext(TabsContext);

  const {
    icon,
    children,
    id,
    onClick,
    size = tabsContext.size,
    variant = tabsContext.variant,
    ...otherProps
  } = props;

  const active = id === tabsContext.activeId;

  const textSize = useMemo(() => {
    switch (size) {
      case 'big':
        return 18;
      case 'small':
        return 14;
      default:
      case 'standard':
        return 16;
    }
  }, [size]);

  const { onChange } = tabsContext;

  const handleClick = useCallback(() => {
    onChange(id);
  }, [id, onChange]);

  return (
    <StyledTab
      {...otherProps}
      active={active}
      id={id}
      size={size}
      variant={variant}
      onClick={onClick || handleClick}
    >
      {icon || (
        <Typography color='inherit' size={textSize} variant='subtitle2'>
          {children}
        </Typography>
      )}
    </StyledTab>
  );
};

export default Tab;
