import { ApiUsersGetResponse } from '../types/api/employees';
import { Employee } from './../types/employees';
import { mapUserMessengers } from './userMessengersMap';

export const mapEmployee = (
  item: ApiUsersGetResponse['data'][0]
): Employee => ({
  name: String(''),
  phone: String(''),
  id: Number(item.id),
  role: String(item.type),
  email: String(item.email),
  messengers: mapUserMessengers(item.messengers),
  sectionsCredentials: item.sectionsCredentials,
  creator: item.creator
});
